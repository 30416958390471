import styled from 'styled-components'
import { shade } from 'polished'

export const Contanier = styled.button`
  background: #ff9000;
  height: 56px;
  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  color: #312e38;
  width: 100%;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;

  &:hover {
    background: ${shade(0.2, '#ff9000')};
  }
`

export const AvatarCircle = styled.img`
  width: 40px;
  height: 40px;

  object-fit: cover;
`
export const WrapperButtons = styled.div`
  @media (min-width: 991px) {
    .container-fluid {
      display: flex;
      align-items: center;
    }
    div.page-logo {
      width: auto !important;
    }
    .page-header
      .page-header-top
      .top-menu
      .navbar-nav
      li.dropdown
      .dropdown-toggle:active,
    .page-header
      .page-header-top
      .top-menu
      .navbar-nav
      li.dropdown
      .dropdown-toggle:focus,
    .page-header
      .page-header-top
      .top-menu
      .navbar-nav
      li.dropdown
      .dropdown-toggle:hover {
      background-color: #333 !important;
    }
    .nav li a:focus,
    .nav li a:hover {
      background-color: #333333 !important;
    }
    li.dropdown {
      margin-left: 20px !important;
    }
  }
  span {
    color: #fff !important;
    &:hover {
      color: #ccc !important;
    }
  }
  i {
    color: #fff !important;
    &:hover {
      color: #ccc !important;
    }
  }
  @media (max-width: 480px) {
    li.dropdown-user .dropdown-toggle {
      background-color: #333333 !important;
    }
  }
`
export const ContainerHeader = styled.div`
  .page-header-top {
    background: #333333;
  }
  @media (min-width: 991px) {
    height: auto !important;
    .container-fluid {
      display: flex;
      align-items: center;
      height: 100px;
    }
    div.page-logo {
      width: auto !important;
      margin-left: -45px;
    }
    div.page-header-top {
      height: 100%;
    }
    div.container-flex {
      display: flex;
      align-items: center;
    }
    div.top-menu {
      margin: 0 0 0 auto !important;
    }
    div.page-header-menu {
      margin-left: 45px;
    }
  }
  @media (max-width: 480px) {
    div.top-menu {
      background-color: #333333 !important;
    }
  }
`
