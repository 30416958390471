import React, { useCallback, useEffect, useState } from 'react'
import {
  CardContainer,
  Card,
  PageContainer,
  MainContent,
  Avatar,
  TabsContainer,
  TabItem
} from './style'
import Modal from '../../../../components/Modal'
import View from '../View'
import { FaCalendar, FaPlus } from 'react-icons/fa'
import avatar from '../../../../assets/layouts/layout3/img/avatar.png'
import gerente from '../../../../assets/layouts/layout3/img/avatar2.jpg'
import gerente2 from '../../../../assets/layouts/layout3/img/avatar1.jpg'
import { FormTask } from '../components/Form'
import Form, { Select } from '../../../../components/Form'
import Button from '../../../../components/Button'
import api from '../../../../services/api'

export type User = {
  id: number
  name: string
}

type Task = {
  id: number
  active: boolean
  title: string
  author: string
  description: string
  start: string
  end: string
  status: string
  userDemandant: {
    name: string
    avatar_url?: string
  }
}

const lorem =
  'Donec rhoncus eget nibh non finibus. Phasellus ut justo quis mi aliquet convallis. Vivamus vitae mollis risus'
const lorem2 =
  'Curabitur semper arcu semper, ornare nunc eget, ultricies urna. Nam dignissim sodales ipsum, quis porttitor magna sollicitudin id. Suspendisse aliquam quis orci et dignissim. Suspendisse maximus condimentum neque, sed ultricies mauris. Donec vel tempor dolor. Phasellus rutrum est id malesuada semper'
const lorem3 =
  'Ut mollis vestibulum tempor. Mauris arcu quam, tincidunt eu fermentum interdum, ultricies nec ipsum. Class aptent taciti sociosqu ad litora torquent per conubia nostra'
const lorem4 = 'lobortis justo ultrices non. Suspendisse eleifend augue diam'
const TASKS_FAKE = [
  {
    id: 1121,
    active: true,
    title: lorem,
    author: lorem,
    description: lorem,
    start: '18/07/2022',
    end: '19/10/2022',
    status: 'NOVA',
    userDemandant: {
      name: 'Gerente 1',
      avatar_url: gerente
    }
  },
  {
    id: 2,
    active: true,
    title: lorem2,
    author: lorem2,
    description: lorem2,
    start: '14/03/2022',
    end: '15/06/2022',
    status: 'NOVA',
    userDemandant: {
      name: 'Gerente 2',
      avatar_url: gerente2
    }
  },
  {
    id: 3,
    active: true,
    title: lorem3,
    author: lorem3,
    description: lorem3,
    start: '04/03/2022',
    end: '12/06/2022',
    status: 'NOVA',
    userDemandant: {
      name: 'Gerente 1',
      avatar_url: gerente
    }
  },
  {
    id: 4,
    active: true,
    title: lorem4,
    author: lorem4,
    description: lorem4,
    start: '09/03/2022',
    end: '12/06/2022',
    status: 'NOVA',
    userDemandant: {
      name: 'Gerente 2',
      avatar_url: gerente2
    }
  }
]

const List: React.FC = () => {
  const [modal, setModal] = useState(false)
  const [users, setUsers] = useState<User[]>([])
  const [tabs, setTabs] = useState<
    { active: boolean; status: string; label: string; color: string }[]
  >([
    { active: true, status: 'NOVA', label: 'Novas', color: '#0077b6' },
    {
      active: false,
      status: 'EM ABERTO',
      label: 'Em aberto',
      color: '#E07800'
    },
    {
      active: false,
      status: 'FINALIZADA',
      label: 'Finalizadas',
      color: '#52b788'
    },
    {
      active: false,
      status: '',
      label: 'Pesquisa Personalizada',
      color: '#52b788'
    }
  ])
  const [tasks, setTasks] = useState<{ [key: string]: Task[] }>()
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([])
  const [selectedTask, setSelectedTask] = useState<Task>()

  const activeTab = (tabIndex: string, tasks: any) => {
    setTabs(prev => {
      const newTabs = prev.map(i => ({
        ...i,
        active: i.status === tabIndex
      }))
      return newTabs
    })
    setFilteredTasks(tasks[tabIndex])
    setSelectedTask(undefined)
  }
  const getTasks = useCallback(async () => {
    const { data } = await api.get<Task[]>('personelDepartment/tasks')
    const tasks = data?.reduce<{ [key: string]: any[] }>((prev, current) => {
      const copy = { ...prev }
      if (!copy[current.status]) {
        copy[current.status] = []
      }
      copy[current.status] = [...copy[current.status], current]
      return copy
    }, {})
    setTasks(tasks)
    activeTab('NOVA', tasks)
  }, [])

  useEffect(() => {
    getTasks()
  }, [getTasks])

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get('users')
      setUsers(data)
    })()
  }, [])

  const onSubmitSearch = async (data: any) => {
    const searchResult = await api.post('personelDepartment/tasks/search', data)
    setTasks({ ...tasks, 'Pesquisa Personalizada': searchResult.data })
    setFilteredTasks(searchResult.data)
  }

  return (
    <PageContainer>
      <h1>Minhas Tarefas</h1>
      <div className="row">
        <TabsContainer>
          <TabItem
            active={tabs[0].active}
            onClick={() => activeTab(tabs[0].status, tasks)}
          >
            <p>{tabs[0].label}</p>
            <p>Em atraso: 1</p>
          </TabItem>
          <TabItem
            active={tabs[1].active}
            onClick={() => activeTab(tabs[1].status, tasks)}
          >
            <p>{tabs[1].label}</p>
            <p>Em atraso: 2</p>
          </TabItem>
          <TabItem
            active={tabs[2].active}
            onClick={() => activeTab(tabs[2].status, tasks)}
          >
            <p>{tabs[2].label}</p>
          </TabItem>
          <TabItem
            active={tabs[3].active}
            onClick={() => activeTab(tabs[3].status, tasks)}
          >
            <p>{tabs[3].label}</p>
          </TabItem>
          <li>
            <button onClick={() => setModal(true)}>
              <FaPlus /> Nova Tarefa
            </button>
          </li>
        </TabsContainer>
      </div>
      <div className="row">
        <MainContent className="col-md-12">
          {tabs[3].active && (
            <Form onSubmit={onSubmitSearch}>
              <div
                className="row"
                style={{ alignItems: 'flex-end', display: 'flex' }}
              >
                <div className="col-md-2">
                  <Select
                    label="Setor"
                    name="uuser"
                    className="form-control"
                    options={[{ name: 'Locação', value: 'userId' }]}
                    blank
                    defaultValue={''}
                  />
                </div>
                <div className="col-md-2">
                  <Select
                    label="Demandante"
                    name="demandant"
                    className="form-control"
                    options={users.map(user => ({
                      name: user.name,
                      value: user.id
                    }))}
                    blank
                    defaultValue={''}
                  />
                </div>
                <div className="col-md-2">
                  <Select
                    label="Participante"
                    name="user"
                    className="form-control"
                    options={users.map(user => ({
                      name: user.name,
                      value: user.id
                    }))}
                    blank
                    defaultValue={''}
                  />
                </div>
                <div className="col-md-2">
                  <Button
                    type="submit"
                    className="btn btn-light btn-sm sbold uppercase"
                    style={{
                      marginBottom: '15px',
                      bottom: '0px',
                      marginRight: '6px',
                      border: 'solid 1px #ccc',
                      background: '#f2f2f2'
                    }}
                  >
                    Limpar
                  </Button>
                  <Button
                    type="submit"
                    className="btn dark btn-sm sbold uppercase"
                    style={{ marginBottom: '15px' }}
                  >
                    Filtrar
                  </Button>
                </div>
              </div>
            </Form>
          )}
          <div className="row">
            <div className="col-md-4">
              <h3>{tabs.find(t => t.active)?.label}</h3>
              <CardContainer>
                {TASKS_FAKE?.map(task => (
                  <Card
                    key={task.id}
                    className="col-md-12"
                    color={tabs.find(t => t.active)?.color}
                    active={task.id === selectedTask?.id}
                    onClick={() => {
                      setSelectedTask(task)
                    }}
                  >
                    <header>
                      <Avatar src={task.userDemandant.avatar_url || avatar} />
                      {task.userDemandant.name}
                    </header>
                    <h5>{task.title}</h5>
                    <footer>
                      <FaCalendar /> <b>{task.end}</b>
                    </footer>
                  </Card>
                ))}
              </CardContainer>
              <div style={{ height: '100px' }}></div>
            </div>
            <div className="col-md-8">
              {selectedTask && (
                <View
                  taskId={selectedTask?.id}
                  isNew={tabs[0].active}
                  getTasks={getTasks}
                />
              )}
            </div>
          </div>
        </MainContent>
      </div>
      {modal && (
        <Modal
          Children={
            <FormTask
              typeForm="create"
              setModal={setModal}
              getTasks={getTasks}
              users={users}
            />
          }
          isOpenModal={modal}
          pageTitle="Nova Tarefa"
          onClickButtonCancel={() => setModal(false)}
          styles={{ padding: '0px' }}
        />
      )}
    </PageContainer>
  )
}
export default List
