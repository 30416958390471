/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useCallback, useEffect, useRef } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import { Container } from './styles'
import api from '../../services/api'

interface FieldsProps {
  name: string
  percent: number
  color?: string
  value?: string
  type?: string
  id: number
}

interface AmchartPizzaProps {
  data: any[]
  style?: any
  maintitle?: string
  classname?: string
  divId: string
  height?: string
  disableClick?: boolean
  styleSettings?: any
  resizeChart?: boolean
  ignoreFields?: string[]
  fields?: FieldsProps[]
  hasExport?: boolean
}
const AmChartPizza: React.FC<AmchartPizzaProps> = ({
  data,
  style,
  maintitle,
  classname,
  divId,
  height,
  disableClick,
  styleSettings,
  resizeChart,
  ignoreFields,
  fields,
  hasExport
}) => {
  const chartRef = useRef(null)
  const pieSeriesRef = useRef(null)
  const dataRef = useRef(data)
  const chartContainerRef = useRef(null)
  const selectedRef = useRef(undefined)
  const generateChartData = useCallback(() => {
    let totalSum = 0
    let getLastColor = ''
    let financial_transactions = ''
    const types = dataRef.current
    const chartData = []
    for (let i = 0; i < types.length; i++) {
      if (!isNaN(Number(selectedRef.current))) {
        if (i === selectedRef.current) {
          for (let x = 0; x < types[i].subs.length; x++) {
            financial_transactions += `,${types[i].subs[x].financial_transactions}`
            getLastColor = types[i].subs[x].color
            totalSum += Number(types[i].subs[x].value)
            chartData.push({
              type: types[i].subs[x].type,
              percent: types[i].subs[x].percent,
              value: types[i].subs[x].value,
              color: types[i].subs[x].color,
              financial_transactions: types[i].subs[x].financial_transactions,
              clickable: true,
              pulled: true,
              sliceSettings: {
                active: true
              }
            })
          }
        }
      } else {
        financial_transactions += `,${types[i].financial_transactions}`
        getLastColor = types[i].color
        totalSum += Number(types[i].value)
        chartData.push({
          type: types[i].type,
          percent: types[i].percent,
          color: types[i].color,
          financial_transactions: types[i].financial_transactions,
          value: types[i].value,
          clickable: !!types[i].subs.length,
          id: i
        })
      }
    }
    if (fields?.length) {
      for (const field of fields) {
        chartData.push({
          ...field,
          type: field.name,
          value: field.type?.includes('sum') ? totalSum : field.value,
          financial_transactions,
          color: field.color || getLastColor
        })
      }
    }
    return chartData
  }, [fields])
  const checkEmptyData = useCallback(() => {
    let indicator
    function showIndicator() {
      if (indicator) {
        indicator.show()
      } else {
        indicator = chartRef.current.tooltipContainer.createChild(
          am4core.Container
        )
        indicator.background.fill = am4core.color('#fff')
        indicator.background.fillOpacity = 0.8
        indicator.width = am4core.percent(100)
        indicator.height = am4core.percent(100)

        const indicatorLabel = indicator.createChild(am4core.Label)
        indicatorLabel.text = 'Sem dados para análise no momento'
        indicatorLabel.align = 'center'
        indicatorLabel.valign = 'middle'
        indicatorLabel.fontSize = 20
      }
    }

    function hideIndicator() {
      indicator.hide()
    }

    chartRef.current.events.on('datavalidated', function (ev) {
      if (ev.target.data.length === 0) {
        showIndicator()
      } else if (
        ev.target.data.length === 1 &&
        ignoreFields?.includes(ev.target.data[0].type)
      ) {
        showIndicator()
      } else if (indicator) {
        hideIndicator()
      }
    })
  }, [ignoreFields])
  const disableTooltip = useCallback(() => {
    if (chartRef.current) {
      chartRef.current.legend.markers.template.children
        .getIndex(0)
        .adapter.add('fill', (fill, target) => {
          if (target.dataItem) {
            if (ignoreFields?.includes(target.dataItem.name)) {
              target.dataItem.marker.disabled = true
              target.dataItem.label.id = 'labelText'
              target.dataItem.label.setClassName()
              target.dataItem.valueLabel.id = 'labelValue'
              target.dataItem.valueLabel.setClassName()
            }
          }
          return fill
        })
    }
    if (pieSeriesRef.current) {
      pieSeriesRef.current.tooltip.label.adapter.add('text', (text, target) => {
        if (target.dataItem) {
          if (ignoreFields?.includes(target.dataItem.category)) {
            text = ''
          }
        }
        return text
      })
    }
  }, [ignoreFields])
  useEffect(() => {
    chartRef.current = am4core.create(divId, am4charts.PieChart)

    const pieSeries = chartRef.current.series.push(new am4charts.PieSeries())
    pieSeriesRef.current = pieSeries
    pieSeries.dataFields.value = 'percent'
    pieSeries.dataFields.category = 'type'
    pieSeries.dataFields.openValue = 'value'
    pieSeries.slices.template.propertyFields.fill = 'color'
    pieSeries.slices.template.propertyFields.isActive = 'pulled'
    pieSeries.slices.template.strokeWidth = 0
    chartRef.current.language.locale._decimalSeparator = ','
    chartRef.current.language.locale._thousandSeparator = '.'
    chartRef.current.numberFormatter.numberFormat = '#,###.00'
    pieSeries.slices.template.tooltipText =
      "{category}: {value.value.formatNumber('#.#')}% ({openValue})"
    pieSeries.labels.template.text =
      "{category}: {value.value.formatNumber('#.#')}%"
    pieSeries.slices.template.events.on('hit', function (event) {
      if (!disableClick) {
        if (event.target.dataItem.dataContext.clickable) {
          if (event.target.dataItem.dataContext.id !== undefined) {
            selectedRef.current = event.target.dataItem.dataContext.id
          } else {
            selectedRef.current = undefined
          }
        }
        chartRef.current.data = generateChartData()
      }
    })
    chartRef.current.legend = new am4charts.Legend()
    chartRef.current.legend.itemContainers.template.events.on(
      'hit',
      function (event) {
        if (
          event.target.dataItem.dataContext.dataContext.financial_transactions
        ) {
          const transactions =
            event.target.dataItem.dataContext.dataContext.financial_transactions

          if (!transactions) return
          const [year, month, day] = new Date()
            .toISOString()
            .slice(0, 10)
            .split('-')
          const date = `${day}-${month}-${year}`
          const hashedOrders = btoa(transactions)
          api
            .post('financial/financialTransactions/generateexport', {
              data: hashedOrders,
              entity: 'FinancialTransactions',
              fieldSearch: 'id',
              source: 'FinancialTransactions',
              header: [
                {
                  id: 'due_date',
                  tableField: 'due_date',
                  title: 'Vencimento',
                  type: 'date',
                  formatStyle: '%Y-%m-%d'
                },
                {
                  id: 'supply_name',
                  tableField: 'name',
                  title: 'Fornecedor',
                  source: 'Suppliers',
                  emptyValue: 'SEM FORNECEDOR',
                  removable: true
                },
                {
                  id: 'client_name',
                  tableField: 'name',
                  title: 'Cliente',
                  emptyValue: 'SEM CLIENTE',
                  source: 'Client',
                  removable: true
                },
                {
                  id: 'value',
                  tableField: 'value',
                  title: 'Valor',
                  type: 'money',
                  symbol: 'R$',
                  precision: 2,
                  locale: 'pt_BR'
                }
              ],
              innerJoin: [
                {
                  entity: 'Client',
                  source: 'Client',
                  field: 'id',
                  fieldCondition: 'client_id',
                  sourceCondition: 'FinancialTransactions',
                  isNull: true
                },
                {
                  entity: 'Suppliers',
                  source: 'Suppliers',
                  field: 'id',
                  fieldCondition: 'supplier_id',
                  sourceCondition: 'FinancialTransactions',
                  isNull: true
                }
              ]
            })
            .then(response => {
              const blob = new Blob(['\ufeff' + response.data], {
                type: 'text/csv;charset=utf-8"'
              })
              const url = window.URL.createObjectURL(blob)

              const a = document.createElement('a')
              a.download = `relatorio-movimentações-${date}.csv`
              a.href = url
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
            })
        }
        return undefined
      }
    )
    chartRef.current.legend.position = 'right'
    chartRef.current.legend.valign = 'top'
    chartRef.current.legend.valueLabels.template.text = '{openValue}'
    chartRef.current.legend.labels.template.paddingLeft = 0
    chartRef.current.legend.maxWidth = styleSettings?.legend?.maxWidth || 400
    chartRef.current.legend.labels.template.minWidth =
      styleSettings?.legend?.minWidth || 200
    pieSeries.ticks.template.disabled = true
    pieSeries.labels.template.disabled = true
    chartRef.current.legend.labels.template.truncate = true
    chartRef.current.responsive.enable = true
    if (hasExport) {
      chartRef.current.legend.itemContainers.template.togglable = false
    }
    if (data.length && resizeChart) {
      const calc = data.length * 50
      chartRef.current.height = height
      chartRef.current.svgContainer.htmlElement.style.height = `${
        calc < height ? height : calc
      }px`
    }

    checkEmptyData()
    disableTooltip()

    return () => {
      chartRef.current.dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    disableClick,
    disableTooltip,
    divId,
    generateChartData,
    height,
    resizeChart
  ])
  useEffect(() => {
    if (chartRef.current) {
      dataRef.current = data
      if (data.length && resizeChart) {
        const calc = data.length * 50
        chartRef.current.height = height
        chartRef.current.svgContainer.htmlElement.style.height = `${
          calc < height ? height : calc
        }px`
      }
      chartRef.current.data = generateChartData()
      checkEmptyData()
      disableTooltip()
    }
  }, [
    checkEmptyData,
    data,
    disableTooltip,
    generateChartData,
    height,
    resizeChart
  ])
  return (
    <Container
      style={{ width: '100%', height: '100%' }}
      className={classname || 'portlet light'}
    >
      {maintitle?.length > 0 && (
        <div className="portlet-title">
          <div className="caption caption-md">
            <i className="icon-bar-chart font-dark hide"></i>
            <span className="maintitle">{maintitle}</span>
          </div>
        </div>
      )}
      <div
        id={divId}
        className="portlet-body"
        ref={chartContainerRef}
        style={style || { width: '100%', minHeight: height || '550px' }}
      ></div>
    </Container>
  )
}

export { AmChartPizza }
