import React, { useEffect, useState, useCallback } from 'react'
import { Input } from '../../../../../../../../../../components/Form'
import { TooltipComponent } from '../../../../../../../../../../components/TooltipComponent'
import { useToast } from '../../../../../../../../../../hooks/toast'
import api from '../../../../../../../../../../services/api'
import { useProduct } from '../../../../../providers/product/ProductProvider'
import { SearchContainer } from './styles'

type CfopListType = {
  id: number
  code: string
  descriptions: string
}
export function SearchComponentCfop() {
  const [value, setValue] = useState('')
  const [value2, setValue2] = useState('')
  const { fiscal } = useProduct()
  const [cfopList, setCfopList] = useState<CfopListType[]>([])
  const [cfopList2, setCfopList2] = useState<CfopListType[]>([])
  const [selected, setSelected] = useState(false)
  const [selected2, setSelected2] = useState(false)
  const { addToast } = useToast()

  useEffect(() => {
    if (fiscal && fiscal.cfop) {
      if (/^-?\d+$/.test(fiscal.cfop?.trim())) {
        ;(async () => {
          const { data } = await api.get('taxes/taxCfop', {
            params: {
              searchParameters: { code: fiscal.cfop.trim(), descriptions: '' }
            }
          })
          if (data?.length) {
            setValue(`${fiscal.cfop} - ${data[0]?.descriptions}`)
          }
        })()
      } else {
        setValue(fiscal.cfop)
      }
      setSelected(true)
    }
    if (fiscal && fiscal.cfop_out_of_state) {
      if (/^-?\d+$/.test(fiscal.cfop_out_of_state?.trim())) {
        ;(async () => {
          const { data } = await api.get('taxes/taxCfop', {
            params: {
              searchParameters: {
                code: fiscal.cfop_out_of_state.trim(),
                descriptions: ''
              }
            }
          })
          if (data?.length) {
            setValue2(`${fiscal.cfop_out_of_state} - ${data[0]?.descriptions}`)
          }
        })()
      } else {
        setValue2(fiscal.cfop_out_of_state)
      }
      setSelected2(true)
    }
  }, [fiscal])
  const getCfopList = useCallback(async () => {
    let code = isNaN(parseInt(value)) ? '' : value
    let descriptions = isNaN(parseInt(value)) ? value : ''
    const filter = value.replace(/[0-9]+[^\w]+/, '')
    if (isNaN(parseInt(filter))) {
      descriptions = filter
      code = ''
    }

    const { data } = await api.get<CfopListType[]>('/taxes/taxCfop', {
      params: {
        searchParameters: { code, descriptions }
      }
    })
    setCfopList(data)
  }, [value])

  const check = useCallback(
    (input: number, cfop: string) => {
      const sanitzedCfop = cfop
        .toUpperCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^\w\s]/gi, '')
        .trim()
      if (
        input === 1 &&
        sanitzedCfop ===
          value2
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/[^\w\s]/gi, '')
            .trim()
      ) {
        setValue('')
        addToast({
          title: 'CFOP do estado deve ser diferente do CFOP fora do estado',
          type: 'error'
        })
      }
      if (
        input === 2 &&
        sanitzedCfop ===
          value
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/[^\w\s]/gi, '')
            .trim()
      ) {
        setValue2('')
        addToast({
          title:
            'CFOP - Dentro do estado deve ser diferente do CFOP - Fora do estado',
          type: 'error'
        })
      }
    },
    [addToast, value, value2]
  )
  useEffect(() => {
    if (!value) return
    const timeOutId = setTimeout(() => getCfopList(), 500)
    return () => clearTimeout(timeOutId)
  }, [getCfopList, value])

  const getCfopList2 = useCallback(async () => {
    let code = isNaN(parseInt(value2)) ? '' : value2
    let descriptions = isNaN(parseInt(value2)) ? value2 : ''
    const filter = value2.replace(/[0-9]+[^\w]+/, '')
    if (isNaN(parseInt(filter))) {
      descriptions = filter
      code = ''
    }

    const { data } = await api.get<CfopListType[]>('/taxes/taxCfop', {
      params: {
        searchParameters: { code, descriptions }
      }
    })
    setCfopList2(data)
  }, [value2])

  useEffect(() => {
    if (!value2) return
    const timeOutId = setTimeout(() => getCfopList2(), 500)
    return () => clearTimeout(timeOutId)
  }, [getCfopList2, value2])

  return (
    <>
      <div className="form-content col-md-4">
        <TooltipComponent
          label="CFOP - Dentro do estado"
          message="É a abreviação de Código Fiscal de Operações e Prestações. Esse código identifica uma determinada operação por categorias no momento da emissão da nota fiscal. Dependendo do Código CFOP, será fixada a tributação sobre a operação e haverá movimentações financeiras e de estoque de interesse do Fisco."
        />
        <SearchContainer>
          <div>
            <Input
              className="form-control"
              name="fiscal.cfop"
              id="option"
              onChange={({ target }) => {
                setValue(target.value)
                setSelected(false)
              }}
              value={value}
              fullControlled
              rules={{ required: true }}
            />
            <ul>
              {value !== '' &&
                !selected &&
                cfopList.map(item => (
                  <li
                    key={item.id}
                    onClick={() => {
                      setValue(`${item.code} - ${item.descriptions}`)
                      setSelected(true)
                      check(1, `${item.code} - ${item.descriptions}`)
                    }}
                  >
                    {`${item.code} - ${item.descriptions}`}
                  </li>
                ))}
            </ul>
          </div>
        </SearchContainer>
      </div>
      <div className="form-content col-md-4">
        <TooltipComponent
          label="CFOP - Fora do estado"
          message="É a abreviação de Código Fiscal de Operações e Prestações. Esse código identifica uma determinada operação por categorias no momento da emissão da nota fiscal. É um código numérico que identifica a natureza de circulação de mercadorias e serviços de transporte intermunicipais, interestaduais e também. Dependendo do Código CFOP, será fixada a tributação sobre a operação e haverá movimentações financeiras e de estoque de interesse do Fisco."
        />
        <SearchContainer>
          <div>
            <Input
              className="form-control"
              name="fiscal.cfop_out_of_state"
              id="option"
              onChange={({ target }) => {
                setValue2(target.value)
                setSelected2(false)
              }}
              value={value2}
              fullControlled
              rules={{ required: true }}
            />
            <ul>
              {value2 !== '' &&
                !selected2 &&
                cfopList2.map(item => (
                  <li
                    key={item.id}
                    onClick={() => {
                      setValue2(`${item.code} - ${item.descriptions}`)
                      setSelected2(true)
                      check(2, `${item.code} - ${item.descriptions}`)
                    }}
                  >
                    {`${item.code} - ${item.descriptions}`}
                  </li>
                ))}
            </ul>
          </div>
        </SearchContainer>
      </div>
    </>
  )
}
