/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'
import DataTable from '../../../../components/DataTable'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions, namePageTitle, nameEntity } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import { WrapperTablePortlet } from './styles'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import { TablePortlet } from '../../../../components/TablePortlet'

interface UserData {
  id: number
  parent_id: number | null
  name: string
  type: 'cake' | 'front'
  controller: string
  method: string
  action: string
  created_at: string
  updated_at: string
  last_acess_at: string
  last_access_ip: string
  remote_access: string
  role_id: number
}

const UserView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [user, setUser] = useState<UserData | null>(null)
  const [permissionsAggroupped, setPermissionsAggroupped] = useState([])
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })
  const searchParametersAuditLog = useRef([
    { entity: nameEntity, entity_id: id }
  ])

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadCategory(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<UserData>(apiList(id))
        const { data } = response
        setUser(data)
        const permissions = await api.get(
          `/users/permissions/listAggrouped/${data.role_id}/${data.id}`
        )
        setPermissionsAggroupped(permissions.data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o usuário',
          description:
            'Houve um error ao carregar o usuário, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Usuário removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Usuário não removido, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Usuário removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Usuário não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  const handleSwitch = useCallback(
    async (item: any) => {
      activeLoading()
      if (!item.approved_role) {
        if (!item.hasPermission) {
          await api.post('/users/permissionUser/create', {
            user_id: id,
            permission_id: item.id
          })
        } else {
          await api.delete(`/users/permissionUser/delete/${id}/${item.id}`)
        }
        const permissions = await api.get(
          `/users/permissions/listAggrouped/${user.role_id}/${user.id}`
        )
        setPermissionsAggroupped(permissions.data)
        disableLoading()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  )

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="id" className="control-label">
                  Cód.
                </label>
                <p>{user?.id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Nome
                </label>
                <p>{user?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="role_id" className="control-label">
                  Grupo de Usuário
                </label>
                <p>{user?.role_id}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className="control-label">
                  Acesso Remoto
                </label>
                <p>{user?.remote_access ? 'Sim' : 'Não'}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className="control-label">
                  Ultimo Ip Acessado
                </label>
                <p>{user?.last_access_ip}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className="control-label">
                  Ultimo Acesso
                </label>
                <p>{user?.last_acess_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="created" className="control-label">
                  Cadastrado em
                </label>
                <p>{user?.created_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className="control-label">
                  Atualizado em
                </label>
                <p>{user?.updated_at}</p>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
          <div className="row">
            <div className="col-md-12">
              <Tabs>
                {[
                  <Tab key={0} title="Histórico">
                    <div className="portlet light">
                      <div className="portlet-title">
                        <div className="caption">Listagem</div>
                        <div className="tools"></div>
                      </div>
                      <div className="portlet-body form">
                        <DataTable
                          entityId={id}
                          format={{ orderBy: '' }}
                          source="auditLogs"
                          entity="AuditLog"
                          searchParameters={searchParametersAuditLog.current}
                        />
                      </div>
                    </div>
                  </Tab>,
                  <Tab key={2} title="Permissões">
                    <WrapperTablePortlet>
                      <TablePortlet
                        maintitle="Listagem"
                        handleSetDate={() => {}}
                        setInitialData={() => {}}
                        actions={[]}
                        tableHead={['Funções', 'Ação']}
                        hasDropdown={true}
                        hasToggle={true}
                        indexColumnToggle={[1]}
                        handleToggleSwitch={handleSwitch}
                        switchSettings={{
                          checkedIcon: false,
                          uncheckedIcon: false,
                          ischecked: 'hasPermission',
                          approved_role: 'approved_role',
                          hasTooltip: true,
                          tooltip: {
                            label: '',
                            message:
                              'Rota permitida pelo grupo do usuário por isso não pode ser alterada',
                            bold: true,
                            key: Math.random() * 999
                          }
                        }}
                        realData={permissionsAggroupped}
                        stylesChild={{
                          th: {
                            classname: 'hasBottomBorder'
                          },
                          column4: {
                            classname: ''
                          },
                          column1: {
                            classname: 'firstChild'
                          },
                          child: {
                            column1: { classname: 'firstSubChild' }
                          },
                          highlightColor: '#c42f2f',
                          highlightOverColor: '#c42f2f'
                        }}
                      />
                    </WrapperTablePortlet>
                  </Tab>
                ]}
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${user?.name} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(user?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default UserView
