/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import {
  destinations,
  nameEntity,
  namePageTitle,
  situations
} from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { FilterContaier, ListTitle, Table, TableRow } from './styles'
import api from '../../../../services/api'
import { useLoading } from '../../../../hooks/loading'
import { useToast } from '../../../../hooks/toast'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import xmlIconDark from '../../../../assets/image/xml-dark.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons'
import Modal from '../../../../components/Modal'
import Button from '../../../../components/Button'
import { Date as DatePicker } from '../../../../components/Form/date'

interface SelectedNfeItems {
  item_id: number
  quantity: number
  nfQuantity: string
  parent?: number
  product_id: number
  stock_id: number
  nfUnitMeasurement: string
}
interface NfeProduct {
  xProd: 'string'
  qCom: string
  parent_id?: number
  uCom: string
}
interface NfeData {
  id: number
  CNPJ: string
  chNFe: string
  dhEmi: string
  xNome: string
  vNF: string
  destination?: string
  company_id: number
  nNF: string
  products: NfeProduct[]
  purchase_number: string
  conciled: number
  infCpl: string
}
interface ItemData {
  id: number
  item_id: number
  product_id: number
  name: string
  quantity: number
}
interface PurchasesRequestsQuotes {
  id: number
  purchase_request_id: number
  order_supply_number: number
  order_supply_date_issue: string
  purchase_request_date: string
  order_supply_value: string
  supplier_id: number
  items: ItemData[]
}

const InvoicesReceivedList = (): JSX.Element => {
  const [company, setCompany] = useState('')
  const [companies, setCompanies] = useState([])
  const [number, setNumber] = useState('')
  const [provider, setProvider] = useState<any>()
  const [cnpj, setCnpj] = useState('')
  const [situation, setSituation] = useState('')
  const [verified, setVerified] = useState(null)
  const [conciled, setConciled] = useState(null)
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [destination, setDestination] = useState<any>()
  const [parameters, setParameters] = useState<any>()
  const { activeLoading, disableLoading } = useLoading()
  const [modalRequests, setModalRequests] = useState(false)
  const [orderSupplyNumber, setOrderSupplyNumber] = useState('')
  const [purchasesRequestsQuotes, setPurchasesRequestsQuotes] = useState<
    PurchasesRequestsQuotes[]
  >([])
  const [selectProducts, setSelectProducts] = useState<NfeProduct[]>([])
  const [selectedNFProducts, setSelectedNFProducts] = useState<
    SelectedNfeItems[]
  >([])
  const [cacheNF, setCacheNF] = useState<any>({})
  const [selectedRequest, setSelectedRequest] =
    useState<PurchasesRequestsQuotes>({} as PurchasesRequestsQuotes)
  const [nfeItem, setNfeItem] = useState<NfeData>({} as NfeData)
  const refModalRequest = useRef(null)
  const { addToast } = useToast()

  async function getConfigurations() {
    const { data } = await api.get('financial/configurations')

    setCompanies(data)
  }
  useEffect(() => {
    getConfigurations()
  }, [])

  const handleClickOnClose = () => {
    setModalRequests(false)
  }
  const handleSave = useCallback(
    async (purchaseRequest: PurchasesRequestsQuotes) => {
      if (!selectedNFProducts.length) {
        return
      }
      try {
        activeLoading()
        await api.post('/financial/purchaseRequestDelivery/create', {
          purchase_request_quote_id: purchaseRequest.id,
          purchase_request_id: purchaseRequest.purchase_request_id,
          supplier_id: purchaseRequest.supplier_id,
          invoice_issued_for_cnpj_id: nfeItem.id,
          nf_number: nfeItem.nNF,
          purchase_request_date: purchaseRequest.purchase_request_date,
          items: selectedNFProducts
        })
        disableLoading()
        addToast({
          type: 'success',
          title: 'Nota Fiscal atualizada',
          description: 'Nota Fiscal alterada com sucesso'
        })
      } catch (error: any) {
        const message: string = JSON.parse(error.request.response).message
        disableLoading()
        if (message === 'This quantity is over quote quantity') {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar a Nota Fiscal',
            description: 'A quantidade recebida é maior do que solicitado.'
          })
          return
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar a Nota Fiscal',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    },
    [
      activeLoading,
      addToast,
      disableLoading,
      nfeItem.id,
      nfeItem.nNF,
      selectedNFProducts
    ]
  )

  const handleSelectItem = useCallback(
    (event, item: ItemData) => {
      if (!event.target.options?.length) return
      const name = event.target.options[event.target.selectedIndex].text
      const tempProducts = selectProducts.map(itemSelected =>
        selectProducts.find(findSelected => findSelected.parent_id === item.id)
          ? { ...itemSelected, parent_id: undefined }
          : { ...itemSelected }
      )
      const filterSelect = tempProducts.map(itemSelected => {
        if (event.target.value === 'unlock') {
          if (itemSelected.parent_id === item.id) {
            return {
              ...itemSelected,
              parent_id: undefined
            }
          }
        }
        return itemSelected.xProd === name
          ? { ...itemSelected, parent_id: item.id }
          : { ...itemSelected }
      })
      const findSelecteProduct = filterSelect.find(
        findSelected => findSelected.parent_id === item.id
      )
      let selectedProducts: any
      if (event.target.value === 'unlock') {
        selectedProducts = selectedNFProducts.filter(
          filterNfProduct => filterNfProduct.item_id !== item.item_id
        )
      } else {
        selectedProducts = [
          ...selectedNFProducts,
          {
            parent: item.id,
            item_id: item.item_id,
            quantity: item.quantity,
            nfQuantity: event.target.value,
            nfProduct: name,
            product_id: item.product_id,
            nfUnitMeasurement: findSelecteProduct?.uCom
          }
        ]
      }
      setSelectedNFProducts(selectedProducts)
      if (!cacheNF[nfeItem?.chNFe]) {
        cacheNF[nfeItem?.chNFe] = {}
      }
      cacheNF[nfeItem?.chNFe].selectedNFProducts = [...selectedProducts]
      setCacheNF(cacheNF)
      setSelectProducts(filterSelect)
    },
    [cacheNF, nfeItem?.chNFe, selectProducts, selectedNFProducts]
  )

  useEffect(() => {
    if (cacheNF[nfeItem?.chNFe]?.selectedRequest) {
      setSelectedRequest({ ...cacheNF[nfeItem?.chNFe]?.selectedRequest })
    }
    if (cacheNF[nfeItem?.chNFe]?.selectedNFProducts) {
      setSelectedNFProducts([...cacheNF[nfeItem?.chNFe]?.selectedNFProducts])
    }
  }, [cacheNF, nfeItem])

  const handleSearchRequestQuotes = useCallback(
    async (
      item: NfeData,
      options?: { searchBy: string; orderSupplier: string }
    ) => {
      activeLoading()
      let purchaseNumber
      if (!options?.orderSupplier) {
        if (item.purchase_number?.length) {
          purchaseNumber = JSON.parse(item.purchase_number)
          options = {
            orderSupplier: purchaseNumber.join(','),
            searchBy: 'orderSupplier'
          }
        }
      }
      try {
        const response = await api.post<PurchasesRequestsQuotes[]>(
          '/financial/purchasesRequestsQuotes/create',
          {
            nf_id: item.id,
            chNFe: item.chNFe,
            cnpj: item.CNPJ,
            company_id: item.company_id,
            date: item.dhEmi,
            infCpl: item.infCpl,
            searchBy: options?.searchBy,
            orderSupplier: options?.orderSupplier,
            hasFound: !!item.purchase_number?.length
          }
        )
        setPurchasesRequestsQuotes(response.data)
        for (const purchaseRequestQuote of response.data) {
          if (purchaseRequestQuote) {
            if (
              purchaseNumber &&
              purchaseNumber[0]?.includes(
                purchaseRequestQuote.order_supply_number
              )
            ) {
              cacheNF[nfeItem.chNFe] = {}
              cacheNF[nfeItem.chNFe].selectedRequest = {
                ...purchaseRequestQuote
              }
              setCacheNF(cacheNF)
              setSelectedRequest({ ...purchaseRequestQuote })
            }
          }
        }
        disableLoading()
      } catch (error: any) {
        if (error?.request?.response) {
          const message: string = JSON.parse(error?.request?.response)?.message
          if (message === 'This cnpj is different from the invoice') {
            addToast({
              type: 'error',
              title: 'Erro ao carregar as requisições',
              description: 'O CNPJ informado esta difrente da nota fiscal.'
            })
          }
        }
        setPurchasesRequestsQuotes([])
        setSelectedRequest({} as PurchasesRequestsQuotes)
        disableLoading()
      }
    },
    [activeLoading, addToast, cacheNF, disableLoading, nfeItem.chNFe]
  )

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={'Filtro'}
        breadcrumb={breadcrumbList}
      >
        <FilterContaier>
          <div>
            <div className="col-md-3">
              <label htmlFor="company">Empresa</label>
              <select
                className="form-control"
                id="company"
                value={company}
                onChange={({ target }) => setCompany(target.value)}
              >
                <option value="" style={{ display: 'none' }}>
                  Selecione
                </option>
                {companies?.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <label htmlFor="number">Nota Fiscal</label>
              <input
                type="text"
                className="form-control"
                id="number"
                value={number}
                onChange={e => setNumber(e.target.value)}
              ></input>
            </div>
            <div className="col-md-3">
              <label htmlFor="provider">Fornecedor</label>
              <input
                type="text"
                className="form-control"
                id="provider"
                value={provider}
                onChange={e => setProvider(e.target.value)}
              ></input>
            </div>
            <div className="col-md-3">
              <label htmlFor="cnpj">CNPJ</label>
              <input
                type="text"
                className="form-control"
                id="cnpj"
                value={cnpj}
                onChange={e => setCnpj(e.target.value)}
              ></input>
            </div>
            <div className="col-md-3" style={{ marginTop: '15px' }}>
              <DatePicker
                className="form-control"
                name="start_date"
                label="Data de início"
                selected={startDate}
                onChange={e => {
                  if (endDate) {
                    if (e > endDate) {
                      setEndDate(null)
                    }
                  }
                  setStartDate(e)
                }}
                controlled
              />
            </div>
            <div className="col-md-3" style={{ marginTop: '15px' }}>
              <DatePicker
                className="form-control"
                name="end_date"
                label="Data de término"
                selected={endDate}
                minDate={startDate}
                onChange={e => setEndDate(e)}
                controlled
              />
            </div>
            <div className="col-md-2" style={{ marginTop: '15px' }}>
              <label htmlFor="destination">Destinação</label>
              <select
                className="form-control"
                id="destination"
                value={destination}
                onChange={({ target }) => setDestination(target.value)}
              >
                <option value="" style={{ display: 'none' }}>
                  Selecione
                </option>
                {destinations?.map(({ id, value }) => (
                  <option key={id} value={id}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2" style={{ marginTop: '15px' }}>
              <label htmlFor="situation">Situação</label>
              <select
                className="form-control"
                id="situation"
                value={situation}
                onChange={({ target }) => setSituation(target.value)}
              >
                <option value="" style={{ display: 'none' }}>
                  Selecione
                </option>
                {situations?.map(({ id, value }) => (
                  <option key={id} value={id}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-1" style={{ marginTop: '15px' }}>
              <label htmlFor="verified">Verificada</label>
              <select
                className="form-control"
                id="verified"
                value={verified}
                onChange={({ target }) => setVerified(target.value)}
              >
                <option value="" style={{ display: 'none' }}>
                  Selecione
                </option>
                <option value={1}>Sim</option>
                <option value={0}>Não</option>
              </select>
            </div>
            <div className="col-md-1" style={{ marginTop: '15px' }}>
              <label htmlFor="conciled">Conciliada</label>
              <select
                className="form-control"
                id="conciled"
                value={conciled}
                onChange={({ target }) => setConciled(target.value)}
              >
                <option value="" style={{ display: 'none' }}>
                  Selecione
                </option>
                <option value={1}>Sim</option>
                <option value={0}>Não</option>
              </select>
            </div>
          </div>
          <footer>
            <button
              onClick={() => {
                setCompany(null)
                setNumber(null)
                setProvider(null)
                setCnpj(null)
                setStartDate(null)
                setEndDate(null)
                setDestination('')
                setSituation('')
                setVerified('')
                setConciled('')
                setParameters([])
              }}
            >
              LIMPAR
            </button>
            <button
              onClick={() => {
                setParameters([
                  {
                    company_id: company || undefined,
                    nNF: number || undefined,
                    xName: provider || undefined,
                    CNPJ: cnpj || undefined,
                    destination: destination || undefined,
                    cSitNFe: situation || undefined,
                    verified,
                    conciled,
                    startDate,
                    endDate
                  }
                ])
              }}
            >
              BUSCAR
            </button>
          </footer>
          <hr />
        </FilterContaier>
        <ListTitle>
          <p>Listagem</p>
          <hr style={{ paddingBottom: 10 }} />
        </ListTitle>
        <DataTable
          source={'invoices'}
          entity={nameEntity}
          format={{ orderBy: 'nnf' }}
          orderByField="nnf"
          orderBySort="DESC"
          notHasChildren
          headers={headers}
          searchParameters={parameters}
          customHeaders={[
            {
              field: 'purchase_request_id',
              name: 'Requisição',
              sortable: false,
              element: item => (
                <a
                  style={{ color: '#337ab7' }}
                  href={`https://multfluxosistema.com.br/compras_requisicoes/view/${item.purchase_request_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.purchase_request_id}
                </a>
              )
            },
            {
              field: 'destination',
              name: 'Destinação',
              sortable: false,
              element: item => (
                <select
                  className="form-control"
                  id="company"
                  defaultValue={item.destination}
                  onChange={async ({ target }) => {
                    activeLoading()
                    try {
                      await api.put(
                        `financial/invoicesReceived/update/${item.id}`,
                        {
                          destination: target.value
                        }
                      )
                      addToast({
                        type: 'success',
                        title: 'Nota atualizada!',
                        description: 'A destinação da nota foi atualizada.'
                      })
                    } catch (error) {
                      addToast({
                        type: 'error',
                        title: 'Erro ao atualizar nota',
                        description: 'Por favor, tente novamente!'
                      })
                      disableLoading()
                    }
                    disableLoading()
                  }}
                >
                  <option value="" style={{ display: 'none' }}>
                    Selecione
                  </option>
                  {destinations.map(({ id, value }) => (
                    <option key={id} value={id}>
                      {value}
                    </option>
                  ))}
                </select>
              )
            },
            {
              field: 'verified',
              name: 'Verificada',
              sortable: true,
              element: item => (
                <p style={{ textAlign: 'left' }}>
                  {Number(item.verified) === 1 ? 'Sim' : 'Não'}
                </p>
              )
            }
          ]}
          actions={[
            {
              name: 'CONCILIÇÃO',
              title: 'CONCILIÇÃO',
              element: (item: NfeData) => {
                return (
                  <FontAwesomeIcon
                    size="lg"
                    style={{ marginTop: '12px' }}
                    icon={faCheckSquare}
                    color={item.conciled !== 1 ? 'red' : undefined}
                  />
                )
              },
              onClick: async (item: NfeData) => {
                activeLoading()
                setModalRequests(true)

                const nfProducts = JSON.parse(item.products.toString())
                let nfProductsArray
                if (!nfProducts.length) {
                  nfProductsArray = [nfProducts]
                } else {
                  nfProductsArray = [...nfProducts]
                }
                const convertedArrayProducts = nfProductsArray
                  .map(product => product && { ...product.prod })
                  .sort((currentProduct: NfeProduct, nextProduct: NfeProduct) =>
                    currentProduct.xProd.localeCompare(nextProduct.xProd)
                  )
                setNfeItem({
                  ...item,
                  products: convertedArrayProducts
                })
                console.log(item)
                setSelectProducts(convertedArrayProducts)
                handleSearchRequestQuotes(item)
              }
            },
            {
              name: 'DANFE',
              title: 'DANFE',
              htmlIcon: (
                <FontAwesomeIcon
                  size="lg"
                  style={{ marginTop: '12px' }}
                  icon={faFilePdf}
                />
              ),
              onClick: item => {
                const cnpj =
                  Number(item.company_id) === 1
                    ? '15181901000102'
                    : '34143237000143'
                const a = document.createElement('a')
                api
                  .post(
                    '/financial/InvoicesReceived/download',
                    {
                      cnpj,
                      type: 'pdf',
                      chNFe: item.chNFe,
                      date: item.dhEmi
                    },
                    {
                      responseType: 'arraybuffer'
                    }
                  )
                  .then(response => {
                    const blob = new Blob([response.data], {
                      type: 'application/pdf'
                    })
                    const url = window.URL.createObjectURL(blob)
                    a.href = url
                    a.download = `${item.chNFe}.pdf`
                    a.click()
                    disableLoading()
                  })
              }
            },
            {
              name: 'xml',
              title: 'XML',
              htmlIcon: (
                <img
                  style={{ marginTop: '1rem', opacity: '0.7' }}
                  src={xmlIconDark}
                  width={16}
                ></img>
              ),
              onClick: item => {
                const cnpj =
                  Number(item.company_id) === 1
                    ? '15181901000102'
                    : '34143237000143'
                const a = document.createElement('a')
                api
                  .post('/financial/InvoicesReceived/download', {
                    cnpj,
                    type: 'xml',
                    chNFe: item.chNFe,
                    date: item.dhEmi
                  })
                  .then(response => {
                    const blob = new Blob([response.data], {
                      type: 'application/xml'
                    })
                    const url = window.URL.createObjectURL(blob)
                    a.href = url
                    a.download = `${item.chNFe}-nfe.xml`
                    a.click()
                    disableLoading()
                  })
              }
            }
          ]}
        />
      </Container>
      {modalRequests && (
        <Modal
          refModal={refModalRequest}
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalRequests}
          pageTitle={'Ordens de Compra'}
          styles={{ padding: 0 }}
          Children={
            <div className="form-body" style={{ marginTop: -30 }}>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="name" className="control-label">
                      Fornecedor
                    </label>
                    <p>{nfeItem?.xNome}</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="name" className="control-label">
                      CNPJ
                    </label>
                    <p>{nfeItem?.CNPJ}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="name" className="control-label">
                      NF Data
                    </label>
                    <p>{nfeItem?.dhEmi}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="name" className="control-label">
                      NF Valor
                    </label>
                    <p>{nfeItem?.vNF}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="name" className="control-label">
                      Busca Ordem Compra
                    </label>
                    <div
                      style={{
                        display: 'flex'
                      }}
                    >
                      <input
                        style={{
                          padding: '5px 10px',
                          outline: 'none',
                          border: '1px solid #c2cad8'
                        }}
                        onChange={event =>
                          setOrderSupplyNumber(event.target.value)
                        }
                      />
                      <Button
                        className="btn dark btn-sm sbold uppercase"
                        onClick={() =>
                          handleSearchRequestQuotes(nfeItem, {
                            orderSupplier: orderSupplyNumber,
                            searchBy: 'orderSupplier'
                          })
                        }
                      >
                        Buscar
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <Table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Requisição</th>
                    <th>O.C</th>
                    <th>O.C Data</th>
                    <th>O.C Valor</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {purchasesRequestsQuotes.length ? (
                    purchasesRequestsQuotes.map(purchaseRequestQuote => (
                      <React.Fragment
                        key={purchaseRequestQuote.purchase_request_id}
                      >
                        <TableRow>
                          <td
                            style={{ padding: '10px' }}
                            onClick={() => {
                              cacheNF[nfeItem.chNFe] = {}
                              cacheNF[nfeItem.chNFe].selectedRequest = {
                                ...purchaseRequestQuote
                              }
                              setCacheNF(cacheNF)
                              setSelectedRequest({ ...purchaseRequestQuote })
                            }}
                          >
                            <div
                              className="check-container form-content col-md-12"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                height: '100%'
                              }}
                            >
                              <input
                                type="checkbox"
                                style={{ cursor: 'pointer' }}
                                readOnly
                                checked={
                                  selectedRequest?.purchase_request_id ===
                                  purchaseRequestQuote.purchase_request_id
                                }
                              />
                            </div>
                          </td>
                          <td>{purchaseRequestQuote.purchase_request_id}</td>
                          <td>{purchaseRequestQuote.order_supply_number}</td>
                          <td>
                            {purchaseRequestQuote.order_supply_date_issue}
                          </td>
                          <td>{purchaseRequestQuote.order_supply_value}</td>
                          <td style={{ textAlign: 'center' }}>
                            <a
                              className="link"
                              key={Math.random()}
                              title="Visualizar"
                              target="_blank"
                              href={`https://multfluxosistema.com.br/compras_requisicoes/view/${purchaseRequestQuote.purchase_request_id}`}
                              rel="noreferrer"
                            >
                              <span className="fa fa-search" />
                            </a>
                          </td>
                        </TableRow>
                        {!!selectedRequest.id &&
                          purchaseRequestQuote.id === selectedRequest.id && (
                            <td colSpan={6}>
                              <table>
                                <tr>
                                  <tr>
                                    <th style={{ width: '384px' }}>
                                      Item Cotação
                                    </th>
                                    <th style={{ width: '112.27px' }}>
                                      Qtd. Cot
                                    </th>
                                    <th style={{ width: '490px' }}>
                                      Item Nota Fiscal
                                    </th>
                                    <th style={{ width: '142px' }}>Qtd. Rec</th>
                                  </tr>
                                  {selectedRequest.items ? (
                                    selectedRequest.items.map(item => (
                                      <React.Fragment key={item.name}>
                                        <TableRow>
                                          <td>{item.name}</td>
                                          <td>{item.quantity}</td>
                                          <td>
                                            <select
                                              className="form-control"
                                              onChange={event =>
                                                handleSelectItem(event, item)
                                              }
                                              defaultValue={
                                                selectedNFProducts?.find(
                                                  product =>
                                                    product?.parent === item?.id
                                                )?.nfQuantity || 'unlock'
                                              }
                                            >
                                              <option value="unlock">
                                                Selecione
                                              </option>
                                              {selectProducts.length &&
                                                selectProducts.map(
                                                  ({
                                                    qCom,
                                                    xProd,
                                                    parent_id
                                                  }) =>
                                                    (parent_id === item.id ||
                                                      !parent_id) && (
                                                      <option
                                                        key={xProd}
                                                        value={qCom}
                                                      >
                                                        {xProd}
                                                      </option>
                                                    )
                                                )}
                                            </select>
                                          </td>
                                          <td>
                                            {
                                              selectedNFProducts.find(
                                                product =>
                                                  product.parent === item.id
                                              )?.nfQuantity
                                            }
                                          </td>
                                        </TableRow>
                                      </React.Fragment>
                                    ))
                                  ) : (
                                    <tr>
                                      <td
                                        style={{ textAlign: 'center' }}
                                        colSpan={6}
                                      >
                                        Sem Itens
                                      </td>
                                    </tr>
                                  )}
                                </tr>
                              </table>
                            </td>
                          )}
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td style={{ textAlign: 'center' }} colSpan={6}>
                        Sem Requisições
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <hr className="divider" />
              <div className="form-actions right">
                <Button
                  type="button"
                  onClick={() => handleSave(selectedRequest)}
                  className="btn dark btn-sm sbold uppercase"
                >
                  Salvar
                </Button>
              </div>
            </div>
          }
        />
      )}
    </>
  )
}

export default InvoicesReceivedList
