import React, { useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { FinancialCategoryType, FormDataProtocol } from '../../domain/protocols'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  valueInput?: string
  isOpenInModal?: false | IsOpenInModalProps
  typeForm:
    | 'create'
    | {
        idUpdate: number
        inputValue: string
        idParentUpdate?: string
        type: string
      }
}

export const FormCategory = ({
  isOpenInModal,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] =
    useState<{ name: string; type: string }>()

  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    if (typeForm !== 'create') {
      setDefaultValues({
        name: typeForm.inputValue,
        type: typeForm.type
      })
    }
  }, [typeForm])

  const onSubmitForm = async (data: FormDataProtocol) => {
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose, idParent } = isOpenInModal
          const dataCreate: {
            name: string
            parent_id: number
            type: FinancialCategoryType
          } = {
            name: data.name,
            parent_id: idParent,
            type: data.type
          }
          activeLoading()
          try {
            await api.post(apiCreate(), dataCreate)
            handleOnClose()
            disableLoading()
            updateDataTable()
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            const dataCreate: { name: string; type: FinancialCategoryType } = {
              name: data.name,
              type: data.type
            }
            activeLoading()
            await api.post(apiCreate(), dataCreate)
            disableLoading()
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const id = typeForm.idUpdate
          const dataUpdate: { name: string; type: FinancialCategoryType } = {
            name: data.name,
            type: data.type
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate: { name: string; type: FinancialCategoryType } = {
            name: data.name,
            type: data.type
          }
          const id = typeForm.idUpdate

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      defaultValues={typeForm === 'create' ? {} : defaultValues}
    >
      <>
        <div className="row">
          <div className="form-content col-md-3">
            <Input
              name="name"
              className="form-control"
              label="Nome"
              rules={{ required: true }}
            />
          </div>
          {!isOpenInModal && (
            <div className="form-content col-md-3">
              <Select
                name="type"
                className="form-control"
                label="Tipo"
                options={[
                  {
                    name: 'RECEITA',
                    value: 'RECEITA'
                  },
                  {
                    name: 'DESPESA',
                    value: 'DESPESA'
                  },
                  {
                    name: 'AMBAS',
                    value: 'AMBAS'
                  }
                ]}
                blank
                defaultValue={''}
              />
            </div>
          )}
        </div>
        {isOpenInModal && (
          <hr
            className="divider"
            style={{
              position: 'relative',
              width: '105.5%',
              transform: 'translateX(-2.9%)'
            }}
          />
        )}
        <div className="form-actions right">
          {isOpenInModal && (
            <button
              onClick={isOpenInModal.handleOnClose}
              type="reset"
              className="btn btn-default btn-sm sbold uppercase"
            >
              Fechar
            </button>
          )}
          <Button type="submit" className="btn dark btn-sm sbold uppercase">
            Salvar
          </Button>
        </div>
      </>
    </Form>
  )
}
