import React, { ChangeEvent, useEffect, useState } from 'react'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import {
  PageContainer,
  Activities,
  Attachments,
  Avatar,
  AvatarSmall,
  Divider,
  Reply,
  ReplyInput,
  TaskDate
} from './style'
import { FaCalendar, FaClipboard, FaUser } from 'react-icons/fa'

import avatar from '../../../../assets/layouts/layout3/img/avatar.png'
import gerente from '../../../../assets/layouts/layout3/img/avatar2.jpg'
import fun1 from '../../../../assets/layouts/layout3/img/avatar3.jpg'
import Button from '../../../../components/Button'
import api from '../../../../services/api'
import Form, { Textarea } from '../../../../components/Form'

type Attachment = {
  id: number
  filename: string
  type: string
}
type Comment = {
  id: number
  message: string
  user: {
    name: string
    avatar_url?: string
  }
  attachments: Attachment[]
}
interface Task {
  id?: number
  title: string
  description: string
  start: string
  end: string
  status: string
  userDemandant: {
    name: string
    avatar_url?: string
  }
  taskUsers: { user: { name: string } }[]
  comments: Comment[]
  attachments: Attachment[]
}

type ViewProps = {
  taskId?: number
  isNew?: boolean
  getTasks: () => void
}

const lorem =
  'Donec rhoncus eget nibh non finibus. Phasellus ut justo quis mi aliquet convallis. Vivamus vitae mollis risus'
const lorem3 =
  'Ut mollis vestibulum tempor. Mauris arcu quam, tincidunt eu fermentum interdum, ultricies nec ipsum. Class aptent taciti sociosqu ad litora torquent per conubia nostra'
const lorem4 = 'lobortis justo ultrices non. Suspendisse eleifend augue diam'

const TASK_FAKE = {
  id: 1,
  title: lorem,
  author: 'string',
  description: lorem3,
  start: '18/07/2022',
  end: '19/10/2022',
  status: 'NOVA',
  userDemandant: {
    name: 'Gerente 1',
    avatar_url: gerente
  },
  taskUsers: [
    { user: { name: 'Funcionario 1' } },
    { user: { name: 'Funcionário 2' } }
  ],
  comments: [
    {
      id: 1,
      message: lorem4,
      user: {
        name: 'Funcionário 2',
        avatar_url: fun1
      },
      attachments: [{ id: 1, filename: 'arquivo1.pdf', type: 'PDF' }]
    }
  ],
  attachments: [{ id: 1, filename: 'arquivo1.pdf', type: 'DOCX' }]
}

const View = ({ taskId, isNew, getTasks }: ViewProps) => {
  const [task, setTask] = useState<Task | null>()
  const { addToast } = useToast()
  const [defaultValues, setDefaultValues] = useState({ message: '' })
  const { disableLoading, activeLoading } = useLoading()

  const [files, setFiles] = useState<File[]>([])

  const loadTask = async (): Promise<void> => {
    activeLoading()
    // const { data } = await api.get(`/personelDepartment/tasks/view/${taskId}`)
    try {
      setTask(TASK_FAKE)
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar a campanha',
        description:
          'Houve um error ao carregar o campanha, tente novamente mais tarde!'
      })
    }
  }

  useEffect(() => {
    if (taskId) {
      loadTask()
    }
  }, [taskId])

  const [alert, setAlert] = useState(false)

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlert(false)
  }

  if (!taskId || !task) {
    return <></>
  }

  const onSubmitComment = async (data: Comment) => {
    const formData = new FormData()
    formData.append('task_id', String(taskId))
    formData.append('message', data.message)
    files.forEach(file => {
      formData.append('files[]', file)
    })
    await api.post('/personelDepartment/comments/create', formData)
    setFiles([])
    loadTask()
    setDefaultValues({ message: '' })
  }

  function onChangeFileHandler(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return
    const file = event.target.files[0]

    setFiles(prev => [...prev, file])
  }

  return (
    <PageContainer>
      {isNew && (
        <div>
          <Button
            type="button"
            className="btn dark btn sbold uppercase"
            style={{ margin: '20px 0px' }}
            onClick={() => {
              getTasks()
            }}
          >
            Aceitar Tarefa
          </Button>
        </div>
      )}
      <h4>
        <Avatar src={task.userDemandant?.avatar_url || avatar} /> {task?.title}
      </h4>
      <TaskDate>
        <FaCalendar style={{ marginRight: '5px' }} />
        {task?.start.substring(0, 10)}
        <div style={{ margin: '0px 10px' }}>-</div>
        {task?.end}
        <p style={{ marginLeft: '20px' }}>
          <FaUser />{' '}
          {task?.taskUsers?.map(
            (user, i) =>
              `${user.user.name}${task?.taskUsers.length - 1 !== i ? ' |' : ''}`
          )}
        </p>
      </TaskDate>
      <p>{task?.description}</p>
      <Attachments>
        {task.attachments.map(att => (
          <a
            key={att.id}
            onClick={() => {
              activeLoading()
              const a = document.createElement('a')
              api
                .post(
                  'personelDepartment/tasks/file',
                  { name: ['uploads', 'tasks', 'attachments', att.filename] },
                  {
                    responseType: 'arraybuffer'
                  }
                )
                .then(response => {
                  const blob = new Blob([response.data], {
                    type: a.type.toLowerCase()
                  })
                  const url = window.URL.createObjectURL(blob)
                  a.href = url
                  a.download = att.filename.substring(
                    att.filename.indexOf('-') + 1
                  )
                  a.click()
                  disableLoading()
                })
            }}
          >
            {att.filename.substring(att.filename.indexOf('-') + 1)}
          </a>
        ))}
      </Attachments>
      <Divider />
      <div style={{ marginTop: '50px' }}></div>
      <Activities>
        <h4>Comentários</h4>
        {!task.comments.length && (
          <i style={{ color: '#ccc' }}>Nenhhum comentário ainda...</i>
        )}
        {task.comments?.map(comment => (
          <Reply key={comment.id}>
            <i>
              <AvatarSmall src={comment?.user.avatar_url || avatar} />{' '}
              {comment?.user.name}:
            </i>
            <p>{comment?.message}</p>
            {comment.attachments.map(att => (
              <a
                key={att.id}
                onClick={() => {
                  activeLoading()
                  const a = document.createElement('a')
                  api
                    .post(
                      'personelDepartment/tasks/file',
                      {
                        name: ['uploads', 'tasks', 'attachments', att.filename]
                      },
                      { responseType: 'arraybuffer' }
                    )
                    .then(response => {
                      const blob = new Blob([response.data], {
                        type: a.type.toLowerCase()
                      })
                      const url = window.URL.createObjectURL(blob)
                      a.href = url
                      a.download = att.filename.substring(
                        att.filename.indexOf('-') + 1
                      )
                      a.click()
                      disableLoading()
                    })
                }}
              >
                {att.filename.substring(att.filename.indexOf('-') + 1)}
              </a>
            ))}
          </Reply>
        ))}
        <Form onSubmit={onSubmitComment} defaultValues={defaultValues}>
          <ReplyInput>
            <Textarea
              placeholder="Digite sua mensagem..."
              className="form-control"
              name="message"
              id="reply"
              rows={5}
              rules={{ required: true }}
            ></Textarea>
            <div className="row">
              {files?.map(file => (
                <a style={{ marginLeft: '20px' }} key={file.name}>
                  <FaClipboard style={{ marginRight: '4px' }} />
                  {file.name}
                </a>
              ))}
            </div>
            <div>
              <label
                htmlFor="commentAttachment"
                className="btn dark btn-sm sbold uppercase"
              >
                Inserir Anexo
              </label>
              <input
                type="file"
                name="commentAttachment"
                id="commentAttachment"
                onChange={onChangeFileHandler}
                style={{ display: 'none' }}
              />
              <button type="submit" className="btn dark btn-sm sbold uppercase">
                Enviar
              </button>
            </div>
          </ReplyInput>
        </Form>
      </Activities>
      <Alert
        message={'Tem certeza que deseja excluir a tarefa ?'}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        isActive={alert}
        onClickConfirmButton={() => 1}
      />
    </PageContainer>
  )
}
export default View
