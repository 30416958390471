import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PrivateRoutes from './Route'
import {
  SignIn,
  SignUp,
  Dashboard,
  Profile,
  Autenticate
} from './users/UsersRouter'
import { ProductCategoriesRouter } from './products/categories/categories-router'
import { ProductAttributesRouter } from './products/attributes/ProductAtributesRouter'
import { ProductUnitMeasuredRouter } from './products/unitMeasured/ProductUnitMeasured'
import { ProductRegisterRouter } from './products/register/prouctRegisterRouter'
import { StocksRouter } from './products/stocks/stocksRouter'
import { ExpenseBudgetRouter } from './financial/ExpenseBudgetRouter'
import { ProductCategoryBudgetRouter } from './financial/ProductCategoryBudgetRouter'
import { ConfigurationsRouter } from './config/ConfigurationsRouter'
import { CostCentersRouter } from './administrative/costCerters/CostCentersRouter'
import { MenuRouter } from './config/MenuRouter'
import { RolesRouter } from './users/RolesRouter'
import { UsersRouter } from './config/UsersRouter'
import { WarehouseRegisterRouter } from './warehouse/WarehouseRouter'
import { PickOrderRouter } from './warehouse/pickOrderRouter'
import { WarehouseRequestsRouter } from './warehouse/WarehouseRequestsRouter'
import { ExpeditionOrdersRouter } from './warehouse/expedition/OrdersRouter'
import { ExpeditionOrdersStatusRouter } from './warehouse/expedition/OrdersStatusRouter'
import { CampaingsRegisterRouter } from './commercial/CampaingsRouter'
import { CompetitorsRegisterRouter } from './commercial/CompetitorsRouter'
import { PermissionsRouter } from './users/PermissionsRouter'
import { TaxCfopRouter } from './taxes/TaxCfopRouter'
import { TaxNcmRouter } from './taxes/TaxNcmRouter'
import { TaxSituationRouter } from './taxes/TaxSituationRouter'
import { TaxNatureOperationRouter } from './taxes/TaxNatureOperationRouter'
import { CacheRegisterRouter } from './cache/CacheRouter'
import { CommercialRouter } from './commercial/CommercialRouter'
import { GoalsEmployeesRouter } from './commercial/GoalsEmployeesRouter'
import { CommissionReleaseRouter } from './commercial/CommissionReleaseRouter'
import { ExpenseAnalysesRouter } from './financial/ExpensesAnalysesRouter'
import { FinancialCategoriesRouter } from './financial/FinancialCategoryRouter'
import { FinancialTransactionsRouter } from './financial/FinancialTransactionsRouter'
import { DigitalCertificatesRouter } from './financial/DigitalCertificatesRouter'
import { IncomeAnalysesRouter } from './financial/IncomeAnalysesRouter'
import { InvoicesReceivedRouter } from './financial/InvoicesReceivedRouter'

import { useAuth } from '../hooks/auth'
import { NotFoundPage } from '../pages/NotFound'
import { IpAllowedRouter } from './users/IpAllowedRouter'
import { CashFlowAnalysisRouter } from './financial/CashFlowAnalysisRouter'
import { ProcessingErrorsRouter } from './processingErrors/ProcessingErrorsRouter'
import { DefaultedOrdersRouter } from './financial/DefaultedOrdersRouter'
import { PaymentRemittanceFilesRouter } from './financial/PaymentRemittanceFilesRouter'
import { PanelLocationRouter } from './commercial/PanelLocationRouter'
import { OrdersRouter } from './commercial/OrdersRouter'

import { TasksRouter } from './personelDepartment/TasksRouter'
import { PermissionsAssociatedRouter } from './users/PermissionsAssociatedRouter'

const Routes = () => {
  const { userLogged, backupHistory } = useAuth()
  return (
    <Switch>
      <Route path="/" exact component={SignIn}>
        {!backupHistory.length && userLogged() && <Redirect to="/dashboard" />}
      </Route>

      <Route path="/signup" component={SignUp} />
      <Route path="/autenticate/:data" component={Autenticate} />

      <PrivateRoutes path="/dashboard" component={Dashboard} />
      <PrivateRoutes path="/users/profile" component={Profile} />

      <Route
        path="/financial/configurations"
        component={ConfigurationsRouter}
      />
      <Route
        path="/warehouse/productAttributes"
        component={ProductAttributesRouter}
      />
      <Route
        path="/warehouse/productCategories"
        component={ProductCategoriesRouter}
      />
      <Route
        path="/warehouse/productUnitMeasured"
        component={ProductUnitMeasuredRouter}
      />
      <Route path="/warehouse/stocks" component={StocksRouter} />
      <Route
        path="/warehouse/warehouseRequests"
        component={WarehouseRequestsRouter}
      />
      <Route
        path="/warehouse/expedition/orders"
        component={ExpeditionOrdersRouter}
      />
      <Route
        path="/warehouse/expedition/ordersStatus"
        component={ExpeditionOrdersStatusRouter}
      />
      <Route
        path="/financial/categories"
        component={FinancialCategoriesRouter}
      />
      <Route
        path="/financial/financialTransactions"
        component={FinancialTransactionsRouter}
      />
      <Route
        path="/financial/digitalCertificates"
        component={DigitalCertificatesRouter}
      />
      <Route path="/financial/expenseBudget" component={ExpenseBudgetRouter} />
      <Route
        path="/financial/productCategoryBudget"
        component={ProductCategoryBudgetRouter}
      />

      <Route path="/costCenters" component={CostCentersRouter} />
      <Route path="/users/menus" component={MenuRouter} />

      <Route path="/financial/costCenters" component={CostCentersRouter} />
      <Route path="/menus" component={MenuRouter} />

      <Route path="/users/roles" component={RolesRouter} />
      <Route path="/warehouse/pickOrder" component={PickOrderRouter} />
      <Route path="/warehouse/products" component={ProductRegisterRouter} />
      <Route path="/warehouse" component={WarehouseRegisterRouter} />
      <Route path="/commercial/campaings" component={CampaingsRegisterRouter} />
      <Route
        path="/commercial/competitors"
        component={CompetitorsRegisterRouter}
      />
      <Route path="/commercial/panelLocation" component={PanelLocationRouter} />
      <Route path="/taxes/taxCfop" component={TaxCfopRouter} />
      <Route path="/taxes/taxNcm" component={TaxNcmRouter} />
      <Route path="/taxes/taxSituation" component={TaxSituationRouter} />
      <Route
        path="/taxes/taxNatureOperation"
        component={TaxNatureOperationRouter}
      />
      <Route path="/cache" component={CacheRegisterRouter} />
      <Route path="/commercial/goalAnalysis" component={CommercialRouter} />
      <Route path="/commercial/orders" component={OrdersRouter} />
      <Route
        path="/commercial/goalsEmployees"
        component={GoalsEmployeesRouter}
      />
      <Route
        path="/commercial/commissionRelease"
        component={CommissionReleaseRouter}
      />
      <Route
        path="/financial/expensesAnalysis"
        component={ExpenseAnalysesRouter}
      />
      <Route
        path="/financial/cashflowanalysis"
        component={CashFlowAnalysisRouter}
      />
      <Route path="/financial/income" component={IncomeAnalysesRouter} />
      <Route
        path="/financial/invoicesReceived"
        component={InvoicesReceivedRouter}
      />
      <Route
        path="/financial/defaultedOrders"
        component={DefaultedOrdersRouter}
      />
      <Route
        path="/financial/paymentRemittanceFiles"
        component={PaymentRemittanceFilesRouter}
      />
      <Route path="/users/ipAllowed" component={IpAllowedRouter} />
      <Route path="/users/permissions" component={PermissionsRouter} />
      <Route
        path="/users/permissionsAssociated"
        component={PermissionsAssociatedRouter}
      />
      <Route path="/users" component={UsersRouter} />
      <Route path="/processingErrors" component={ProcessingErrorsRouter} />

      <Route path="/personelDepartment/tasks" component={TasksRouter} />

      <Route path="*" component={NotFoundPage} />
    </Switch>
  )
}
export default Routes
