import React from 'react'
import { TooltipComponent } from '../../../../../../../../../components/TooltipComponent'
import {
  typeUnitMensuredWeight,
  typeUnitMensuredDetails
} from '../../../../../domain/data/products/unitMensured'
import { Container } from './styles'

import {
  Input,
  Select,
  Textarea
} from '../../../../../../../../../components/Form'
import { Alert } from '../../../../../../../../../components/Alert'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

export const DetailsTab = (
  index: number,
  onSearchOldProduct: any,
  setStock: any,
  stock: any,
  oldProduct: any,
  handlerOnClickButtonConfirmOldProduct: any,
  handlerOnClickButtonCancelOldProduct: any,
  alertOldProduct: any
): JSX.Element => {
  return (
    <>
      <td colSpan={100}>
        <Container className="row">
          <div className="form-content col-md-3">
            <TooltipComponent label="Nome" message="Informe o nome" />
            <Input
              className="form-control"
              name={`stock.${index}.name`}
              rules={{ required: true }}
              onChange={e =>
                setStock((prev: any) => {
                  const copy = [...prev]
                  copy[index].name = e.target.value
                  return copy
                })
              }
            />
          </div>
          <div className="form-content col-md-3">
            <TooltipComponent
              label="Peso medida"
              message="Selecione a unidade de medida"
            />
            <Select
              name={`stock.${index}.details.measure_weight`}
              className="form-control"
              options={typeUnitMensuredWeight.map(({ label, value }) => ({
                value: value.toUpperCase(),
                name: label
              }))}
              controlled
              blank
            />
          </div>
          <div className="form-content col-md-3">
            <TooltipComponent label="Peso" message="Informe o peso" />
            <Input
              className="form-control"
              name={`stock.${index}.details.weight`}
              price
            />
          </div>
          <div className="form-content col-md-3">
            <TooltipComponent
              label="Dimensão medida"
              message="Selecione a unidade de medida"
            />
            <Select
              name={`stock.${index}.details.measure`}
              className="form-control"
              options={typeUnitMensuredDetails.map(({ label, value }) => ({
                value: value.toUpperCase(),
                name: label
              }))}
              controlled
              blank
            />
          </div>
        </Container>
        <Container className="row">
          <div className="form-content col-md-3">
            <TooltipComponent label="Largura" message="Informe a largura" />
            <Input
              className="form-control"
              name={`stock.${index}.details.width`}
              price
            />
          </div>
          <div className="form-content col-md-3">
            <TooltipComponent label="Altura" message="Informe a altura" />
            <Input
              className="form-control"
              name={`stock.${index}.details.height`}
              price
            />
          </div>
          <div className="form-content col-md-3">
            <TooltipComponent
              label="Comprimento"
              message="Informe o comprimento"
            />
            <Input
              className="form-control"
              name={`stock.${index}.details.length`}
              price
            />
          </div>
          <div className="form-content col-md-3">
            <TooltipComponent label="Espessura" message="Informe a espessura" />
            <Input
              className="form-control"
              name={`stock.${index}.details.thickness`}
              price
            />
          </div>
        </Container>
        <Container className="row">
          <div className="form-content col-md-12">
            <TooltipComponent
              label="Descrição e detalhes"
              message="Informe a descrição e detalhes"
            />
            <Textarea
              name={`stock.${index}.details.description_details`}
              className="form-control"
              rules={{ required: true }}
            />
          </div>
        </Container>
        <Container className="row">
          <div className="form-content col-md-12">
            <TooltipComponent
              label="Especificação Técnica"
              message="Informe a especificação Técnica"
            />
            <Textarea
              name={`stock.${index}.details.technical_specification`}
              className="form-control"
              rules={{ required: true }}
            />
          </div>
        </Container>
        <Container className="row">
          <div className="form-content col-md-12">
            <TooltipComponent
              label="Forma de utilização"
              message="Informe a forma de utilização"
            />
            <Textarea
              name={`stock.${index}.details.way_use`}
              className="form-control"
              rules={{ required: true }}
            />
          </div>
        </Container>
        <Container className="row">
          <div className="form-content col-md-3">
            <TooltipComponent
              label="Código do Produto"
              message="Informe o código do produto"
            />
            <Input
              className="form-control"
              name={`stock.${index}.product_code`}
            />
          </div>
          <div className="form-content col-md-3">
            <TooltipComponent
              label="Produto antigo"
              message="Informe o código do produto antigo"
            />
            <div className="row">
              <div className="col-md-10">
                <Input
                  name={`stock.${index}.details.old_product`}
                  className="form-control"
                  onChange={e =>
                    setStock((prev: any) => {
                      const copy = [...prev]
                      copy[index].details = {
                        ...copy[index].details,
                        old_product: e.target.value
                      }
                      return copy
                    })
                  }
                />
              </div>
              <div
                className="col-md-2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '10px'
                }}
              >
                <span
                  onClick={() => onSearchOldProduct(index)}
                  className="fa fa-search"
                  style={{
                    fontSize: '18px',
                    marginBottom: '1rem',
                    cursor: 'pointer'
                  }}
                />
                {oldProduct?.[index]?.show && (
                  <div style={{ paddingBottom: '10px' }}>
                    <TooltipComponent
                      label=""
                      message={oldProduct[index].name}
                      icon={faCheckCircle}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
        <Input
          value={stock[index].details?.old_product_name}
          name={`stock.${index}.details.old_product_name`}
          type="hidden"
          controlled
        />
      </td>
      <Alert
        message={`Produto antigo: ${oldProduct?.[index]?.name} ?`}
        onClickCancellButton={handlerOnClickButtonCancelOldProduct}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmOldProduct(index)
        }
        isActive={alertOldProduct?.[index]}
      />
    </>
  )
}
