/* eslint-disable array-callback-return */
/* eslint-disable no-constant-condition */
/* eslint-disable no-self-compare */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ControlPanel } from './style'
import { AmChartMap } from '../../../components/AmChartMap'
import { AmChartMixedColumn } from '../../../components/AmChartMixedColumn'
import { AmChartSortedBar } from '../../../components/AmChartSortedBar'
import { HeaderPortlet } from '../../../components/HeaderPortlet'
import { TablePortlet } from '../../../components/TablePortlet'
import Container from '../../../components/Container'
import { useLoading } from '../../../hooks/loading'
import api from '../../../services/api'
import currency from 'currency.js'
import { AmChartPizza } from '../../../components/AmChartPizza'
import * as am4core from '@amcharts/amcharts4/core'

const FinanceIncome: React.FC = () => {
  const [financeIncomeData, setFinanceIncomeData] = useState<any>({})
  const [financeIncomePeriodData, setFinanceIncomePeriodData] = useState<any>(
    []
  )
  const [incomeData, setIncomeData] = useState([])
  const [groupProduct, setGroupProduct] = useState([])
  const [dataUpdateProducts, setDataUpdateProducts] = useState([])
  const [dataUpdateGroupProduct, setDataUpdateGroupProduct] = useState([])
  const [dataUpdateMixed, setDataUpdateMixed] = useState([])
  const [dataUpdateMap, setDataUpdateMap] = useState([])
  const [selectedDate, setSelectedDate] = useState('Mes')
  const [selectedIncomeId, setSelectedIncomeId] = useState(0)
  const { activeLoading, disableLoading } = useLoading()
  const [datesRange, setDatesRange] = useState({
    minDate: undefined,
    maxDate: undefined
  })
  const BRL = (value: any) =>
    currency(value, {
      symbol: '',
      decimal: ',',
      separator: '.'
    })
  const dateRange = (date: string) => {
    const year = date.split(' ')[2]
    const minDate = new Date(`${year}/${date.split(' ')[1]}`)
      .toISOString()
      .slice(0, 7)
    const [, month] = new Date(`${year}/${date.split(' ')[4]}`)
      .toISOString()
      .slice(0, 7)
      .split('-')
    return {
      minDate,
      maxDate: `${year}-${Number(month) + 1}`
    }
  }
  const createBoardIncome = useCallback(
    (data: any[], selectDate?: any, hasDivide?: boolean) => {
      let previewDivide: any
      if (data.length) {
        if (!hasDivide) {
          return data
            .map((finance: any) => {
              if (finance.category_name) {
                return {
                  id: finance.category_id,
                  column1: finance.category_name,
                  column2: BRL(finance.expected_value).format(),
                  column3: BRL(finance.realized_value).format(),
                  column4: BRL(finance.realized_value)
                    .subtract(finance.expected_value)
                    .format(),
                  column5: currency(
                    finance.realized_value === 0
                      ? 0
                      : BRL(finance.realized_value)
                          .multiply(100)
                          .divide(finance.expected_value)
                          .format(),
                    {
                      symbol: '%',
                      pattern: '#!'
                    }
                  ).format()
                }
              }
            })
            .filter((item: any) => item)
        } else {
          return data
            .map((finance: any) => {
              if (finance.category_name) {
                if (selectDate === 'day') {
                  previewDivide = finance.expected_value / 22
                }
                if (selectDate === 'week') {
                  previewDivide = (finance.expected_value / 22) * 5
                }
                return {
                  id: finance.category_id,
                  column1: finance.category_name,
                  column2: BRL(
                    previewDivide || finance.expected_value
                  ).format(),
                  column3: BRL(finance.realized_value).format(),
                  column4: BRL(finance.realized_value)
                    .subtract(previewDivide || finance.expected_value)
                    .format(),
                  column5: currency(
                    finance.realized_value === 0
                      ? 0
                      : BRL(finance.realized_value)
                          .multiply(100)
                          .divide(previewDivide || finance.expected_value)
                          .format(),
                    {
                      symbol: '%',
                      pattern: '#!'
                    }
                  ).format()
                }
              }
            })
            .filter((item: any) => item)
        }
      }
      return []
    },
    []
  )
  const createBoardGroupProductsIncome = useCallback(
    (data: any[], selectDate?: any, hasDivide?: boolean) => {
      let previewDivide: any
      if (data.length) {
        if (!hasDivide) {
          return data.map(
            (finance: any) =>
              finance && {
                id: finance.group_id,
                column1: finance.group_name,
                column2: BRL(finance.expected_value).format(),
                column3: BRL(finance.product_total_value).format(),
                column4: BRL(finance.product_total_value).subtract(0).format(),
                column5: currency(
                  finance.expected_value === 0 || !finance.expected_value
                    ? 0
                    : BRL(finance.product_total_value)
                        .multiply(100)
                        .divide(finance.expected_value)
                        .format(),
                  {
                    symbol: '%',
                    pattern: '#!'
                  }
                ).format(),
                column6: finance.products_quantity,
                subItems: finance.products.map((product: any) => {
                  return {
                    id: product.id,
                    column1: product.name,

                    column3: BRL(product.product_total_value).format(),
                    column4: BRL(product.product_total_value)
                      .subtract(previewDivide || 0)
                      .format(),

                    column6: product.products_quantity
                  }
                })
              }
          )
        } else {
          return data.map((finance: any) => {
            if (selectDate === 'day') {
              previewDivide = finance.expected_value / 22
            }
            if (selectDate === 'week') {
              previewDivide = (finance.expected_value / 22) * 5
            }
            return {
              id: finance.group_id,
              column1: finance.group_name,
              column2: BRL(previewDivide || finance.expected_value).format(),
              column3: BRL(finance.product_total_value).format(),
              column4: BRL(finance.product_total_value)
                .subtract(previewDivide || finance.expected_value)
                .format(),
              column5: currency(
                previewDivide ||
                  finance.expected_value === 0 ||
                  !(previewDivide || finance.expected_value)
                  ? 0
                  : BRL(finance.product_total_value)
                      .multiply(100)
                      .divide(previewDivide || finance.expected_value)
                      .format(),
                {
                  symbol: '%',
                  pattern: '#!'
                }
              ).format(),
              column6: finance.products_quantity,
              subItems: finance.products.map((product: any) => {
                return {
                  id: product.id,
                  column1: product.name,

                  column3: BRL(product.product_total_value).format(),
                  column4: BRL(product.product_total_value)
                    .subtract(previewDivide || product.expected_value)
                    .format(),

                  column6: product.products_quantity
                }
              })
            }
          })
        }
      }
      return []
    },
    []
  )

  const findBiggerPercetage = useCallback(
    (data: any[], maxValue: number, isParent?: boolean, isLocal?: boolean) => {
      let percentageIncomeData = 0
      data
        .filter((item: any) => item)
        .forEach((product: any) => {
          const percentage = (product.product_total_value / maxValue) * 100
          if (percentageIncomeData < percentage) {
            percentageIncomeData = percentage
          }
        })
      return { percentageIncomeData }
    },
    []
  )

  function createChartPizzaData(
    data: any[],
    color: string,
    percentage: any,
    maxValue: number,
    type?: string
  ) {
    const maxLighten = 0.65
    if (type) {
      return data
        .filter((item: any) => item)
        .sort(
          (currentValue: any, nextValue: any) =>
            Number(nextValue.product_total_value) -
            Number(currentValue.product_total_value)
        )
        .filter((product: any) => Number(product.product_total_value) > 0)
        .map((product: any) => {
          const percent = (Number(product.product_total_value) / maxValue) * 100
          const percentLight = (percentage - percent) / 45
          return {
            type: product.group_name,
            percent,
            value: Number(product.product_total_value),
            color: am4core
              .color(color)
              .lighten(percentLight > maxLighten ? maxLighten : percentLight),
            subs: []
          }
        })
        .flatMap((item: any) => item)
        .filter((item: any) => item)
    }
    return data
      .filter((item: any) => item)
      .sort(
        (currentValue: any, nextValue: any) =>
          Number(nextValue.product_total_value) -
          Number(currentValue.product_total_value)
      )
      .filter((product: any) => Number(product.product_total_value) > 0)
      .map((product: any) => {
        const percent = (Number(product.product_total_value) / maxValue) * 100
        const percentLight = (percentage - percent) / 45
        return {
          type: product.products_name,
          percent,
          value: Number(product.product_total_value),
          color: am4core
            .color(color)
            .lighten(percentLight > maxLighten ? maxLighten : percentLight),
          subs: []
        }
      })
      .flatMap((item: any) => item)
      .filter((item: any) => item)
  }

  async function loadData() {
    activeLoading()
    const transactionFinanceIncome = await api.get(
      '/financial/income/listPerPeriod/date'
    )
    setFinanceIncomeData(transactionFinanceIncome.data)
    setIncomeData(
      createBoardIncome(transactionFinanceIncome.data.financeIncome.month)
    )

    setDataUpdateMixed(
      transactionFinanceIncome.data.graphicData.map((month: any) => {
        return {
          date: month.date,
          goal: month.expected_value,
          goalAccomplished: month.realized_value,
          discount: month.realized_value - month.expected_value,
          lastYearTarget:
            month.expected_value === 0
              ? 0
              : (month.realized_value * 100) / month.expected_value
        }
      })
    )
    setTimeout(() => {
      disableLoading()
    }, 300)
  }

  async function setInitialData(date?: string, income_id?: number) {
    let previewDivide: any
    const selectDate = date.length > 0 ? date : selectedDate
    const selectIncome =
      income_id === 0 || !income_id ? income_id : selectedIncomeId
    setSelectedDate(date.length > 0 ? date : selectedDate)
    setSelectedIncomeId(
      income_id === 0 || !income_id ? income_id : selectedIncomeId
    )
    let selectPeriod: any
    activeLoading()
    if (
      selectDate === 'Mes' ||
      selectDate === 'Mês' ||
      selectDate.includes('period')
    ) {
      selectPeriod = 'month'
    }
    if (selectDate === 'Ano') {
      selectPeriod = 'year'
    }
    if (selectDate === 'Hoje') {
      selectPeriod = 'day'
    }
    if (selectDate === 'Semana') {
      selectPeriod = 'week'
    }
    if (selectDate.includes('period')) {
      const { minDate, maxDate } = JSON.parse(date || selectDate)
      const transactionFinanceIncome = {
        ...financeIncomePeriodData
      }
      setFinanceIncomePeriodData(transactionFinanceIncome)
      setIncomeData(
        createBoardIncome(
          transactionFinanceIncome.data.financeIncome[selectPeriod]
        )
      )

      if (selectIncome) {
        const findIncome = transactionFinanceIncome.data.financeIncome[
          selectPeriod
        ].find((income: any) => income.category_id === selectIncome)
        if (findIncome) {
          setGroupProduct(
            createBoardGroupProductsIncome(findIncome.products, selectPeriod)
          )
          setDatesRange({
            maxDate,
            minDate
          })
          const states = findIncome.orders
            .map((order: any) => order.delivery_destination_state)
            .join(',')
            .split(',')
          setDataUpdateMixed(
            findIncome.graphicMonths.map((month: any) => {
              return {
                date: month.date,
                goal: month.expected_value,
                goalAccomplished: month.realized_value,
                discount: month.realized_value - month.expected_value,
                lastYearTarget:
                  month.expected_value === 0
                    ? 0
                    : (month.realized_value * 100) / month.expected_value
              }
            })
          )
          setDataUpdateMap(
            findIncome.delivery_destination
              .split(',')
              .map((state: any) => state && { MAIL_ST_PROV_C: state })
          )
          const percentProducts = findBiggerPercetage(
            findIncome.orders,
            findIncome.realized_value
          )
          const percentGroupProducts = findBiggerPercetage(
            findIncome.products,
            findIncome.realized_value
          )
          setDataUpdateProducts(
            createChartPizzaData(
              findIncome.orders,
              '#62b033',
              percentProducts.percentageIncomeData,
              findIncome.realized_value
            )
          )
          setDataUpdateGroupProduct(
            createChartPizzaData(
              findIncome.products,
              '#62b033',
              percentGroupProducts.percentageIncomeData,
              findIncome.realized_value,
              'groupProduct'
            )
          )
        }
      } else {
        setDatesRange({
          maxDate,
          minDate
        })
        setDataUpdateGroupProduct([])
        setDataUpdateProducts([])
        setDataUpdateMap([])
        setGroupProduct([])
        setDataUpdateMixed(
          transactionFinanceIncome.data.graphicData.map((month: any) => {
            return {
              date: month.date,
              goal: month.expected_value,
              goalAccomplished: month.realized_value,
              discount: month.realized_value - month.expected_value,
              lastYearTarget:
                month.expected_value === 0
                  ? 0
                  : (month.realized_value * 100) / month.expected_value
            }
          })
        )
      }
    } else {
      const year = new Date().getFullYear()
      setDatesRange({
        minDate: `${year}-01`,
        maxDate: `${year}-12`
      })

      setIncomeData(
        createBoardIncome(
          financeIncomeData.financeIncome[selectPeriod],
          selectPeriod,
          true
        )
      )
      if (selectIncome) {
        const findIncome = financeIncomeData.financeIncome[selectPeriod].find(
          (income: any) => income.category_id === selectIncome
        )
        if (findIncome) {
          setGroupProduct(
            createBoardGroupProductsIncome(findIncome.products, selectPeriod)
          )
          const states = findIncome.orders
            .map((order: any) => order.delivery_destination_state)
            .join(',')
            .split(',')
          setDataUpdateMixed(
            findIncome.graphicMonths.map((month: any) => {
              return {
                date: month.date,
                goal: month.expected_value,
                goalAccomplished: month.realized_value,
                discount: month.realized_value - month.expected_value,
                lastYearTarget:
                  month.expected_value === 0
                    ? 0
                    : (month.realized_value * 100) / month.expected_value
              }
            })
          )
          setDataUpdateMap(
            findIncome.delivery_destination
              .split(',')
              .map((state: any) => state && { MAIL_ST_PROV_C: state })
          )
          const percentProducts = findBiggerPercetage(
            findIncome.orders,
            findIncome.realized_value
          )
          const percentGroupProducts = findBiggerPercetage(
            findIncome.products,
            findIncome.realized_value
          )
          setDataUpdateProducts(
            createChartPizzaData(
              findIncome.orders,
              '#62b033',
              percentProducts.percentageIncomeData,
              findIncome.realized_value
            )
          )
          setDataUpdateGroupProduct(
            createChartPizzaData(
              findIncome.products,
              '#62b033',
              percentGroupProducts.percentageIncomeData,
              findIncome.realized_value,
              'groupProduct'
            )
          )
        }
      } else {
        setDataUpdateGroupProduct([])
        setDataUpdateProducts([])
        setDataUpdateMap([])
        setGroupProduct([])
        setDataUpdateMixed(
          financeIncomeData.graphicData.map((month: any) => {
            return {
              date: month.date,
              goal: month.expected_value,
              goalAccomplished: month.realized_value,
              discount: month.realized_value - month.expected_value,
              lastYearTarget:
                month.expected_value === 0
                  ? 0
                  : (month.realized_value * 100) / month.expected_value
            }
          })
        )
      }
    }
    disableLoading()
  }
  const handleSetDate = useCallback(
    async (date: string, income_id?: number) => {
      let previewDivide: any
      const selectDate = date || selectedDate
      const selectIncome = income_id || selectedIncomeId
      setSelectedDate(date || selectedDate)
      setSelectedIncomeId(income_id || selectedIncomeId)
      activeLoading()
      let selectPeriod: any
      if (
        selectDate === 'Mes' ||
        selectDate === 'Mês' ||
        selectDate.includes('period')
      ) {
        selectPeriod = 'month'
      }
      if (selectDate === 'Ano') {
        selectPeriod = 'year'
      }
      if (selectDate === 'Hoje') {
        selectPeriod = 'day'
      }
      if (selectDate === 'Semana') {
        selectPeriod = 'week'
      }
      if (selectDate.includes('period')) {
        const { minDate, maxDate } = JSON.parse(date || selectDate)
        let transactionFinanceIncome: any = {}
        if (selectedDate && selectDate.includes(selectedDate)) {
          transactionFinanceIncome = {
            ...financeIncomePeriodData
          }
        } else {
          const { data } = await api.get(
            `/financial/income/listPerPeriod/${date || selectDate}`
          )
          transactionFinanceIncome.data = data
        }
        setFinanceIncomePeriodData(transactionFinanceIncome)
        setIncomeData(
          createBoardIncome(
            transactionFinanceIncome.data.financeIncome[selectPeriod]
          )
        )
        setDatesRange({
          maxDate,
          minDate
        })
        if (selectIncome) {
          const findIncome = transactionFinanceIncome.data.financeIncome[
            selectPeriod
          ].find((income: any) => income.category_id === selectIncome)
          if (findIncome) {
            setGroupProduct(
              createBoardGroupProductsIncome(findIncome.products, selectPeriod)
            )
            const states = findIncome.orders
              .map((order: any) => order.delivery_destination_state)
              .join(',')
              .split(',')
            setDataUpdateMixed(
              findIncome.graphicMonths.map((month: any) => {
                return {
                  date: month.date,
                  goal: month.expected_value,
                  goalAccomplished: month.realized_value,
                  discount: month.realized_value - month.expected_value,
                  lastYearTarget:
                    month.expected_value === 0
                      ? 0
                      : (month.realized_value * 100) / month.expected_value
                }
              })
            )
            setDataUpdateMap(
              findIncome.delivery_destination
                .split(',')
                .map((state: any) => state && { MAIL_ST_PROV_C: state })
            )
            const percentProducts = findBiggerPercetage(
              findIncome.orders,
              findIncome.realized_value
            )
            const percentGroupProducts = findBiggerPercetage(
              findIncome.products,
              findIncome.realized_value
            )
            setDataUpdateProducts(
              createChartPizzaData(
                findIncome.orders,
                '#62b033',
                percentProducts.percentageIncomeData,
                findIncome.realized_value
              )
            )
            setDataUpdateGroupProduct(
              createChartPizzaData(
                findIncome.products,
                '#62b033',
                percentGroupProducts.percentageIncomeData,
                findIncome.realized_value,
                'groupProduct'
              )
            )
          }
        } else {
          setDataUpdateMixed(
            transactionFinanceIncome.data.graphicData.map((month: any) => {
              return {
                date: month.date,
                goal: month.expected_value,
                goalAccomplished: month.realized_value,
                discount: month.realized_value - month.expected_value,
                lastYearTarget:
                  month.expected_value === 0
                    ? 0
                    : (month.realized_value * 100) / month.expected_value
              }
            })
          )
        }
      } else {
        const year = new Date().getFullYear()
        setDatesRange({
          minDate: `${year}-01`,
          maxDate: `${year}-12`
        })

        setIncomeData(
          createBoardIncome(
            financeIncomeData.financeIncome[selectPeriod],
            selectPeriod,
            true
          )
        )
        if (selectIncome) {
          const findIncome = financeIncomeData.financeIncome[selectPeriod].find(
            (income: any) => income.category_id === selectIncome
          )
          if (findIncome) {
            setGroupProduct(
              createBoardGroupProductsIncome(findIncome.products, selectPeriod)
            )
            const states = findIncome.delivery_destination
              .split(',')
              .map((order: any) => order.delivery_destination_state)

            setDataUpdateMixed(
              findIncome.graphicMonths.map((month: any) => {
                return {
                  date: month.date,
                  goal: month.expected_value,
                  goalAccomplished: month.realized_value,
                  discount: month.realized_value - month.expected_value,
                  lastYearTarget:
                    month.expected_value === 0
                      ? 0
                      : (month.realized_value * 100) / month.expected_value
                }
              })
            )
            setDataUpdateMap(
              findIncome.delivery_destination
                .split(',')
                .map((state: any) => state && { MAIL_ST_PROV_C: state })
            )
            const percentProducts = findBiggerPercetage(
              findIncome.orders,
              findIncome.realized_value
            )
            const percentGroupProducts = findBiggerPercetage(
              findIncome.products,
              findIncome.realized_value
            )
            setDataUpdateProducts(
              createChartPizzaData(
                findIncome.orders,
                '#62b033',
                percentProducts.percentageIncomeData,
                findIncome.realized_value
              )
            )
            setDataUpdateGroupProduct(
              createChartPizzaData(
                findIncome.products,
                '#62b033',
                percentGroupProducts.percentageIncomeData,
                findIncome.realized_value,
                'groupProduct'
              )
            )
          }
        }
      }
      disableLoading()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedDate,
      selectedIncomeId,
      activeLoading,
      disableLoading,
      createBoardIncome,
      financeIncomePeriodData,
      createBoardGroupProductsIncome,
      findBiggerPercetage,
      financeIncomeData.financeIncome
    ]
  )

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Container
      pageTitle={'Controle de Receita'}
      portletTitle={''}
      breadcrumb={[
        {
          name: 'Início',
          to: '/'
        },
        {
          name: 'Controle de Receita'
        }
      ]}
      tools={[]}
    >
      <ControlPanel>
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <HeaderPortlet
            handleSetDate={handleSetDate}
            getUserData={() => {}}
            activeMembersData={[]}
            setInitialData={setInitialData}
            actions={['Hoje', 'Semana', 'Mês', 'Ano', 'Período']}
            mainTitle=""
          />
        </div>
        <div className="row">
          <TablePortlet
            hasTotal={true}
            className="col-md-6"
            maintitle="Quadro de Receitas"
            handleSetDate={handleSetDate}
            setInitialData={setInitialData}
            realData={incomeData}
            hasDropdown={false}
            isHighlight={true}
            isHighlightOver={true}
            lastChildStyle="boldGrey"
            initialData={[]}
            actions={[]}
            stylesChild={{
              column4: {
                classname: ''
              },
              th: {
                classname: 'hasBottomBorder'
              },
              highlightColor: '#c42f2f',
              highlightOverColor: '#62b033'
            }}
            tableHead={[
              'Receitas',
              'Previsto',
              'Realizado',
              'Balanço',
              'Percentual'
            ]}
          />

          <div className="col-md-6">
            <AmChartMixedColumn
              settings={{
                valueAxis1Title: 'Receita',
                valueAxis2Title: '',
                series2Title: 'Receita Prevista',
                series1Title: 'Receita Realizada',
                series3Title: 'Balanço',
                series4Title: 'Percentual',
                series4TooltipText: '{name}\n[bold font-size: 20]{valueY}%[/]'
              }}
              data={dataUpdateMixed}
              fillColor="#62b033"
              initialData={[]}
              minDate={datesRange.minDate}
              maxDate={datesRange.maxDate}
            />
          </div>
        </div>

        <div className="row">
          <TablePortlet
            className="col-md-6"
            maintitle="Quadro do Grupo do Produto e Produto"
            handleSetDate={() => {}}
            setInitialData={() => {}}
            realData={groupProduct}
            lastChildStyle="boldGrey"
            initialData={[]}
            actions={[]}
            stylesChild={{
              column4: {
                classname: ' dropDown'
              },
              column6: {
                classname: 'boldGrey dropDown'
              },
              th: {
                classname: 'hasBottomBorder',
                0: {
                  width: 550
                }
              },
              highlightColor: '#c42f2f',
              highlightOverColor: '#62b033'
            }}
            tableHead={[
              'Categorias',
              'Previsto',
              'Realizado',
              'Balanço',
              'Percentual',
              'Quantidade'
            ]}
          />
          <div className="col-md-6">
            <AmChartMap
              maintitle="Mapa dos Estados Comercializados"
              data={dataUpdateMap}
              initialData={[]}
              height="500px"
            />
          </div>
        </div>

        <div className="row">
          <AmChartPizza
            disableClick
            data={dataUpdateGroupProduct}
            divId="amChartPizzaGroupProduct"
            maintitle="Grafíco por Grupo do Produto"
            style={{ width: '100%', height: '400px' }}
            height="750"
            resizeChart={true}
            styleSettings={{
              legend: {
                maxWidth: 1050,
                minWidth: 900
              }
            }}
          />
        </div>
        <div className="row">
          <AmChartPizza
            disableClick
            data={dataUpdateProducts}
            divId="amChartPizzaProduct"
            maintitle="Grafíco por Produto"
            style={{ width: '100%', height: '400px' }}
            height="750"
            resizeChart={true}
            styleSettings={{
              legend: {
                maxWidth: 1050,
                minWidth: 900
              }
            }}
          />
        </div>
      </ControlPanel>
    </Container>
  )
}

export default FinanceIncome
