import React, { useEffect, useState } from 'react'
import { TooltipComponent } from '../../../../../../../../../components/TooltipComponent'
import { useTabs } from '../../../../../../../../../hooks/tabs'
import api from '../../../../../../../../../services/api'
import {
  formatProductTypeToLowerCase,
  RE_SALE,
  SALE
} from '../../../../../domain/data/products'
import { FiscalView } from '../../../domain/response/productResponse'
import { useProduct } from '../../../provider/productProvider'
import { Container } from './style'

export const labelFiscal = 'Classificação fiscal do produto'
export const nameFiscal = '@@tabs-view-fiscal'

export const Fiscal = (): JSX.Element => {
  const { getProduct } = useProduct()
  const { fiscal } = getProduct()
  const { activeTab } = useTabs()
  const [natureOperations, setNatureOperations] = useState<any[]>([])
  const [situations, setSituations] = useState<any[]>([])
  const [cfop, setCfop] = useState('')
  const [cfopOK, setCfopOK] = useState(false)
  const [cfopOutOK, setCfopOutOK] = useState(false)
  const [cfopOut, setCfopOut] = useState('')

  let fiscalList: FiscalView = {} as FiscalView

  const getTaxes = async () => {
    const natureOperations = await api.get('/taxes/taxNatureOperation')
    setNatureOperations(natureOperations.data)
    const situations = await api.get('/taxes/taxSituation')
    setSituations(situations.data?.situations)
  }
  const getCfop = async (code: string) => {
    const { data } = await api.get('/taxes/taxCfop/byCode', {
      params: {
        code
      }
    })
    return data
  }

  useEffect(() => {
    getTaxes()
  }, [])

  useEffect(() => {
    if (
      getProduct().type?.replace(' ', '-') ===
        formatProductTypeToLowerCase(RE_SALE) ||
      getProduct().type?.replace(' ', '-') ===
        formatProductTypeToLowerCase(SALE)
    ) {
      activeTab(nameFiscal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProduct()])

  if (fiscal) {
    fiscalList = JSON.parse(fiscal.toLowerCase())
    if (/^-?\d+$/.test(fiscalList.cfop?.trim()) && !cfopOK) {
      setCfopOK(true)
      ;(async () => {
        const data = await getCfop(fiscalList.cfop?.trim())
        setCfop(`${data?.code} - ${data?.descriptions}`)
      })()
    }
    if (/^-?\d+$/.test(fiscalList.cfop_out_of_state?.trim()) && !cfopOutOK) {
      setCfopOutOK(true)
      ;(async () => {
        const data = await getCfop(fiscalList.cfop_out_of_state?.trim())
        setCfopOut(`${data?.code} - ${data?.descriptions}`)
      })()
    }
  }

  return (
    <Container>
      <p style={{ marginBottom: '20px', marginTop: '25px' }}>
        CLASSIFICAÇÃO DE VENDA DO PRODUTO
      </p>
      <div className="row">
        <div className="form-content col-md-3">
          <label htmlFor="Peso">
            <TooltipComponent
              label="NCM"
              message="Ela também pode ser chamada de Nomenclatura Comum do Mercosul (NCM). Trata-se de uma codificação de oito dígitos definida pelos governos dos países do Mercosul, para identificar a natureza das mercadorias. Posterior a isso o governo deve analisar a NCM para fins de se verificar quais tributos e regimes tributários podem advir daquela classificação fiscal."
            />
          </label>
          <p>{fiscalList.ncm}</p>
        </div>
        <div className="form-content col-md-4">
          <label htmlFor="tipo do produto">
            <TooltipComponent
              label="CFOP - Dentro do estado"
              message="É a abreviação de Código Fiscal de Operações e Prestações. Esse código identifica uma determinada operação por categorias no momento da emissão da nota fiscal. Dependendo do Código CFOP, será fixada a tributação sobre a operação e haverá movimentações financeiras e de estoque de interesse do Fisco."
            />
          </label>
          <p>{cfop || fiscalList.cfop}</p>
        </div>
        <div className="form-content col-md-3">
          <label htmlFor="tipo do produto">
            <TooltipComponent
              label="CFOP - Fora do estado"
              message="É a abreviação de Código Fiscal de Operações e Prestações. Esse código identifica uma determinada operação por categorias no momento da emissão da nota fiscal. É um código numérico que identifica a natureza de circulação de mercadorias e serviços de transporte intermunicipais, interestaduais e também. Dependendo do Código CFOP, será fixada a tributação sobre a operação e haverá movimentações financeiras e de estoque de interesse do Fisco."
            />
          </label>
          <p>{cfopOut || fiscalList.cfop_out_of_state}</p>
        </div>
      </div>
      <div className="row" style={{ marginTop: '40px' }}>
        <div className="title col-md-4">
          <TooltipComponent
            label="ICMS"
            message="Código de Situação Tributária, ou apenas CST, é um classificador que determina a incidência do ICMS sobre um produto ou serviço. Ele serve para orientar os contribuintes no processo de comercialização de mercadorias e auxiliar as entidades federativas no processo de fiscalização tributária."
          />
          <div className="row">
            <div className="col-md-4">
              <div className="title">
                <label>Situação tributaria</label>
                <div>
                  {
                    situations.filter(
                      s => s.id === Number(fiscalList?.icms_tax_situation)
                    )[0]?.descriptions
                  }
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="title">
                <label>Origem</label>
                <div>
                  {
                    natureOperations.filter(
                      np => np.id === Number(fiscalList?.icms_tax_origem)
                    )[0]?.name
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="title col-md-3">
          <TooltipComponent
            label="IPI"
            message="Código da situação tributária referente ao impostos industrializados (CST-IPI)"
          />
          <div className="title">
            <label>Situação tributaria</label>
            <div>
              {
                situations.filter(
                  s => s.id === Number(fiscalList?.ipi_tax_situation)
                )[0]?.descriptions
              }
            </div>
          </div>
        </div>
        <div className="title col-md-3">
          <TooltipComponent
            label="PIS"
            message="PIS, é designado à promoção da integração social dos trabalhadores: seus recursos são para pagamento do seguro-desemprego, abono salarial e participação na receita dos órgãos e entidades para os trabalhadores públicos e de empresas privadas.  Deve ser recolhidos sempre que uma organização obtém receitas durante o mês."
          />
          <div className="title">
            <label>Situação tributaria</label>
            <div>
              {
                situations.filter(
                  s => s.id === Number(fiscalList?.pis_tax_situation)
                )[0]?.descriptions
              }
            </div>
          </div>
        </div>
        <div className="title col-md-2">
          <TooltipComponent
            label="COFINS"
            message="O Cofins é utilizado para o recolhimento de fundos principalmente para a área da saúde pública e seguridade social do país, incluindo dispositivos como Previdência Social e  Assistência Social."
          />
          <div className="title">
            <label>Situação tributaria</label>
            <div>
              {
                situations.filter(
                  s => s.id === Number(fiscalList?.cofins_tax_situation)
                )[0]?.descriptions
              }
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
