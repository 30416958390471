import React, { ChangeEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'

import Form, { Input, Select } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { CheckboxContainer } from './styles'
import {
  genericMaskWithTwoZero,
  phoneMask,
  zipCodeMask
} from '../../../../../utlis/mask'

type ConfigurationData = {
  name: string
  certificate_url?: string
  certificate_pass?: string
  free_ins_est: number
  phone: string
  cellphone: string
  zip_code: string
  commercial_commission_percentage: string
}

type ConfigurationFormData = {
  name: string
  certificate_url?: string
  certificate_pass?: string
  phone: string
  cellphone: string
  zip_code: string
  commercial_commission_percentage: string
}

type TypesFormProps = {
  initialValues?: ConfigurationData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormConfigurations = ({
  typeForm,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const { activeLoading, disableLoading } = useLoading()

  const [freeInsState, setFreeInsState] = useState(0)
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [phone, setPhone] = useState('')
  const [cellphone, setCellphone] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [file, setFile] = useState<File>()

  function onChangeFileHandler(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return
    const file = event.target.files[0]

    setFile(file)
  }

  useEffect(() => {
    if (initialValues) {
      setPassword(initialValues.certificate_pass)
      setPhone(phoneMask(initialValues.phone))
      setCellphone(phoneMask(initialValues.cellphone))
      setZipCode(zipCodeMask(initialValues.zip_code))
      setFreeInsState(initialValues.free_ins_est)
    }
  }, [initialValues])

  const onSubmit = async (data: ConfigurationFormData) => {
    data.phone = undefined
    data.cellphone = undefined
    data.zip_code = undefined
    const commission = data.commercial_commission_percentage
    data.commercial_commission_percentage = undefined
    const formData = new FormData()
    formData.append('certificate_path', file)
    formData.append('free_ins_est', freeInsState ? '1' : '0')
    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value)
      }
    })
    formData.append('phone', phone.replace(' ', ''))
    formData.append('cellphone', cellphone.replace(' ', ''))
    formData.append('zip_code', zipCode)
    formData.append(
      'commercial_commission_percentage',
      genericMaskWithTwoZero(commission) || '0,00'
    )
    try {
      if (typeForm === 'create') {
        try {
          activeLoading()
          await api.post(apiCreate(), formData)
          disableLoading()
          history.push(nameActions.read.to)
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          disableLoading()
          updateDataTable()
        }
      } else {
        const id = initialValues?.idUpdate
        formData.append('id', id.toString())
        try {
          activeLoading()
          await api.put(apiUpdate(String(id)), formData)
          updateDataTable()
          disableLoading()
          history.push(nameActions.read.to)
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
      }
    }
  }

  function verifyPassword(): boolean {
    if (!password || !passwordConfirm || password !== passwordConfirm) {
      return false
    }
    return true
  }

  return (
    <Form onSubmit={onSubmit} defaultValues={initialValues}>
      <h4>Dados Pessoais</h4>
      <div className="row">
        <div className="col-md-3">
          <Input
            label="Nome Fantasia"
            name="name"
            className="form-control"
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Razão Social"
            name="company_name"
            className="form-control"
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="CNPJ"
            name="cnpj"
            className="form-control"
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-3">
          <div style={{ width: '90%', float: 'left' }}>
            <Input
              label="Ins. Estadual"
              name="state_registration"
              className="form-control"
              rules={{ required: true }}
            />
          </div>
          <CheckboxContainer>
            <label htmlFor="free_ins_state">ISENTA</label>
            <input
              type="checkbox"
              name="free_ins_state"
              id="free_ins_state"
              checked={!!freeInsState}
              onClick={() => setFreeInsState(prev => (prev ? 0 : 1))}
            />
          </CheckboxContainer>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <Input
            label="Ins. Municipal"
            name="city_registration"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <Input
            label="CNAE Principal"
            name="cnae_code"
            className="form-control"
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-3">
          <Select
            label="Regime Tributário"
            name="tax_regime"
            className="form-control"
            rules={{ required: true }}
            options={[
              { name: 'SIMPLES NACIONAL', value: '1' },
              { name: 'MEI', value: '2' }
            ]}
            defaultValue={''}
            blank
          />
        </div>
        <div className="col-md-3">
          <Select
            label="Regime Especial de Tributação"
            name="special_tax_regime"
            className="form-control"
            options={[{ name: '1', value: '1' }]}
            defaultValue={''}
            blank
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <Input
            label="Valor Mínimo Frete"
            name="freight"
            className="form-control"
            rules={{ required: true }}
            price
          />
        </div>
      </div>
      <h4>Contato</h4>
      <div className="row">
        <div className="col-md-3">
          <Input
            label="Email"
            name="email"
            className="form-control"
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Telefone"
            name="phone"
            className="form-control"
            rules={{ required: true }}
            maxLength={15}
            value={phone}
            onChange={e => setPhone(phoneMask(e.target.value))}
            onKeyPress={event => {
              const regex = /^[0-9.]+$/
              if (!regex.test(event.key)) event.preventDefault()
            }}
            controlled
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Celular"
            name="cellphone"
            className="form-control"
            maxLength={15}
            value={cellphone}
            onChange={e => setCellphone(phoneMask(e.target.value))}
            onKeyPress={event => {
              const regex = /^[0-9.]+$/
              if (!regex.test(event.key)) event.preventDefault()
            }}
            controlled
          />
        </div>
      </div>
      <h4>Endereço</h4>
      <div className="row">
        <div className="col-md-3">
          <Input
            label="CEP"
            name="zip_code"
            className="form-control"
            maxLength={9}
            value={zipCode}
            onChange={e => setZipCode(zipCodeMask(e.target.value))}
            onKeyPress={event => {
              const regex = /^[0-9.]+$/
              if (!regex.test(event.key)) event.preventDefault()
            }}
            rules={{ required: true }}
            controlled
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Logradouro"
            name="street"
            className="form-control"
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Número"
            name="number"
            className="form-control"
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Complemento"
            name="complement"
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <Input
            label="Bairro"
            name="district"
            className="form-control"
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Cidade"
            name="city_name"
            className="form-control"
            rules={{ required: true }}
          />
        </div>
      </div>
      <h4>Certificado Digital</h4>
      <div className="row">
        <div className="col-md-6">
          <input
            type="file"
            name="certificate_path"
            id="certificate_path"
            className="form-control"
            onChange={onChangeFileHandler}
            style={{ marginBottom: '1rem' }}
          />
        </div>
        <div style={{ height: '34px', display: 'flex', alignItems: 'center' }}>
          {initialValues?.certificate_url && (
            <a href={initialValues.certificate_url}>Arquivo guardado</a>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <Input
            label="Senha do certificado"
            name="certificate_pass"
            className="form-control"
            type="password"
            autoComplete="off"
            onChange={({ target }) => setPassword(target.value)}
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Confirme a senha"
            name="certificate_pass_check"
            className="form-control"
            type="password"
            autoComplete="off"
            onChange={({ target }) => setPasswordConfirm(target.value)}
            rules={{ required: true }}
            controlled
          />
        </div>
        <div className="col-md-3" style={{ marginTop: '30px', padding: '0' }}>
          {passwordConfirm.length > 0 && (
            <>
              {verifyPassword() ? (
                <span className="fa fa-check" />
              ) : (
                <p>Senha incorreta</p>
              )}
            </>
          )}
        </div>
      </div>
      {initialValues?.name === 'COMERCIO' && (
        <>
          <h4>Comercial</h4>
          <div className="row">
            <div className="col-md-3">
              <Input
                label="Percentual de comissão"
                name="commercial_commission_percentage"
                className="form-control"
                price
              />
            </div>
          </div>
        </>
      )}
      <div className="form-actions right">
        <Button type="submit" className="btn dark btn-sm sbold uppercase">
          Salvar
        </Button>
      </div>
    </Form>
  )
}
