import React, { ChangeEvent, useEffect, useState } from 'react'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { Date } from '../../../../../components/Form/date'
import Button from '../../../../../components/Button'
import { FaClipboard, FaUser } from 'react-icons/fa'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { apiCreate, apiUpdate } from '../../domain/api'
import { User } from '../../List'

type TaskData = {
  id: number
  title: string
  author: string
  description: string
  start: string
  end: Date
  status: string
}

type FormTaskProps = {
  initialValues?: TaskData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
  setModal: React.Dispatch<React.SetStateAction<boolean>>
  getTasks: () => void
  users: User[]
}

export const FormTask = ({
  typeForm,
  initialValues,
  setModal,
  getTasks,
  users
}: FormTaskProps): JSX.Element => {
  const { addToast } = useToast()
  const [defaultValues, setDefaultValues] = useState<TaskData>()
  const [end, setEnd] = useState<Date>()
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const [selectedUser, setSelectedUser] = useState<User>()
  const { activeLoading, disableLoading } = useLoading()

  const [files, setFiles] = useState<File[]>([])

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues])

  const onSubmit = async (data: TaskData) => {
    if (!selectedUsers.length) {
      addToast({
        type: 'error',
        title: 'É necessário adicionar pelo menos um usuário à tarefa.'
      })
      return
    }
    const formData = new FormData()
    formData.append(
      'users',
      JSON.stringify(selectedUsers.map(selectedUser => selectedUser.id))
    )
    formData.append('end', data.end.toISOString())
    files.forEach(file => {
      formData.append('files[]', file)
    })
    Object.entries(data).forEach(([key, value]) => {
      if (value && key !== 'end') {
        formData.append(key, String(value))
      }
    })

    if (typeForm === 'create') {
      try {
        activeLoading()
        await api.post(apiCreate(), formData)
        disableLoading()
        getTasks()
        setModal(false)
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao adicionar o registro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        disableLoading()
      }
    } else {
      const id = initialValues?.idUpdate

      try {
        activeLoading()
        await api.put(apiUpdate(String(id)), formData)
        disableLoading()
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
        setModal(false)
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    }
    disableLoading()
  }

  function onChangeFileHandler(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return
    const file = event.target.files[0]

    setFiles(prev => [...prev, file])
  }

  return (
    <Form onSubmit={onSubmit} defaultValues={defaultValues}>
      <Input label="Título" name="title" className="form-control" />
      <Textarea
        label="Descrição"
        name="description"
        className="form-control"
        rows={7}
        rules={{ required: true }}
      />
      <div className="row">
        <div className="col-md-4">
          <Date
            label="Prazo"
            name="end"
            className="form-control"
            onChange={date => setEnd(date)}
            selected={end}
            controlled
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-4">
          <Select
            label="Status"
            name="status"
            className="form-control"
            options={[
              { name: 'Nova', value: 'NOVA' },
              { name: 'Finalizada', value: 'FINALIZADA' }
            ]}
            blank
            defaultValue={''}
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="row" style={{ alignItems: 'flex-end', display: 'flex' }}>
        <div className="col-md-3">
          <Select
            label="Usuários"
            name="uuser"
            className="form-control"
            options={users.map(u => ({
              name: u.name,
              value: u.id
            }))}
            blank
            defaultValue={''}
            onChange={({ target }) =>
              setSelectedUser(users.find(u => u.id === Number(target.value)))
            }
            rules={{ required: true }}
          />
        </div>
        <div
          className="col-md-1"
          style={{ padding: '0px', marginBottom: '1em' }}
        >
          <Button
            type="button"
            className="btn dark btn-sm sbold uppercase"
            style={{ height: '34px' }}
            onClick={() => {
              if (selectedUser) {
                setSelectedUsers(prev => [...prev, selectedUser])
              }
            }}
          >
            +
          </Button>
        </div>
        <div className="col-md-7" style={{ display: 'flex', flexWrap: 'wrap' }}>
          {selectedUsers.map(selectedUser => (
            <div
              key={selectedUser.id}
              style={{
                border: '1px solid #ccc',
                background: '#f2f2f2',
                padding: '5px 8px',
                marginBottom: '1em'
              }}
            >
              <FaUser style={{ marginRight: '4px' }} />
              {selectedUser.name}
            </div>
          ))}
        </div>
      </div>
      <div className="row">
        <div
          className="col-md-12"
          style={{ alignItems: 'flex-end', display: 'flex' }}
        >
          <label htmlFor="atch" className="btn dark btn-sm sbold uppercase">
            Inserir Anexo
          </label>
          <input
            type="file"
            name="atch"
            id="atch"
            onChange={onChangeFileHandler}
            style={{ display: 'none' }}
          />
          {files?.map(file => (
            <a style={{ marginLeft: '10px' }} key={file.name}>
              <FaClipboard style={{ marginRight: '4px' }} />
              {file.name}
            </a>
          ))}
        </div>
      </div>
      <div className="form-actions right">
        <Button
          type="submit"
          className="btn dark btn-sm sbold uppercase"
          style={{ marginTop: '15px' }}
        >
          + Criar Tarefa
        </Button>
      </div>
    </Form>
  )
}
