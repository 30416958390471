import React, { createContext, useContext, useState, useCallback } from 'react'

import { useLoading } from '../../../../../hooks/loading'

import api from '../../../../../services/api'
import { listById } from '../../domain/data/api'
import { ProductResponse } from '../../domain/models/productResponse'
import {
  TypeComposition,
  TypeProductStock
} from '../../domain/models/typeProduct'

import {
  FinancialCategory,
  ProductCategory,
  ProductType,
  UnitMensured,
  Attributes
} from '../../services/api'

type ProductContextType = {
  getProduct: (id: string) => any
  typesProduct: ProductType[]
  productType: string
  setProductType: React.Dispatch<React.SetStateAction<string>>
  stock: any[]
  setStock: React.Dispatch<React.SetStateAction<any[]>>
  composition: TypeComposition[]
  setComposition: React.Dispatch<React.SetStateAction<TypeComposition[]>>
  hasVariation: boolean
  setHasVariation: React.Dispatch<React.SetStateAction<boolean>>
  groupsProduct: ProductCategory[]
  categoriesCost: FinancialCategory[]
  subCategoriesCost: FinancialCategory[]
  loadSubCategoriesCost: (parentId: string) => void
  unitMensured: UnitMensured[]
  attributes: Attributes[]
  setAttributes: React.Dispatch<React.SetStateAction<Attributes[]>>
  saveProduct: (data: any, id?: string) => Promise<string>
  removeComposition: (index: number) => void
}

const ProductContext = createContext<ProductContextType>(
  {} as ProductContextType
)

type ProductProviderParams = {
  children: JSX.Element
}

export const ProductProvider = ({ children }: ProductProviderParams) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [typesProduct, setTypesProduct] = useState<ProductType[]>([])
  const [productType, setProductType] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [groupsProduct, setGroupsProduct] = useState<ProductCategory[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [categoriesCost, setCategoriesCost] = useState<FinancialCategory[]>([])
  const [subCategoriesCost, setSubCategoriesCost] = useState<
    FinancialCategory[]
  >([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [unitMensured, setUnitMensured] = useState<UnitMensured[]>([])
  const [attributes, setAttributes] = useState<Attributes[]>([])
  const [hasVariation, setHasVariation] = useState<boolean>(false)
  const [stock, setStock] = useState<TypeProductStock[]>([
    {
      atributes: []
    }
  ])
  const [composition, setComposition] = useState<TypeComposition[]>([
    { id: 0, name: '', prices: '0.00' }
  ])

  const { activeLoading, disableLoading } = useLoading()
  // useEffect(() => {
  //   setTimeout(async () => {
  //     const productsType = loadProductTypes()
  //     setTypesProduct(productsType)
  //     const groupsProduct = await loadProductCategories()
  //     setGroupsProduct(groupsProduct)
  //     const categoriesCost = await loadFinancialCategories()
  //     setCategoriesCost(categoriesCost)
  //     const mensureds = await loadUnitMensured()
  //     setUnitMensured(mensureds)
  //     const attributes = await loadAtributes()
  //     setAttributes(attributes)
  //   }, 1000)
  // }, [activeLoading, disableLoading])

  // useEffect(() => {
  //   if (!productType) return
  //   if (['materia-prima', 'locacao', 'consumo'].includes(productType)) {
  //     disableTab('priceComposition')
  //     disableTab('fiscal')
  //     disableTab('hasComposition')
  //   } else if (productType === 'revenda') {
  //     activeTab('priceComposition')
  //     activeTab('fiscal')
  //     disableTab('hasComposition')
  //   } else if (productType === 'semi-acabado') {
  //     activeTab('hasComposition')
  //     disableTab('fiscal')
  //     disableTab('priceComposition')
  //   } else {
  //     activeTab('hasComposition')
  //     activeTab('fiscal')
  //     activeTab('priceComposition')
  //   }
  // }, [productType])

  const loadSubCategoriesCost = useCallback(
    (parentId: string) => {
      if (parentId) {
        const subcategoryCost = categoriesCost?.filter(
          ({ parent_id }) => parent_id?.toString() === parentId
        )
        setSubCategoriesCost(subcategoryCost)
      }
    },
    [categoriesCost]
  )

  const getProduct = useCallback(
    async (id: string) => {
      activeLoading()
      if (!attributes.length || !categoriesCost.length) return
      const { data } = await api.get<ProductResponse>(listById(id))

      setProductType(data.type)
      loadSubCategoriesCost(String(data.financial_category.id))
      data.composition && setComposition(JSON.parse(data.composition))

      const stocksCopy = data.stocks.map(stock => {
        const currentAttributes = JSON.parse(String(stock.atributes))
        const newAttributes: any[] = []

        attributes.forEach((a, index) => {
          currentAttributes?.forEach((att: any) => {
            if (att) {
              if (Number(a.id) === Number(att.parent_id) && Number(att.id)) {
                newAttributes[index] = {
                  id: Number(att.id),
                  parent_id: Number(att.parent_id),
                  value: Number(att.id)
                }
                setAttributes(prev => {
                  prev[index].isChecked = true
                  return prev
                })
              } else {
                newAttributes[index] = newAttributes[index]
                  ? newAttributes[index]
                  : {}
              }
            }
          })
        })
        return {
          ...stock,
          details: JSON.parse(String(stock.details)),
          atributes: [...newAttributes]
        }
      })
      data.stocks && setStock(stocksCopy)

      const value = {
        details_overview: {
          type: data.type,
          product_category_id: data.product_category_id,
          name: data.name,
          category_cost_id: data.financial_category.id,
          subcategory_cost_id: data.subfinancial_category.id
        },
        stock: stocksCopy,
        composition: data.composition
          ? JSON.parse(String(data.composition))
          : [],
        price_composition: data.price_composition
          ? JSON.parse(String(data.price_composition))
          : {},
        fiscal: data.fiscal ? JSON.parse(String(data.fiscal)) : {}
      }
      disableLoading()
      return value
    },
    [
      activeLoading,
      attributes,
      categoriesCost.length,
      disableLoading,
      loadSubCategoriesCost
    ]
  )

  const saveProduct = useCallback(async (product: any, id?: string) => {
    try {
      if (id) {
        const { data } = await api.put(`warehouse/products/update/${id}`, {
          params: product
        })
        if (data.error) {
          return false
        }
        return data.id
      }
      const { data } = await api.post('warehouse/products/create', {
        params: product
      })
      if (data.error) {
        return false
      }
      return data.id
    } catch (error) {
      console.log(error)
      return false
    }
  }, [])

  const removeComposition = useCallback(
    (indexItem: number) => {
      const filterCompositions = composition.filter(
        (item, index) => index !== indexItem
      )
      setComposition(filterCompositions)
    },
    [composition]
  )

  return (
    <ProductContext.Provider
      value={{
        getProduct,
        typesProduct,
        productType,
        setProductType,
        stock,
        setStock,
        composition,
        setComposition,
        hasVariation,
        setHasVariation,
        groupsProduct,
        categoriesCost,
        subCategoriesCost,
        loadSubCategoriesCost,
        unitMensured,
        attributes,
        setAttributes,
        saveProduct,
        removeComposition
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

export const useProduct = (): ProductContextType => {
  const context = useContext(ProductContext)
  if (!context) throw new Error('Context Product context not found')
  return context
}
