/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-template-curly-in-string */
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import am4lang_pt_BR from '@amcharts/amcharts4/lang/pt_BR'
import React, { useEffect, useRef } from 'react'

interface AmchartMixed {
  divId?: string
  data?: any[]
  initialData: any[]
  settings: any
  height?: string
  valueAxis1Title?: string
  valueAxis2Title?: string
  maintitle?: string
  minDate?: string
  maxDate?: string
  fillColor?: string
}

const AmChartMixedColumn: React.FC<AmchartMixed> = ({
  divId,
  data,
  initialData,
  height,
  settings,
  maintitle,
  minDate,
  maxDate,
  fillColor
}) => {
  const dateAxis = useRef(null)
  const chartRef = useRef(null)
  function checkEmptyData() {
    let indicator: any
    function showIndicator() {
      if (indicator) {
        indicator.show()
      } else {
        indicator = chartRef.current.tooltipContainer.createChild(
          am4core.Container
        )
        indicator.background.fill = am4core.color('#fff')
        indicator.background.fillOpacity = 0.8
        indicator.width = am4core.percent(100)
        indicator.height = am4core.percent(100)

        const indicatorLabel = indicator.createChild(am4core.Label)
        indicatorLabel.text = 'Sem dados para análise no momento'
        indicatorLabel.align = 'center'
        indicatorLabel.valign = 'middle'
        indicatorLabel.fontSize = 20
      }
    }

    function hideIndicator() {
      indicator.hide()
    }

    chartRef.current.events.on('datavalidated', function (ev: any) {
      if (ev.target.data.length === 0) {
        showIndicator()
      } else if (indicator) {
        hideIndicator()
      }
    })
  }
  useEffect(() => {
    if (!chartRef.current) {
      am4core.useTheme(am4themes_animated)

      chartRef.current = am4core.create(divId || 'chartdiv', am4charts.XYChart)
      chartRef.current.language.locale = am4lang_pt_BR
      chartRef.current.language.locale._decimalSeparator = ','
      chartRef.current.language.locale._thousandSeparator = '.'
      chartRef.current.numberFormatter.numberFormat = '#,###.##'

      chartRef.current.data = initialData

      dateAxis.current = chartRef.current.xAxes.push(new am4charts.DateAxis())
      const categoryAxis = chartRef.current.yAxes.push(
        new am4charts.CategoryAxis()
      )
      categoryAxis.renderer.minGridDistance = 30
      dateAxis.current.renderer.minLabelPosition = 0
      dateAxis.current.renderer.maxLabelPosition = 1
      dateAxis.current.dateFormats.setKey('month', 'MMM')
      dateAxis.current.periodChangeDateFormats.setKey('month', 'MMM[/]')
      dateAxis.current.gridIntervals.setAll([{ timeUnit: 'month', count: 1 }])
      const valueAxis1 = chartRef.current.yAxes.push(new am4charts.ValueAxis())
      valueAxis1.title.text = settings.valueAxis1Title || 'Vendas'

      const series2 = chartRef.current.series.push(new am4charts.ColumnSeries())
      series2.dataFields.valueY = 'goal'
      series2.dataFields.dateX = 'date'
      series2.yAxis = valueAxis1
      series2.name = settings.series2Title || 'Meta'
      series2.tooltipText = '{name}\n[bold font-size: 20]{valueY}[/]'
      series2.fill =
        settings.series2fillColor || am4core.color('grey').lighten(0.7)
      series2.strokeWidth = 0
      series2.clustered = false
      series2.toBack()

      const series1 = chartRef.current.series.push(new am4charts.ColumnSeries())
      series1.dataFields.valueY = 'goalAccomplished'
      series1.dataFields.dateX = 'date'
      series1.yAxis = valueAxis1
      series1.name = settings.series1Title || 'Meta Realizada'
      series1.tooltipText = '{name}\n[bold font-size: 20]{valueY}[/]'
      series1.fill = am4core.color(
        settings.series1fillColor || fillColor || 'rgb(224,142,32)'
      )
      series1.strokeWidth = 0
      series1.clustered = false
      series1.columns.template.width = am4core.percent(40)
      let series3
      if (settings.series3Title) {
        series3 = chartRef.current.series.push(new am4charts.LineSeries())
        series3.dataFields.valueY = 'discount'
        series3.dataFields.dateX = 'date'
        series3.name = settings.series3Title || 'Desconto'
        series3.strokeWidth = 2
        series3.tensionX = 0.7
        series3.yAxis = valueAxis1
        series3.stroke = am4core.color(settings.series3StrokeColor || '#e9dc01')
        series3.fill = am4core.color(settings.series3fillColor || '#e9dc01')
        series3.tooltipText = '{name}\n[bold font-size: 20]{valueY}[/]'
      }

      if (series3) {
        const bullet3 = series3.bullets.push(new am4charts.CircleBullet())
        bullet3.circle.radius = 3
        bullet3.circle.strokeWidth = 2
        bullet3.circle.fill = am4core.color('#fff')
      }
      let series4
      if (settings.series4Title) {
        series4 = chartRef.current.series.push(new am4charts.LineSeries())
        series4.dataFields.valueY = 'lastYearTarget'
        series4.dataFields.dateX = 'date'
        series4.name = settings.series4Title || 'Meta Ano Passado'
        series4.strokeWidth = 2
        series4.tensionX = 0.7
        series4.yAxis = valueAxis1
        series4.tooltipText =
          settings.series4TooltipText ||
          '{name}\n[bold font-size: 20]{valueY}[/]'
        series4.stroke = am4core.color(settings.series4StrokeColor || '#000')
        series4.fill = am4core.color(settings.series4fillColor || '#000')
        series4.strokeDasharray = '3,3'
      }

      if (series4) {
        const bullet4 = series4.bullets.push(new am4charts.CircleBullet())
        bullet4.circle.radius = 3
        bullet4.circle.strokeWidth = 2
        bullet4.circle.fill = am4core.color('#fff')
      }

      chartRef.current.cursor = new am4charts.XYCursor()

      chartRef.current.legend = new am4charts.Legend()
      chartRef.current.legend.position = 'top'
      chartRef.current.legend.contentAlign = 'left'
      chartRef.current.legend.itemContainers.template.paddingLeft = 0
      chartRef.current.legend.itemContainers.template.paddingRight = 0
      categoryAxis.renderer.labels.template.paddingLeft = 0
      categoryAxis.renderer.labels.template.paddingRight = 0
      categoryAxis.renderer.labels.template.marginLeft = 0
      categoryAxis.renderer.labels.template.marginRight = 0
      chartRef.current.legend.events.on('layoutvalidated', (event: any) => {
        if (event.target.children.values[0]) {
          event.target.children.values[0].paddingLeft = 98
        }
      })

      chartRef.current.scrollbarX = new am4charts.XYChartScrollbar()
      // @ts-ignore
      chartRef.current.scrollbarX.series.push(series1)
      // @ts-ignore
      if (series3) {
        chartRef.current.scrollbarX.series.push(series3)
      }
      chartRef.current.scrollbarX.parent = chartRef.current.bottomAxesContainer
      chartRef.current.zoomOutButton.background.fill = am4core.color('#000')
      chartRef.current.zoomOutButton.background.states.getKey(
        'hover'
      ).properties.fill = am4core.color('#333333')
      chartRef.current.responsive.enable = true
    }

    return () => {
      chartRef.current && chartRef.current.dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = data
      chartRef.current.invalidateRawData()
      checkEmptyData()
      const year = new Date().getFullYear()
      chartRef.current.events.on('datavalidated', function () {
        let initialDate: any = new Date(`january 01, ${year}`)
        let endedDate: any = new Date(`december 28, ${year}`)
        if (minDate && maxDate) {
          const [yearStart, monthStart] = minDate.split('-')
          const [yearEnd, monthEnd] = maxDate.split('-')
          initialDate = new Date(Number(yearStart), Number(monthStart) - 1)
          endedDate = new Date(Number(yearEnd), Number(monthEnd))
        }
        dateAxis.current.zoomToDates(
          new Date(initialDate),
          new Date(endedDate),
          false
        )
      })
    }
  }, [data, maxDate, minDate])
  return (
    <div style={{ width: '100%', height: '100%' }} className={'portlet light'}>
      {maintitle?.length > 0 && (
        <div className="portlet-title">
          <div className="caption caption-md">
            <i className="icon-bar-chart font-dark hide"></i>
            <span className="maintitle">{maintitle}</span>
          </div>
        </div>
      )}
      <div
        className={divId || 'chartdiv'}
        style={{ width: '100%', minHeight: height || '550px' }}
      ></div>
    </div>
  )
}

export { AmChartMixedColumn }
