import React, { useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, namePageTitle, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import moment from 'moment'
import { FaDownload } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import api from '../../../../services/api'
import { Alert } from '../../../../components/Alert'
import { useUpdateDataTable } from '../../../../hooks/dataTable'

const PaymentRemittanceFileList = (): JSX.Element => {
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
  }>({
    id: 0,
    isActive: false
  })
  const { updateDataTable } = useUpdateDataTable()

  return (
    <Container
      pageTitle={namePageTitle}
      portletTitle={'Listagem'}
      breadcrumb={breadcrumbList}
      tools={[toolsList]}
    >
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'id' }}
        notHasChildren
        headers={headers}
        customHeaders={[
          {
            name: 'Data',
            field: 'created_at',
            sortable: false,
            element: item => (
              <span>
                {moment(item.created_at, 'DD/MM/YYYY HH:mm:ss').format(
                  'DD/MM/YYYY'
                )}
              </span>
            )
          },
          {
            name: 'Empresa',
            field: 'company_id',
            sortable: false,
            element: item => (
              <span>{item.company_id === 1 ? 'COMERCIO' : 'INDUSTRIA'}</span>
            )
          },
          {
            name: 'Conta',
            field: 'bank_account_id',
            sortable: false,
            element: item => (
              <span>{item.bank_account_id === 13 ? 'INTER' : 'SANTANDER'}</span>
            )
          },
          {
            name: 'Transferências',
            field: 'value_transfers',
            sortable: false,
            element: item => (
              <span>{`${item.value_transfers} (${
                item.quantity_tranfers || '0'
              })`}</span>
            )
          },
          {
            name: 'Títulos',

            field: 'value_titles',
            sortable: false,
            element: item => (
              <span>{`${item.value_titles} (${
                item.quantity_titles || '0'
              })`}</span>
            )
          },
          {
            name: 'Convênios/Tributos',
            field: 'value_taxes',
            sortable: false,
            element: item => (
              <span>{`${item.value_taxes} (${
                item.quantity_taxes || '0'
              })`}</span>
            )
          }
        ]}
        actions={[
          {
            name: 'Baixar',
            title: 'Baixar',
            htmlIcon: <FaDownload />,
            onClick: async item => {
              let response
              if (item.bank_account_id !== 13) {
                response = await api.get(
                  `financial/paymentRemittanceFiles/getOldRemittanceFile/${item.id}`
                )
              } else {
                response = await api.post(
                  'financial/paymentRemittanceFiles/generate',
                  {
                    id: item.id
                  }
                )
              }
              const blob = new Blob([response.data], {
                type: 'text/plain'
              })
              console.log(response.headers)

              const name = response.headers['content-disposition']
                .split('filename=')[1]
                .split('.')[0]
              const extension = response.headers['content-disposition']
                .split('.')[1]
                .split(';')[0]

              const filename = `${name.substring(
                1,
                name.length
              )}.${extension.substring(0, extension.length - 1)}`
              const url = window.URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = filename
              a.click()
            }
          },
          {
            name: 'Visualizar',
            title: 'Visualizar',
            spanIcon: 'fa fa-search',
            linkTo: item => `/financial/paymentRemittanceFiles/view/${item.id}`
          },
          {
            name: 'Deletar',
            title: 'Deletar',
            htmlIcon: <MdClose />,
            onClick: async item => {
              setIsActiveAlert({
                id: item.id,
                isActive: true
              })
            }
          }
        ]}
      />
      <Alert
        message={'Tem certeza que deseja excluir esse arquivo?'}
        onClickCancellButton={() =>
          setIsActiveAlert({
            id: 0,
            isActive: false
          })
        }
        onClickConfirmButton={async () => {
          await api.delete(`financial/paymentRemittanceFiles/${alert.id}`)
          updateDataTable()
          setIsActiveAlert({
            id: 0,
            isActive: false
          })
        }}
        isActive={alert.isActive}
      />
    </Container>
  )
}

export default PaymentRemittanceFileList
