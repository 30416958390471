import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  Container,
  DropdownDate,
  DateButton,
  WrapperDates,
  ContainerInput,
  ContainerDateYear
} from './styles'
import {
  FaTimes,
  FaCalendarAlt,
  FaArrowLeft,
  FaArrowRight
} from 'react-icons/fa'

interface DateRangeProps {
  minDate: Date
  maxDate: Date
  minRange: number | undefined
  maxRange: number | undefined
  firstDate: string
  secondDate: string
  firstDateFull: string
  secondDateFull: string
}

interface DatePickerProps extends InputHTMLAttributes<HTMLInputElement> {
  getUserData?: () => void
  setInitialData(date?: string): void
  handleSwitchPeriod(index: number): void
  handleResetData(): void
  handleSetDate(date: string, agent_id?: number): void
  isReset: boolean
  initialAction?: string
}
const DatePicker: React.FC<DatePickerProps> = ({
  className,
  handleSwitchPeriod,
  setInitialData,
  handleResetData,
  isReset,
  handleSetDate,
  initialAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getUserData,
  ...rest
}) => {
  const [months] = useState([
    {
      name: 'January',
      shortName: 'JAN',
      number: 0
    },
    {
      name: 'February',
      shortName: 'FEV',
      number: 1
    },
    {
      name: 'March',
      shortName: 'MAR',
      number: 2
    },
    {
      name: 'April',
      shortName: 'ABR',
      number: 3
    },
    {
      name: 'May',
      shortName: 'MAI',
      number: 4
    },
    {
      name: 'June',
      shortName: 'JUN',
      number: 5
    },
    {
      name: 'July',
      shortName: 'JUL',
      number: 6
    },
    {
      name: 'August',
      shortName: 'AGO',
      number: 7
    },
    {
      name: 'September',
      shortName: 'SET',
      number: 8
    },
    {
      name: 'October',
      shortName: 'OUT',
      number: 9
    },
    {
      name: 'November',
      shortName: 'NOV',
      number: 10
    },
    {
      name: 'December',
      shortName: 'DEZ',
      number: 11
    }
  ])
  const [currentMonth, setCurrentMonth] = useState('Período')
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [toggleModal, setToggleModal] = useState(false)
  const [isSelectRange, setIsSelectRange] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeProps>({
    minDate: new Date(),
    maxDate: new Date(),
    maxRange: undefined,
    minRange: undefined,
    firstDate: '',
    secondDate: '',
    firstDateFull: '',
    secondDateFull: ''
  })

  const verifyRangeDate = (index: number) => {
    const minYear = selectedDateRange.minDate.getFullYear()
    const maxYear = selectedDateRange.maxDate.getFullYear()
    if (
      (selectedDateRange.minRange === index ||
        index === selectedDateRange.maxRange) &&
      isSelectRange &&
      currentYear === minYear
    ) {
      return 'selectedDate activeDate'
    } else if (selectedDateRange.maxRange) {
      if (
        currentYear >= minYear &&
        index >= selectedDateRange.minRange &&
        currentYear < maxYear
      ) {
        return 'activeDate'
      }
      if (
        currentYear <= maxYear &&
        index <= selectedDateRange.maxRange &&
        currentYear > minYear
      ) {
        return 'activeDate'
      }
      if (
        minYear === maxYear &&
        minYear === currentYear &&
        index >= selectedDateRange.minRange &&
        index <= selectedDateRange.maxRange
      ) {
        return 'activeDate'
      }
    }
    return ''
  }

  const handleToggleModal = useCallback(() => {
    handleSwitchPeriod(4)
    setToggleModal(!toggleModal)
  }, [handleSwitchPeriod, toggleModal])
  const handleSetCurrentMonth = useCallback(
    (month: string, monthFullName: string, index: number) => {
      if (isSelectRange) {
        setIsSelectRange(false)
        if (
          selectedDateRange.minDate <=
          new Date(`${monthFullName} 01, ${currentYear}`)
        ) {
          setSelectedDateRange({
            minRange: selectedDateRange.minRange,
            maxRange: index,
            minDate: selectedDateRange.minDate,
            maxDate: new Date(`${monthFullName} 01, ${currentYear}`),
            firstDate: selectedDateRange.firstDate,
            secondDate: month,
            firstDateFull: '',
            secondDateFull: ''
          })
          const dates = {
            period: true,
            minDate: selectedDateRange.minDate.toISOString().slice(0, 7),
            maxDate: new Date(`${monthFullName} 01, ${currentYear}`)
              .toISOString()
              .slice(0, 7)
          }
          if (selectedDateRange.minDate.getFullYear() === currentYear) {
            setCurrentMonth(`${currentMonth} até ${month}/${currentYear}`)
          } else {
            inputRef.current.className = 'InputGrowthMedium'
            const findMonth = months.find(
              month => month.number === selectedDateRange.minDate.getMonth()
            )
            setCurrentMonth(
              `Período de ${
                findMonth?.shortName
              }/${selectedDateRange.minDate.getFullYear()} até ${month}/${currentYear}`
            )
          }
          handleSetDate(JSON.stringify(dates))
        } else {
          setSelectedDateRange({
            minRange: index,
            maxRange: selectedDateRange.minRange,
            minDate: new Date(`${monthFullName} 01, ${currentYear}`),
            maxDate: selectedDateRange.minDate,
            firstDate: selectedDateRange.firstDate,
            secondDate: month,
            firstDateFull: selectedDateRange.firstDateFull,
            secondDateFull: monthFullName
          })
          const dates = {
            period: true,
            minDate: new Date(`${monthFullName} 01, ${currentYear}`)
              .toISOString()
              .slice(0, 7),
            maxDate: selectedDateRange.minDate.toISOString().slice(0, 7)
          }
          if (selectedDateRange.minDate.getFullYear() === currentYear) {
            setCurrentMonth(
              `Período de ${month} até ${selectedDateRange.firstDate}/${currentYear}`
            )
          } else {
            inputRef.current.className = 'InputGrowthMedium'
            const findMonth = months.find(
              month => month.number === selectedDateRange.minDate.getMonth()
            )
            setCurrentMonth(
              `Período de ${month}/${currentYear} até ${
                findMonth?.shortName
              }/${selectedDateRange.minDate.getFullYear()}`
            )
          }

          handleSetDate(JSON.stringify(dates))
        }
        handleToggleModal()
      } else {
        setSelectedDateRange({
          minRange: index,
          maxRange: undefined,
          minDate: new Date(`${monthFullName} 01, ${currentYear}`),
          maxDate: new Date(`${monthFullName} 01, ${currentYear}`),
          firstDate: month,
          secondDate: month,
          firstDateFull: monthFullName,
          secondDateFull: monthFullName
        })
        setIsSelectRange(true)
        setCurrentMonth(`Período de ${month}`)
      }
    },
    [
      currentMonth,
      currentYear,
      handleSetDate,
      handleToggleModal,
      isSelectRange,
      months,
      selectedDateRange.firstDate,
      selectedDateRange.firstDateFull,
      selectedDateRange.minDate,
      selectedDateRange.minRange
    ]
  )
  const handleClearCurrentMonth = useCallback(() => {
    setToggleModal(false)
    handleResetData()
    setCurrentMonth('Período')
    setSelectedDateRange({
      minDate: new Date(),
      maxDate: new Date(),
      maxRange: undefined,
      minRange: undefined,
      firstDate: '',
      secondDate: '',
      firstDateFull: '',
      secondDateFull: ''
    })
    setCurrentYear(new Date().getFullYear())
    setInitialData(initialAction || 'Mes')
  }, [handleResetData, initialAction, setInitialData])
  const handleClearInput = useCallback(() => {
    if (currentMonth === 'Período') {
      setToggleModal(false)
      setCurrentMonth('Período')
      setCurrentYear(new Date().getFullYear())
    }
  }, [currentMonth])

  const handleNextYear = useCallback(() => {
    if (currentYear === new Date().getFullYear()) {
      return
    }
    setCurrentYear(currentYear + 1)
  }, [currentYear])
  const handlePreviousYear = useCallback(() => {
    setCurrentYear(currentYear - 1)
  }, [currentYear])

  useEffect(() => {
    if (isReset) {
      if (currentMonth !== 'Período') {
        setToggleModal(false)
        setCurrentMonth('Período')
        setCurrentYear(new Date().getFullYear())
        setSelectedDateRange({
          minDate: new Date(),
          maxDate: new Date(),
          maxRange: undefined,
          minRange: undefined,
          firstDate: '',
          secondDate: '',
          firstDateFull: '',
          secondDateFull: ''
        })
      }
    }
  }, [currentMonth, isReset])
  return (
    <Container onMouseLeave={handleClearInput}>
      <ContainerInput className={className}>
        <FaCalendarAlt
          onClick={handleToggleModal}
          style={{ marginRight: '5px', marginBottom: '2px' }}
          size={13}
          className="FaCalendar"
        />
        <input
          readOnly
          ref={inputRef}
          className={currentMonth.length > 10 ? 'InputGrowth' : ''}
          type="text"
          value={currentMonth}
          onClick={handleToggleModal}
          style={{
            color: toggleModal && '#fff'
          }}
          {...rest}
        />
        {currentMonth !== 'Período' && (
          <FaTimes onClick={handleClearCurrentMonth} />
        )}
      </ContainerInput>
      {toggleModal && (
        <DropdownDate>
          <ContainerDateYear>
            <FaArrowLeft onClick={handlePreviousYear} color="#a0a0a0" />
            <p>{currentYear}</p>
            <FaArrowRight
              onClick={handleNextYear}
              color={
                currentYear === new Date().getFullYear() ? '#ccc' : '#a0a0a0'
              }
            />
          </ContainerDateYear>
          <WrapperDates>
            {months.map((month, index) => (
              <div key={month.name} className={verifyRangeDate(index)}>
                <DateButton
                  type="button"
                  className={verifyRangeDate(index)}
                  isSelected={
                    selectedDateRange.minRange === index ||
                    index === selectedDateRange.maxRange
                  }
                  onClick={() =>
                    handleSetCurrentMonth(month.shortName, month.name, index)
                  }
                >
                  {month.shortName}
                </DateButton>
              </div>
            ))}
          </WrapperDates>
        </DropdownDate>
      )}
    </Container>
  )
}

export { DatePicker }
