import React from 'react'
import { RegisterOptions, UseFormRegister } from 'react-hook-form'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ptBR from 'date-fns/locale/pt-BR'
import { DatePickerContainer, Error } from './styles'
import { FiAlertCircle } from 'react-icons/fi'

type DateProps = ReactDatePickerProps & {
  register?: UseFormRegister<any>
  name: string
  rules?: RegisterOptions
  hasError?: any
  errors?: any
  label?: string
  controlled?: boolean
  fullControlled?: boolean
  price?: boolean
}

export function Date({
  register,
  name,
  label,
  rules,
  errors,
  hasError,
  ...rest
}: DateProps) {
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error

  return (
    <DatePickerContainer>
      {label && (
        <label htmlFor={name} className="control-label">
          {label}
        </label>
      )}
      <div>
        <DatePicker
          dateFormat="P"
          {...(register && register(name, rules))}
          locale={ptBR}
          {...rest}
        />
        {error?.message && (
          <Error title={error.message}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {hasError?.error && (
          <Error title={hasError?.message}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {error?.type === 'required' && (
          <Error title={`O campo ${label} é obrigatório`}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </div>
    </DatePickerContainer>
  )
}
