/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import api from '../../services/api'
import { Container } from './styles'

interface BoardOfSummary {
  order: {
    orderNumber: string
    orderQuantity: number
    converteToOrder: string
  }
  orderDiscount: {
    orderDiscountNumber: string
    orderDiscountQuantity: number
    discountToClient: string
  }
  budget: {
    budgetNumber: string
    budgetQuantity: number
    contactPending: number
  }
  calledOff: {
    calledOffNumber: string
    calledOffQuantity: number
    calledOffToClient: number
  }
  revenue: {
    billerdNumber: string
    toProfitNumber: number
    billerdQuantity: number
    prospection: number
  }
}
interface ISearchParams {
  data: string
  entity: string
  source: string
  fieldSearch: string
  header: {
    id: string
    title: string
    tableField: string
    type?: string
    formatStyle?: string
    precision?: number
    locale?: string
    symbol?: string
    source?: string
    emptyValue?: string
    multiItem?: string[]
    condition?: { true: string; false: string }
  }[]
  innerJoin?: {
    entity: string
    source: string
    field: string
    fieldCondition: string
    sourceCondition: string
  }[]
}
interface BoardOfSummaryProps {
  getUserData?: () => void
  boardOfSummaryData: BoardOfSummary[]
  generatedExport?: boolean
}

const BoardOfSummary: React.FC<BoardOfSummaryProps> = ({
  boardOfSummaryData,
  generatedExport
}) => {
  const handleGenerateCsv = useCallback(
    async ({
      data,
      entity,
      fieldSearch,
      header,
      source,
      innerJoin
    }: ISearchParams) => {
      if (!generatedExport) return
      if (!data) return
      const [year, month, day] = new Date()
        .toISOString()
        .slice(0, 10)
        .split('-')
      const date = `${day}-${month}-${year}`
      const hashedOrders = btoa(data)
      api
        .post('commercial/orders/generateexport', {
          data: hashedOrders,
          entity,
          fieldSearch,
          source,
          header,
          innerJoin
        })
        .then(response => {
          const blob = new Blob(['\ufeff' + response.data], {
            type: 'text/csv;charset=utf-8"'
          })
          const url = window.URL.createObjectURL(blob)

          const a = document.createElement('a')
          a.download = `relatorio-pedidos-${date}.csv`
          a.href = url
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        })
    },
    [generatedExport]
  )
  return (
    <Container className="col-md-6">
      <div className="portlet light ">
        <div className="portlet-title" style={{ marginBottom: 0 }}>
          <div className="caption caption-md">
            <i className="icon-bar-chart font-dark hide"></i>
            <span className="maintitle">Resumo do Comercial</span>
            <span className="caption-helper"></span>
          </div>
        </div>
        <div className="portlet-body">
          {boardOfSummaryData.length &&
            boardOfSummaryData.map((sale: any) => (
              <div className="container" key={sale.order.orderQuantity * 10}>
                <div
                  className="row "
                  style={{
                    padding: '30px 0px',
                    borderBottom: '1px solid #F2F5F8'
                  }}
                >
                  <div
                    className="col-md-4"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.approved?.orders,
                        entity: 'Order',
                        fieldSearch: 'id',
                        header: [
                          {
                            id: 'order_id',
                            tableField: 'id',
                            title: 'Pedido'
                          },
                          {
                            id: 'date',
                            tableField: 'date',
                            title: 'Data',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'client_name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE',
                            source: 'Client'
                          },
                          {
                            id: 'products_value',
                            tableField: 'products_value',
                            title: 'Valor Produtos',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'freight_value',
                            tableField: 'freight_value',
                            title: 'Valor Frete',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'show_included_freight',
                            tableField: 'show_included_freight',
                            title: 'Frete Embutido',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'ipi_value',
                            tableField: 'ipi_value',
                            title: 'Valor IPI',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'total_value',
                            tableField: 'total_value',
                            title: 'Valor Total',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'invoice_date',
                            tableField: 'invoice_date',
                            title: 'Data Faturamento',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'released',
                            tableField: 'released',
                            title: 'Faturado',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'agent_id',
                            tableField: 'name',
                            title: 'Representante',
                            source: 'Employee'
                          }
                        ],
                        source: 'Order',
                        innerJoin: [
                          {
                            entity: 'Client',
                            source: 'Client',
                            field: 'id',
                            fieldCondition: 'client_id',
                            sourceCondition: 'Order'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Employee',
                            source: 'Employee',
                            field: 'agent_id',
                            fieldCondition: 'agent_id',
                            sourceCondition: 'Order'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm">Pedido</div>
                    <div className="uppercase font-hg">
                      {sale.order.orderNumber}
                    </div>
                  </div>
                  <div
                    className="col-md-4 "
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.approved?.orders,
                        entity: 'Order',
                        fieldSearch: 'id',
                        header: [
                          {
                            id: 'order_id',
                            tableField: 'id',
                            title: 'Pedido'
                          },
                          {
                            id: 'date',
                            tableField: 'date',
                            title: 'Data',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'client_name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE',
                            source: 'Client'
                          },
                          {
                            id: 'products_value',
                            tableField: 'products_value',
                            title: 'Valor Produtos',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'freight_value',
                            tableField: 'freight_value',
                            title: 'Valor Frete',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'show_included_freight',
                            tableField: 'show_included_freight',
                            title: 'Frete Embutido',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'ipi_value',
                            tableField: 'ipi_value',
                            title: 'Valor IPI',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'total_value',
                            tableField: 'total_value',
                            title: 'Valor Total',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'invoice_date',
                            tableField: 'invoice_date',
                            title: 'Data Faturamento',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'released',
                            tableField: 'released',
                            title: 'Faturado',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'agent_id',
                            tableField: 'name',
                            title: 'Representante',
                            source: 'Employee'
                          }
                        ],
                        source: 'Order',
                        innerJoin: [
                          {
                            entity: 'Client',
                            source: 'Client',
                            field: 'id',
                            fieldCondition: 'client_id',
                            sourceCondition: 'Order'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Employee',
                            source: 'Employee',
                            field: 'agent_id',
                            fieldCondition: 'agent_id',
                            sourceCondition: 'Order'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm"> Qtd Pedido </div>
                    <div className="uppercase font-hg">
                      {sale.order.orderQuantity}
                    </div>
                  </div>
                  <div
                    className="col-md-4 "
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.approved?.orders,
                        entity: 'Order',
                        fieldSearch: 'id',
                        header: [
                          {
                            id: 'order_id',
                            tableField: 'id',
                            title: 'Pedido'
                          },
                          {
                            id: 'date',
                            tableField: 'date',
                            title: 'Data',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'client_name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE',
                            source: 'Client'
                          },
                          {
                            id: 'products_value',
                            tableField: 'products_value',
                            title: 'Valor Produtos',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'freight_value',
                            tableField: 'freight_value',
                            title: 'Valor Frete',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'show_included_freight',
                            tableField: 'show_included_freight',
                            title: 'Frete Embutido',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'ipi_value',
                            tableField: 'ipi_value',
                            title: 'Valor IPI',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'total_value',
                            tableField: 'total_value',
                            title: 'Valor Total',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'invoice_date',
                            tableField: 'invoice_date',
                            title: 'Data Faturamento',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'released',
                            tableField: 'released',
                            title: 'Faturado',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'agent_id',
                            tableField: 'name',
                            title: 'Representante',
                            source: 'Employee'
                          }
                        ],
                        source: 'Order',
                        innerJoin: [
                          {
                            entity: 'Client',
                            source: 'Client',
                            field: 'id',
                            fieldCondition: 'client_id',
                            sourceCondition: 'Order'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Employee',
                            source: 'Employee',
                            field: 'agent_id',
                            fieldCondition: 'agent_id',
                            sourceCondition: 'Order'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm">
                      Orçamento convertido em Pedido
                    </div>
                    <div className="uppercase font-hg">
                      {sale.order.converteToOrder}
                    </div>
                  </div>
                </div>
                <div
                  className="row "
                  style={{
                    padding: '30px 0px',
                    borderBottom: '1px solid #F2F5F8'
                  }}
                >
                  <div
                    className="col-md-4"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.ordersTotalDiscount,
                        entity: 'Order',
                        fieldSearch: 'id',
                        header: [
                          {
                            id: 'order_id',
                            tableField: 'id',
                            title: 'Pedido'
                          },
                          {
                            id: 'date',
                            tableField: 'date',
                            title: 'Data',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'client_name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE',
                            source: 'Client'
                          },
                          {
                            id: 'products_value',
                            tableField: 'products_value',
                            title: 'Valor Produtos',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'freight_value',
                            tableField: 'freight_value',
                            title: 'Valor Frete',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'show_included_freight',
                            tableField: 'show_included_freight',
                            title: 'Frete Embutido',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'ipi_value',
                            tableField: 'ipi_value',
                            title: 'Valor IPI',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'total_value',
                            tableField: 'total_value',
                            title: 'Valor Total',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'invoice_date',
                            tableField: 'invoice_date',
                            title: 'Data Faturamento',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'released',
                            tableField: 'released',
                            title: 'Faturado',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'agent_id',
                            tableField: 'name',
                            title: 'Representante',
                            source: 'Employee'
                          }
                        ],
                        source: 'Order',
                        innerJoin: [
                          {
                            entity: 'Client',
                            source: 'Client',
                            field: 'id',
                            fieldCondition: 'client_id',
                            sourceCondition: 'Order'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Employee',
                            source: 'Employee',
                            field: 'agent_id',
                            fieldCondition: 'agent_id',
                            sourceCondition: 'Order'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm">Desconto</div>
                    <div className="uppercase font-hg">
                      {sale.orderDiscount.orderDiscountNumber}
                    </div>
                  </div>
                  <div
                    className="col-md-4"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.ordersTotalDiscount,
                        entity: 'Order',
                        fieldSearch: 'id',
                        header: [
                          {
                            id: 'order_id',
                            tableField: 'id',
                            title: 'Pedido'
                          },
                          {
                            id: 'date',
                            tableField: 'date',
                            title: 'Data',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'client_name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE',
                            source: 'Client'
                          },
                          {
                            id: 'products_value',
                            tableField: 'products_value',
                            title: 'Valor Produtos',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'freight_value',
                            tableField: 'freight_value',
                            title: 'Valor Frete',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'show_included_freight',
                            tableField: 'show_included_freight',
                            title: 'Frete Embutido',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'ipi_value',
                            tableField: 'ipi_value',
                            title: 'Valor IPI',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'total_value',
                            tableField: 'total_value',
                            title: 'Valor Total',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'invoice_date',
                            tableField: 'invoice_date',
                            title: 'Data Faturamento',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'released',
                            tableField: 'released',
                            title: 'Faturado',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'agent_id',
                            tableField: 'name',
                            title: 'Representante',
                            source: 'Employee'
                          }
                        ],
                        source: 'Order',
                        innerJoin: [
                          {
                            entity: 'Client',
                            source: 'Client',
                            field: 'id',
                            fieldCondition: 'client_id',
                            sourceCondition: 'Order'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Employee',
                            source: 'Employee',
                            field: 'agent_id',
                            fieldCondition: 'agent_id',
                            sourceCondition: 'Order'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm"> Qtd Desconto </div>
                    <div className="uppercase font-hg">
                      {sale.orderDiscount.orderDiscountQuantity}
                    </div>
                  </div>
                  <div
                    className="col-md-4"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.ordersTotalDiscount,
                        entity: 'Order',
                        fieldSearch: 'id',
                        header: [
                          {
                            id: 'order_id',
                            tableField: 'id',
                            title: 'Pedido'
                          },
                          {
                            id: 'date',
                            tableField: 'date',
                            title: 'Data',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'client_name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE',
                            source: 'Client'
                          },
                          {
                            id: 'products_value',
                            tableField: 'products_value',
                            title: 'Valor Produtos',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'freight_value',
                            tableField: 'freight_value',
                            title: 'Valor Frete',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'show_included_freight',
                            tableField: 'show_included_freight',
                            title: 'Frete Embutido',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'ipi_value',
                            tableField: 'ipi_value',
                            title: 'Valor IPI',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'total_value',
                            tableField: 'total_value',
                            title: 'Valor Total',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'invoice_date',
                            tableField: 'invoice_date',
                            title: 'Data Faturamento',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'released',
                            tableField: 'released',
                            title: 'Faturado',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'agent_id',
                            tableField: 'name',
                            title: 'Representante',
                            source: 'Employee'
                          }
                        ],
                        source: 'Order',
                        innerJoin: [
                          {
                            entity: 'Client',
                            source: 'Client',
                            field: 'id',
                            fieldCondition: 'client_id',
                            sourceCondition: 'Order'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Employee',
                            source: 'Employee',
                            field: 'agent_id',
                            fieldCondition: 'agent_id',
                            sourceCondition: 'Order'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm">Desconto concedido em Pedido</div>
                    <div className="uppercase font-hg">
                      {sale.orderDiscount.discountToClient}
                    </div>
                  </div>
                </div>
                <div
                  className="row "
                  style={{
                    padding: '30px 0px',
                    borderBottom: '1px solid #F2F5F8'
                  }}
                >
                  <div
                    className="col-md-4"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.pending?.orders,
                        entity: 'Order',
                        fieldSearch: 'id',
                        header: [
                          {
                            id: 'order_id',
                            tableField: 'id',
                            title: 'Pedido'
                          },
                          {
                            id: 'date',
                            tableField: 'date',
                            title: 'Data',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'client_name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE',
                            source: 'Client'
                          },
                          {
                            id: 'products_value',
                            tableField: 'products_value',
                            title: 'Valor Produtos',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'freight_value',
                            tableField: 'freight_value',
                            title: 'Valor Frete',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'show_included_freight',
                            tableField: 'show_included_freight',
                            title: 'Frete Embutido',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'ipi_value',
                            tableField: 'ipi_value',
                            title: 'Valor IPI',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'total_value',
                            tableField: 'total_value',
                            title: 'Valor Total',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'invoice_date',
                            tableField: 'invoice_date',
                            title: 'Data Faturamento',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'released',
                            tableField: 'released',
                            title: 'Faturado',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'agent_id',
                            tableField: 'name',
                            title: 'Representante',
                            source: 'Employee'
                          }
                        ],
                        source: 'Order',
                        innerJoin: [
                          {
                            entity: 'Client',
                            source: 'Client',
                            field: 'id',
                            fieldCondition: 'client_id',
                            sourceCondition: 'Order'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Employee',
                            source: 'Employee',
                            field: 'agent_id',
                            fieldCondition: 'agent_id',
                            sourceCondition: 'Order'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm"> Orçamento</div>
                    <div className="uppercase font-hg">
                      {sale.budget.budgetNumber}
                    </div>
                  </div>
                  <div
                    className="col-md-4"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.pending?.orders,
                        entity: 'Order',
                        fieldSearch: 'id',
                        header: [
                          {
                            id: 'order_id',
                            tableField: 'id',
                            title: 'Pedido'
                          },
                          {
                            id: 'date',
                            tableField: 'date',
                            title: 'Data',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'client_name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE',
                            source: 'Client'
                          },
                          {
                            id: 'products_value',
                            tableField: 'products_value',
                            title: 'Valor Produtos',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'freight_value',
                            tableField: 'freight_value',
                            title: 'Valor Frete',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'show_included_freight',
                            tableField: 'show_included_freight',
                            title: 'Frete Embutido',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'ipi_value',
                            tableField: 'ipi_value',
                            title: 'Valor IPI',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'total_value',
                            tableField: 'total_value',
                            title: 'Valor Total',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'invoice_date',
                            tableField: 'invoice_date',
                            title: 'Data Faturamento',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'released',
                            tableField: 'released',
                            title: 'Faturado',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'agent_id',
                            tableField: 'name',
                            title: 'Representante',
                            source: 'Employee'
                          }
                        ],
                        source: 'Order',
                        innerJoin: [
                          {
                            entity: 'Client',
                            source: 'Client',
                            field: 'id',
                            fieldCondition: 'client_id',
                            sourceCondition: 'Order'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Employee',
                            source: 'Employee',
                            field: 'agent_id',
                            fieldCondition: 'agent_id',
                            sourceCondition: 'Order'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm"> Qtd Orçamento </div>
                    <div className="uppercase font-hg">
                      {sale.budget.budgetQuantity}
                    </div>
                  </div>
                  <div
                    className="col-md-4"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.pending?.ordersMonitoringSituation,
                        entity: 'Order',
                        fieldSearch: 'id',
                        header: [
                          {
                            id: 'order_id',
                            tableField: 'id',
                            title: 'Pedido'
                          },
                          {
                            id: 'date',
                            tableField: 'date',
                            title: 'Data',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'client_name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE',
                            source: 'Client'
                          },
                          {
                            id: 'products_value',
                            tableField: 'products_value',
                            title: 'Valor Produtos',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'freight_value',
                            tableField: 'freight_value',
                            title: 'Valor Frete',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'show_included_freight',
                            tableField: 'show_included_freight',
                            title: 'Frete Embutido',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'ipi_value',
                            tableField: 'ipi_value',
                            title: 'Valor IPI',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'total_value',
                            tableField: 'total_value',
                            title: 'Valor Total',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'invoice_date',
                            tableField: 'invoice_date',
                            title: 'Data Faturamento',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'released',
                            tableField: 'released',
                            title: 'Faturado',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'agent_id',
                            tableField: 'name',
                            title: 'Representante',
                            source: 'Employee'
                          }
                        ],
                        source: 'Order',
                        innerJoin: [
                          {
                            entity: 'Client',
                            source: 'Client',
                            field: 'id',
                            fieldCondition: 'client_id',
                            sourceCondition: 'Order'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Employee',
                            source: 'Employee',
                            field: 'agent_id',
                            fieldCondition: 'agent_id',
                            sourceCondition: 'Order'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm">
                      Orçamento Pendente de Oportunidade
                    </div>
                    <div className="uppercase font-hg">
                      {sale.budget.contactPending}
                    </div>
                  </div>
                </div>
                <div
                  className="row "
                  style={{
                    padding: '30px 0px',
                    borderBottom: '1px solid #F2F5F8'
                  }}
                >
                  <div
                    className="col-md-4"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.canceled?.orders,
                        entity: 'Order',
                        fieldSearch: 'id',
                        header: [
                          {
                            id: 'order_id',
                            tableField: 'id',
                            title: 'Pedido'
                          },
                          {
                            id: 'date',
                            tableField: 'date',
                            title: 'Data',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'client_name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE',
                            source: 'Client'
                          },
                          {
                            id: 'products_value',
                            tableField: 'products_value',
                            title: 'Valor Produtos',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'freight_value',
                            tableField: 'freight_value',
                            title: 'Valor Frete',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'show_included_freight',
                            tableField: 'show_included_freight',
                            title: 'Frete Embutido',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'ipi_value',
                            tableField: 'ipi_value',
                            title: 'Valor IPI',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'total_value',
                            tableField: 'total_value',
                            title: 'Valor Total',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'invoice_date',
                            tableField: 'invoice_date',
                            title: 'Data Faturamento',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'released',
                            tableField: 'released',
                            title: 'Faturado',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'agent_id',
                            tableField: 'name',
                            title: 'Representante',
                            source: 'Employee'
                          }
                        ],
                        source: 'Order',
                        innerJoin: [
                          {
                            entity: 'Client',
                            source: 'Client',
                            field: 'id',
                            fieldCondition: 'client_id',
                            sourceCondition: 'Order'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Employee',
                            source: 'Employee',
                            field: 'agent_id',
                            fieldCondition: 'agent_id',
                            sourceCondition: 'Order'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm">Cancelado</div>
                    <div className="uppercase font-hg">
                      {sale.calledOff.calledOffNumber}
                    </div>
                  </div>
                  <div
                    className="col-md-4"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.canceled?.orders,
                        entity: 'Order',
                        fieldSearch: 'id',
                        header: [
                          {
                            id: 'order_id',
                            tableField: 'id',
                            title: 'Pedido'
                          },
                          {
                            id: 'date',
                            tableField: 'date',
                            title: 'Data',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'client_name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE',
                            source: 'Client'
                          },
                          {
                            id: 'products_value',
                            tableField: 'products_value',
                            title: 'Valor Produtos',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'freight_value',
                            tableField: 'freight_value',
                            title: 'Valor Frete',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'show_included_freight',
                            tableField: 'show_included_freight',
                            title: 'Frete Embutido',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'ipi_value',
                            tableField: 'ipi_value',
                            title: 'Valor IPI',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'total_value',
                            tableField: 'total_value',
                            title: 'Valor Total',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'invoice_date',
                            tableField: 'invoice_date',
                            title: 'Data Faturamento',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'released',
                            tableField: 'released',
                            title: 'Faturado',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'agent_id',
                            tableField: 'name',
                            title: 'Representante',
                            source: 'Employee'
                          }
                        ],
                        source: 'Order',
                        innerJoin: [
                          {
                            entity: 'Client',
                            source: 'Client',
                            field: 'id',
                            fieldCondition: 'client_id',
                            sourceCondition: 'Order'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Employee',
                            source: 'Employee',
                            field: 'agent_id',
                            fieldCondition: 'agent_id',
                            sourceCondition: 'Order'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm">Qtd Cancelado </div>
                    <div className="uppercase font-hg">
                      {sale.calledOff.calledOffQuantity}
                    </div>
                  </div>
                  <div
                    className="col-md-4"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.ordersCanceledToOrders,
                        entity: 'Order',
                        fieldSearch: 'id',
                        header: [
                          {
                            id: 'order_id',
                            tableField: 'id',
                            title: 'Pedido'
                          },
                          {
                            id: 'date',
                            tableField: 'date',
                            title: 'Data',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'client_name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE',
                            source: 'Client'
                          },
                          {
                            id: 'products_value',
                            tableField: 'products_value',
                            title: 'Valor Produtos',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'freight_value',
                            tableField: 'freight_value',
                            title: 'Valor Frete',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'show_included_freight',
                            tableField: 'show_included_freight',
                            title: 'Frete Embutido',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'ipi_value',
                            tableField: 'ipi_value',
                            title: 'Valor IPI',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'total_value',
                            tableField: 'total_value',
                            title: 'Valor Total',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'invoice_date',
                            tableField: 'invoice_date',
                            title: 'Data Faturamento',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'released',
                            tableField: 'released',
                            title: 'Faturado',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'agent_id',
                            tableField: 'name',
                            title: 'Representante',
                            source: 'Employee'
                          }
                        ],
                        source: 'Order',
                        innerJoin: [
                          {
                            entity: 'Client',
                            source: 'Client',
                            field: 'id',
                            fieldCondition: 'client_id',
                            sourceCondition: 'Order'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Employee',
                            source: 'Employee',
                            field: 'agent_id',
                            fieldCondition: 'agent_id',
                            sourceCondition: 'Order'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm">
                      Cancelado convertido em Pedido
                    </div>
                    <div className="uppercase font-hg">
                      {sale.calledOff.calledOffToClient}
                    </div>
                  </div>
                </div>
                <div
                  className="row "
                  style={{
                    padding: '30px 0px'
                  }}
                >
                  <div
                    className="col-md-4"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.orders,
                        entity: 'Order',
                        fieldSearch: 'id',
                        header: [
                          {
                            id: 'order_id',
                            tableField: 'id',
                            title: 'Pedido'
                          },
                          {
                            id: 'date',
                            tableField: 'date',
                            title: 'Data',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'client_name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE',
                            source: 'Client'
                          },
                          {
                            id: 'products_value',
                            tableField: 'products_value',
                            title: 'Valor Produtos',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'freight_value',
                            tableField: 'freight_value',
                            title: 'Valor Frete',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'show_included_freight',
                            tableField: 'show_included_freight',
                            title: 'Frete Embutido',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'ipi_value',
                            tableField: 'ipi_value',
                            title: 'Valor IPI',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'total_value',
                            tableField: 'total_value',
                            title: 'Valor Total',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'invoice_date',
                            tableField: 'invoice_date',
                            title: 'Data Faturamento',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'released',
                            tableField: 'released',
                            title: 'Faturado',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'agent_id',
                            tableField: 'name',
                            title: 'Representante',
                            source: 'Employee'
                          }
                        ],
                        source: 'Order',
                        innerJoin: [
                          {
                            entity: 'Client',
                            source: 'Client',
                            field: 'id',
                            fieldCondition: 'client_id',
                            sourceCondition: 'Order'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Employee',
                            source: 'Employee',
                            field: 'agent_id',
                            fieldCondition: 'agent_id',
                            sourceCondition: 'Order'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm">Faturado</div>
                    <div className="uppercase font-hg">
                      {sale.revenue.billerdNumber}
                    </div>
                  </div>
                  <div
                    className="col-md-4"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.orders,
                        entity: 'Order',
                        fieldSearch: 'id',
                        header: [
                          {
                            id: 'order_id',
                            tableField: 'id',
                            title: 'Pedido'
                          },
                          {
                            id: 'date',
                            tableField: 'date',
                            title: 'Data',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'client_name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE',
                            source: 'Client'
                          },
                          {
                            id: 'products_value',
                            tableField: 'products_value',
                            title: 'Valor Produtos',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'freight_value',
                            tableField: 'freight_value',
                            title: 'Valor Frete',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'show_included_freight',
                            tableField: 'show_included_freight',
                            title: 'Frete Embutido',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'ipi_value',
                            tableField: 'ipi_value',
                            title: 'Valor IPI',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'total_value',
                            tableField: 'total_value',
                            title: 'Valor Total',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'invoice_date',
                            tableField: 'invoice_date',
                            title: 'Data Faturamento',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'released',
                            tableField: 'released',
                            title: 'Faturado',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'agent_id',
                            tableField: 'name',
                            title: 'Representante',
                            source: 'Employee'
                          }
                        ],
                        source: 'Order',
                        innerJoin: [
                          {
                            entity: 'Client',
                            source: 'Client',
                            field: 'id',
                            fieldCondition: 'client_id',
                            sourceCondition: 'Order'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Employee',
                            source: 'Employee',
                            field: 'agent_id',
                            fieldCondition: 'agent_id',
                            sourceCondition: 'Order'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm">Qtd Faturado</div>
                    <div className="uppercase font-hg">
                      {sale.revenue.billerdQuantity}
                    </div>
                  </div>
                  <div
                    className="col-md-4"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.ordersToProfit,
                        entity: 'Order',
                        fieldSearch: 'id',
                        header: [
                          {
                            id: 'order_id',
                            tableField: 'id',
                            title: 'Pedido'
                          },
                          {
                            id: 'date',
                            tableField: 'date',
                            title: 'Data',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'client_name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE',
                            source: 'Client'
                          },
                          {
                            id: 'products_value',
                            tableField: 'products_value',
                            title: 'Valor Produtos',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'freight_value',
                            tableField: 'freight_value',
                            title: 'Valor Frete',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'show_included_freight',
                            tableField: 'show_included_freight',
                            title: 'Frete Embutido',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'ipi_value',
                            tableField: 'ipi_value',
                            title: 'Valor IPI',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'total_value',
                            tableField: 'total_value',
                            title: 'Valor Total',
                            type: 'money',
                            symbol: 'R$',
                            precision: 2,
                            locale: 'pt_BR'
                          },
                          {
                            id: 'invoice_date',
                            tableField: 'invoice_date',
                            title: 'Data Faturamento',
                            type: 'date',
                            formatStyle: '%Y-%m-%d'
                          },
                          {
                            id: 'released',
                            tableField: 'released',
                            title: 'Faturado',
                            type: 'boolean',
                            condition: {
                              true: 'Sim',
                              false: 'Não'
                            }
                          },
                          {
                            id: 'agent_id',
                            tableField: 'name',
                            title: 'Representante',
                            source: 'Employee'
                          }
                        ],
                        source: 'Order',
                        innerJoin: [
                          {
                            entity: 'Client',
                            source: 'Client',
                            field: 'id',
                            fieldCondition: 'client_id',
                            sourceCondition: 'Order'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Employee',
                            source: 'Employee',
                            field: 'agent_id',
                            fieldCondition: 'agent_id',
                            sourceCondition: 'Order'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm">A Faturar</div>
                    <div className="uppercase font-hg">
                      {sale.revenue.toProfitNumber}
                    </div>
                  </div>
                </div>
                <div
                  className="row "
                  style={{
                    padding: '30px 0px'
                  }}
                >
                  <div
                    className="col-md-4"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      handleGenerateCsv({
                        data: sale.ordersProspection,
                        entity: 'Client',
                        fieldSearch: 'id',
                        source: 'Client',
                        header: [
                          {
                            id: 'name',
                            tableField: 'name',
                            title: 'Cliente',
                            emptyValue: 'SEM CLIENTE'
                          },
                          {
                            id: 'name_department',
                            tableField: 'name',
                            title: 'Segmento',
                            source: 'Departments',
                            emptyValue: 'SEM SEGMENTO'
                          },
                          {
                            id: 'phone_number1',
                            tableField: 'phone_number1',
                            title: 'Telefone',
                            multiItem: [
                              'phone_number2',
                              'cellphone_number1',
                              'cellphone_number2'
                            ],
                            emptyValue: 'SEM TELEFONE'
                          },
                          {
                            id: 'email1',
                            tableField: 'email1',
                            title: 'Email',
                            multiItem: ['email2', 'email3'],
                            emptyValue: 'SEM EMAIL'
                          },
                          {
                            id: 'state_client',
                            tableField: 'state',
                            title: 'Estado',
                            source: 'ClientAddress'
                          },
                          {
                            id: 'city_client',
                            tableField: 'city',
                            title: 'Cidade',
                            source: 'ClientAddress'
                          }
                        ],
                        innerJoin: [
                          {
                            entity: 'ClientAddress',
                            source: 'ClientAddress',
                            field: 'client_id',
                            fieldCondition: 'id',
                            sourceCondition: 'Client'
                          },
                          {
                            entity: 'Departments',
                            source: 'Departments',
                            field: 'id',
                            fieldCondition: 'department_id',
                            sourceCondition: 'Client'
                          }
                        ]
                      })
                    }
                  >
                    <div className="font-sm">Prospecção</div>
                    <div className="uppercase font-hg">
                      {sale.revenue.prospection}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Container>
  )
}

export { BoardOfSummary }
