export const headers = [
  { name: 'Nº Pedido', field: 'id', sortable: true },
  { name: 'Data', field: 'date', sortable: true, custom: true },
  { name: 'Cliente', field: 'client.name', sortable: true },
  {
    name: 'Entrega prevista',
    field: 'delivery_date',
    custom: true,
    sortable: true
  },
  { name: 'Status', field: 'expedition.name', sortable: true },
  {
    name: 'Status expiração',
    field: 'expedicao_status_expiracao',
    sortable: true,
    custom: true
  },
  {
    name: 'Status atualização',
    field: 'expedicao_status_data',
    sortable: true,
    custom: true
  },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const logsHeaders = [
  {
    name: 'Data',
    field: 'date',
    sortable: true
  },
  {
    name: 'Status anterior',
    field: 'prev.name',
    sortable: true
  },
  {
    name: 'Status novo',
    field: 'new.name',
    sortable: true
  },
  {
    name: 'Status expiração',
    field: 'expiration',
    sortable: true,
    custom: true
  },
  { name: 'Usuário', field: 'user.name', sortable: true },
  {
    name: 'Observação',
    field: 'description',
    sortable: true
  }
]

export const productsHeaders = [
  { name: 'Cód.', field: 'product_id', sortable: true },
  { name: 'Produto', field: 'product_name', sortable: true },
  { name: 'Quantidade', field: 'quantity', sortable: true }
]
