import React, { useEffect, useState } from 'react'
import Container from '../../../../../../components/Container'
import DataTable from '../../../../../../components/DataTable'
import { nameEntity, namePageTitle, nameSource } from '../../domain/data/info'
import { headers } from '../../domain/data/headers'
import { breadcrumbList } from '../../domain/data/breadcrumb'
import { toolsList } from '../../domain/data/tools'
import {
  loadProductTypes,
  loadProductCategories,
  ProductCategory
} from '../../services/api'
import { FilterContaier, ListTitle } from './styles'

const ProductAtributesList = (): JSX.Element => {
  const [productType, setProductType] = useState('')
  const [productCategory, setProductCategory] = useState('')
  const [parameters, setParameters] = useState<any>()
  const [productCategories, setProductCategories] =
    useState<ProductCategory[]>()

  useEffect(() => {
    ;(async () => {
      const categories = await loadProductCategories()
      setProductCategories(categories)
    })()
  }, [])
  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={'Filtro'}
        breadcrumb={breadcrumbList}
        tools={[toolsList]}
      >
        <FilterContaier>
          <div>
            <div className="col-md-3">
              <label htmlFor="product-type">Tipo</label>
              <select
                className="form-control"
                id="product-type"
                value={productType}
                onChange={({ target }) => setProductType(target.value)}
              >
                <option value="" style={{ display: 'none' }}>
                  Selecione
                </option>
                {loadProductTypes().map(({ name, label }) => (
                  <option key={name} value={name}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <label htmlFor="product-group">Grupo</label>
              <select
                className="form-control"
                id="product-group"
                value={productCategory}
                onChange={({ target }) => setProductCategory(target.value)}
              >
                <option value="" style={{ display: 'none' }}>
                  Selecione
                </option>
                {productCategories?.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <footer>
            <button
              onClick={() => {
                setProductType('')
                setProductCategory('')
              }}
            >
              LIMPAR
            </button>
            <button
              onClick={() => {
                if (productType || productCategory) {
                  setParameters([
                    {
                      type: productType || undefined,
                      product_category_id: productCategory || undefined
                    }
                  ])
                } else {
                  setParameters(undefined)
                }
              }}
            >
              BUSCAR
            </button>
          </footer>
          <hr />
        </FilterContaier>
        <ListTitle>
          <p>Listagem</p>
          <hr style={{ paddingBottom: 10 }} />
        </ListTitle>
        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'name' }}
          notHasChildren
          headers={headers}
          searchParameters={parameters}
        />
      </Container>
    </>
  )
}

export default ProductAtributesList
