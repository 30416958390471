/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ControlPanel } from './style'
import { AmChartMixedColumn } from '../../../components/AmChartMixedColumn'
import { AmChartSortedBar } from '../../../components/AmChartSortedBar'
import { HeaderPortlet } from '../../../components/HeaderPortlet'
import { TablePortlet } from '../../../components/TablePortlet'
import Container from '../../../components/Container'
import api from '../../../services/api'
import currency from 'currency.js'
import { useLoading } from '../../../hooks/loading'
import { AmChartPizza } from '../../../components/AmChartPizza'
import * as am4core from '@amcharts/amcharts4/core'
import { useLocation, useParams } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
const CashFlowAnalysis: React.FC = () => {
  const [selectPeriod, setSelectPeriod] = useQueryParam('period', StringParam)
  const [paidBills, setPaidBills] = useState([])
  const [accountsPayable, setAccountsPayable] = useState([])
  const [dataUpdateIncomePizza, setDataUpdateIncomePizza] = useState([])
  const [dataUpdateExpensePizza, setDataUpdateExpensePizza] = useState([])
  const [cashFlowAnalysisData, setcashFlowAnalysisData] = useState<any>([])
  const requestCashFlowAnalysisDataRef = useRef({})
  const [dataUpdatePayableMixed, setDataUpdatePayableMixed] = useState([])
  const [dataUpdateMixed, setDataUpdateMixed] = useState([])
  const [selectedDate, setSelectedDate] = useState('Ano')
  const [selectedMonthId, setSelectedMonthId] = useState(0)
  const selectStartIndexPeriod: any = {
    year: {
      index: 3,
      period: 'Ano'
    },
    month: {
      index: 2,
      period: 'Mês'
    },
    week: {
      index: 1,
      period: 'Semana'
    },
    day: {
      index: 0,
      period: 'Hoje'
    }
  }
  const [dataUpdatePayableIncomePizza, setDataUpdatePayableIncomePizza] =
    useState([])
  const [dataUpdatePayableExpensePizza, setDataUpdatePayableExpensePizza] =
    useState([])
  const [selectedSituation, setSelectedSituation] = useState(1)
  const [selectedMonts, setSelectedMonts] = useState<any>({
    1: {
      isActive: false,
      month: 0
    },
    0: {
      isActive: false,
      month: 0
    }
  })
  const { activeLoading, disableLoading } = useLoading()
  const [datesRange, setDatesRange] = useState({
    minDate: undefined,
    maxDate: undefined
  })
  const [datesRangePayable, setDatesRangePayable] = useState({
    minDate: undefined,
    maxDate: undefined
  })
  const percetageExpenseRef = useRef(0)
  const percetageIncomeRef = useRef(0)

  const BRL = (value: any) =>
    currency(value, {
      symbol: '',
      decimal: ',',
      separator: '.'
    })
  const findGraphicCategoriesIndex = (data: any[]) => {
    return data.findIndex((item: any) => !!item.graphicData)
  }
  const dateRange = (date: string) => {
    const year = date.split(' ')[2]
    const minDate = new Date(`${year}/${date.split(' ')[1]}`)
      .toISOString()
      .slice(0, 7)
    const [, month] = new Date(`${year}/${date.split(' ')[4]}`)
      .toISOString()
      .slice(0, 7)
      .split('-')
    return {
      year,
      minDate,
      maxDate: `${year}-${
        Number(month) + 1 < 10 ? `0${Number(month) + 1}` : Number(month) + 1
      }`
    }
  }

  const findBiggerPercetage = useCallback(
    (data: any[], isParent?: boolean, isLocal?: boolean) => {
      let percentageExpenseData = 0
      let percentageIncomeData = 0
      if (isParent) {
        data
          .filter((item: any) => item)
          .forEach((month: any) => {
            if (month.name !== 'Total' && month.name) {
              month.categories
                .filter((item: any) => item)
                .forEach((category: any) => {
                  if (
                    percentageExpenseData <
                    category.realized_value_percentage_expense
                  ) {
                    percentageExpenseData =
                      category.realized_value_percentage_expense
                  }
                  if (
                    percentageIncomeData <
                    category.realized_value_percentage_income
                  ) {
                    percentageIncomeData =
                      category.realized_value_percentage_income
                  }
                })
            }
          })
        if (!isLocal) {
          percetageExpenseRef.current = percentageExpenseData
          percetageIncomeRef.current = percentageIncomeData
        }
        return { percentageExpenseData, percentageIncomeData }
      }
      data
        .filter((item: any) => item)
        .forEach((category: any, index: number) => {
          if (
            percentageExpenseData < category.realized_value_percentage_expense
          ) {
            percentageExpenseData = category.realized_value_percentage_expense
          }
          if (
            percentageIncomeData < category.realized_value_percentage_income
          ) {
            percentageIncomeData = category.realized_value_percentage_income
          }
        })
      return { percentageExpenseData, percentageIncomeData }
    },
    []
  )
  function createCategories(
    data: any[],
    color: string,
    percentage: any,
    type: string,
    isParent?: boolean
  ) {
    const maxLighten = 0.65
    if (isParent) {
      return data
        .filter((item: any) => item)
        .map((month: any) => {
          if (month.name !== 'Total') {
            return month.categories
              .filter((item: any) => item)
              .sort(
                (currentValue: any, nextValue: any) =>
                  nextValue[`realized_value_percentage_${type}`] -
                  currentValue[`realized_value_percentage_${type}`]
              )
              .filter(
                (category: any) =>
                  category[`realized_value_percentage_${type}`] > 0
              )
              .map((category: any) => {
                let biggerPercent = 0
                const percentLight =
                  (percentage - category[`realized_value_percentage_${type}`]) /
                  100
                return {
                  type: category.name,
                  percent: category[`realized_value_percentage_${type}`],
                  value: category[`realized_value_${type}`],
                  financial_transactions: category.financial_transactions,
                  color: am4core
                    .color(color)
                    .lighten(
                      percentLight > maxLighten ? maxLighten : percentLight
                    ),
                  subs: category.subCategories
                    .filter((item: any) => item)
                    .sort(
                      (currentValue: any, nextValue: any) =>
                        nextValue[`realized_value_percentage_${type}`] -
                        currentValue[`realized_value_percentage_${type}`]
                    )
                    .map((subCategory: any) => {
                      if (
                        subCategory[`realized_value_${type}`] /
                          category[`realized_value_${type}`] >
                        biggerPercent
                      ) {
                        biggerPercent =
                          subCategory[`realized_value_${type}`] /
                          category[`realized_value_${type}`]
                      }
                      const percentLight =
                        biggerPercent -
                        subCategory[`realized_value_${type}`] /
                          category[`realized_value_${type}`]
                      return {
                        financial_transactions:
                          subCategory.financial_transactions,
                        type: subCategory.name,
                        value: subCategory[`realized_value_${type}`],
                        color: am4core
                          .color(color)
                          .lighten(
                            percentLight > maxLighten
                              ? maxLighten
                              : percentLight
                          ),
                        percent:
                          subCategory[`realized_value_percentage_${type}`]
                      }
                    })
                }
              })
          }
        })
        .flatMap((item: any) => item)
        .filter((item: any) => item)
    }
    return data
      .filter((item: any) => item)
      .sort(
        (currentValue: any, nextValue: any) =>
          nextValue[`realized_value_percentage_${type}`] -
          currentValue[`realized_value_percentage_${type}`]
      )
      .filter(
        (category: any) => category[`realized_value_percentage_${type}`] > 0
      )
      .map((category: any) => {
        let biggerPercent = 0
        const percentLight =
          (percentage - category[`realized_value_percentage_${type}`]) / 100
        return {
          type: category.name,
          percent: category[`realized_value_percentage_${type}`],
          value: category[`realized_value_${type}`],
          financial_transactions: category.financial_transactions,
          color: am4core
            .color(color)
            .lighten(percentLight > maxLighten ? maxLighten : percentLight),
          subs: category.subCategories
            .filter((item: any) => item)
            .sort(
              (currentValue: any, nextValue: any) =>
                nextValue[`realized_value_percentage_${type}`] -
                currentValue[`realized_value_percentage_${type}`]
            )
            .map((subCategory: any) => {
              if (
                subCategory[`realized_value_${type}`] /
                  category[`realized_value_${type}`] >
                biggerPercent
              ) {
                biggerPercent =
                  subCategory[`realized_value_${type}`] /
                  category[`realized_value_${type}`]
              }
              const percentLight =
                biggerPercent -
                subCategory[`realized_value_${type}`] /
                  category[`realized_value_${type}`]
              return {
                type: subCategory.name,
                financial_transactions: subCategory.financial_transactions,
                value: subCategory[`realized_value_${type}`],
                color: am4core
                  .color(color)
                  .lighten(
                    percentLight > maxLighten ? maxLighten : percentLight
                  ),
                percent: subCategory[`realized_value_percentage_${type}`]
              }
            })
        }
      })
      .flatMap((item: any) => item)
      .filter((item: any) => item)
  }
  const createTableData = useCallback(
    (data: any, type?: string, situation?: number) => {
      let refItem: any
      const yearRefDate = new Date().getFullYear()
      const hasPeriod = data.some((item: any) => {
        if (item && item.name && item.name !== 'Total') {
          if (!refItem) {
            refItem = { ...item }
          }
          if (refItem) {
            const [yearDate] = item.date.split('-')
            return Number(yearDate) !== Number(yearRefDate)
          }
        }
        return false
      })
      if (type === 'paid') {
        return data
          .filter((item: any) => item)
          .map((month: any) => {
            if (month.name) {
              let id = month.numberMonth
              let column1 = month.name
              if (month.date) {
                const [yearDate, monthDate] = month.date.split('-')
                const yearSliceDate = yearDate
                  .toString()
                  .slice(2, yearDate.length)
                id = Number(`${yearSliceDate}${monthDate}`)
                if (hasPeriod) {
                  column1 = `${month.name}/${yearDate}`
                }
              }
              return {
                disable: month.name === 'Total',
                situation,
                id,
                column1,
                column2: BRL(month.realized_value_income).format(),
                column3: BRL(month.realized_value_expense).format(),
                column4: BRL(month.balance).format(),
                column5: currency(month.profitability, {
                  symbol: '%',
                  pattern: '#!',
                  negativePattern: '-#!'
                }).format(),
                column6: month.realized_account_balance
                  ? BRL(month.realized_account_balance).format()
                  : ''
              }
            }
          })
          .filter((item: any) => item)
      }
      if (type === 'find') {
        let id = data.numberMonth
        let column1 = data.name
        if (data.date) {
          const [yearDate, monthDate] = data.date.split('-')
          const yearSliceDate = yearDate.toString().slice(2, yearDate.length)
          id = Number(`${yearSliceDate}${monthDate}`)
          if (hasPeriod) {
            column1 = `${data.name}/${yearDate}`
          }
        }
        return [
          {
            disable: data.name === 'Total',
            situation,
            id,
            column1,
            column2: BRL(data.realized_value_income).format(),
            column3: BRL(data.realized_value_expense).format(),
            column4: BRL(data.balance).format(),
            column5: currency(data.profitability, {
              symbol: '%',
              pattern: '#!',
              negativePattern: '-#!'
            }).format(),
            column6: currency(
              data.realized_value_expense === 0
                ? 0
                : BRL(data.realized_value_expense)
                    .multiply(100)
                    .divide(data.expected_value)
                    .format(),
              {
                symbol: '%',
                pattern: '#!'
              }
            ).format()
          }
        ]
      }
      return data
        .filter((item: any) => item)
        .map((month: any) => {
          if (month.name) {
            let id = month.numberMonth
            let column1 = month.name
            if (month.date) {
              const [yearDate, monthDate] = month.date.split('-')
              const yearSliceDate = yearDate
                .toString()
                .slice(2, yearDate.length)
              id = Number(`${yearSliceDate}${monthDate}`)
              if (hasPeriod) {
                column1 = `${month.name}/${yearDate}`
              }
            }
            return {
              disable: month.name === 'Total',
              situation,
              id,
              column1,
              column2: BRL(month.realized_value_income).format(),
              column3: BRL(month.realized_value_expense).format(),
              column4: BRL(month.balance).format(),
              column5: currency(month.profitability, {
                symbol: '%',
                pattern: '#!',
                negativePattern: '-#!'
              }).format()
            }
          }
        })
        .filter((item: any) => item)
    },
    []
  )

  async function loadData() {
    let period = 'year'
    if (!isNaN(selectStartIndexPeriod[selectPeriod]?.index)) {
      period = selectPeriod
    }
    activeLoading()
    const response = await api.get(
      '/financial/cashflowanalysis/listPerPeriod/date'
    )
    setcashFlowAnalysisData(response.data)
    setPaidBills(
      createTableData(response.data.cashFlowAnalysis[period][1], 'paid', 1)
    )

    setAccountsPayable(
      createTableData(response.data.cashFlowAnalysis[period][0], undefined, 0)
    )
    setDataUpdateMixed(
      response.data.graphicData
        .filter((item: any) => item)
        .map(
          (month: any) =>
            month && {
              date: month.date,
              goal: month.realized_value_expense,
              goalAccomplished: month.realized_value_income,
              discount: month.predicted_value_income,
              lastYearTarget: month.predicted_value_expense
            }
        )
    )
    setDataUpdatePayableMixed(
      response.data.graphicData[0]
        .filter((item: any) => item)
        .map(
          (month: any) =>
            month && {
              date: month.date,
              goal: month.realized_value_expense,
              goalAccomplished: month.realized_value_income,
              discount: month.predicted_value_income,
              lastYearTarget: month.predicted_value_expense
            }
        )
    )
    const findGraphic = findGraphicCategoriesIndex(
      response.data.cashFlowAnalysis[period][1]
    )
    const findPayableGraphic = findGraphicCategoriesIndex(
      response.data.cashFlowAnalysis[period][0]
    )

    const {
      percentageExpenseData: percentagePayableExpense,
      percentageIncomeData: percentagePayableIncome
    } = findBiggerPercetage(
      response.data.cashFlowAnalysis[period][0][findPayableGraphic].graphicData,
      false,
      true
    )

    const { percentageExpenseData, percentageIncomeData } = findBiggerPercetage(
      response.data.cashFlowAnalysis[period][1][findGraphic].graphicData
    )
    const categoriesExpense = createCategories(
      response.data.cashFlowAnalysis[period][1][findGraphic].graphicData,
      '#c42f2f',
      percentageExpenseData,
      'expense'
    )
    const categoriesIncome = createCategories(
      response.data.cashFlowAnalysis[period][1][findGraphic].graphicData,
      '#62b033',
      percentageIncomeData,
      'income'
    )
    const categoriesPayableExpense = createCategories(
      response.data.cashFlowAnalysis[period][0][findPayableGraphic].graphicData,
      '#c42f2f',
      percentagePayableExpense,
      'expense'
    )
    const categoriesPayableIncome = createCategories(
      response.data.cashFlowAnalysis[period][0][findPayableGraphic].graphicData,
      '#62b033',
      percentagePayableIncome,
      'income'
    )
    setDataUpdateExpensePizza(categoriesExpense)
    setDataUpdateIncomePizza(categoriesIncome)
    setDataUpdatePayableExpensePizza(categoriesPayableExpense)
    setDataUpdatePayableIncomePizza(categoriesPayableIncome)

    setTimeout(() => {
      disableLoading()
    }, 300)
  }
  const setInitialData = useCallback(
    async (date?: string, month_id?: number, item?: any) => {
      const selectDate = date.length > 0 ? date : selectedDate
      const selectMonth = month_id === 0 ? month_id : selectedMonthId
      const currentSituation = item?.situation
        ? item.situation
        : selectedSituation
      let historyMonts: any = { ...selectedMonts }

      setSelectedDate(date.length > 0 ? date : selectedDate)
      setSelectedMonthId(month_id === 0 ? month_id : selectedMonthId)
      let selectPeriod: any
      activeLoading()
      if (
        selectDate === 'Mes' ||
        selectDate === 'Mês' ||
        selectDate.includes('period')
      ) {
        selectPeriod = 'month'
      }
      if (selectDate === 'Ano') {
        selectPeriod = 'year'
      }
      if (selectDate === 'Hoje') {
        selectPeriod = 'day'
      }
      if (selectDate === 'Semana') {
        selectPeriod = 'week'
      }
      if (selectDate.includes('period')) {
        const { minDate, maxDate } = JSON.parse(date || selectDate)
        const response = await api.get(
          `/financial/cashflowanalysis/listPerPeriod/${date || selectDate}`
        )
        setDatesRange({
          minDate,
          maxDate
        })
        setPaidBills(
          createTableData(
            response.data.cashFlowAnalysis[selectPeriod][1],
            'paid',
            1
          )
        )

        setAccountsPayable(
          createTableData(
            response.data.cashFlowAnalysis[selectPeriod][0],
            undefined,
            0
          )
        )

        if (selectMonth) {
          const year = minDate.split('-')[0]
          if (selectedMonts[1].isActive) {
            const monthFind = response.data.cashFlowAnalysis[
              selectPeriod
            ][1].find((month: any) => month?.numberIndex === selectMonth)

            setDatesRange({
              minDate: `${year}-${selectedMonts[1].month}`,
              maxDate: `${year}-${selectedMonts[1].month}`
            })
            if (monthFind) {
              const { percentageExpenseData, percentageIncomeData } =
                findBiggerPercetage(monthFind.categories)
              const percetageExpense = percentageExpenseData
              const percetageIncome = percentageIncomeData

              setDataUpdateMixed(
                response.data.graphicData
                  .filter((item: any) => item)
                  .map(
                    (month: any) =>
                      month && {
                        date: month.date,
                        goal: month.realized_value_expense,
                        goalAccomplished: month.realized_value_income,
                        discount: month.predicted_value_income,
                        lastYearTarget: month.predicted_value_expense
                      }
                  )
              )
              const categoriesExpense = createCategories(
                monthFind.categories,
                '#c42f2f',
                percetageExpense,
                'expense'
              )
              const categoriesIncome = createCategories(
                monthFind.categories,
                '#62b033',
                percetageIncome,
                'income'
              )
              setDataUpdateExpensePizza(categoriesExpense)
              setDataUpdateIncomePizza(categoriesIncome)
            }
          } else {
            setDatesRange({
              minDate,
              maxDate
            })
            const { percentageExpenseData, percentageIncomeData } =
              findBiggerPercetage(
                response.data.cashFlowAnalysis[selectPeriod][1],
                true,
                true
              )
            const percetageExpense = percentageExpenseData
            const percetageIncome = percentageIncomeData
            const findGraphic = findGraphicCategoriesIndex(
              response.data.cashFlowAnalysis[selectPeriod][1]
            )

            const categoriesExpense = createCategories(
              response.data.cashFlowAnalysis[selectPeriod][1][findGraphic]
                .graphicData,
              '#c42f2f',
              percetageExpense,
              'expense'
            )
            const categoriesIncome = createCategories(
              response.data.cashFlowAnalysis[selectPeriod][1][findGraphic]
                .graphicData,
              '#62b033',
              percetageIncome,
              'income'
            )
            setDataUpdateExpensePizza(categoriesExpense)
            setDataUpdateIncomePizza(categoriesIncome)
            setDataUpdateMixed(
              response.data.graphicData
                .filter((item: any) => item)
                .map(
                  (month: any) =>
                    month && {
                      date: month.date,
                      goal: month.realized_value_expense,
                      goalAccomplished: month.realized_value_income,
                      discount: month.predicted_value_income,
                      lastYearTarget: month.predicted_value_expense
                    }
                )
            )
          }
          if (selectedMonts[0].isActive) {
            setDatesRangePayable({
              minDate: `${year}-${selectedMonts[0].month}`,
              maxDate: `${year}-${selectedMonts[0].month}`
            })
            const monthFindAccountPayable = response.data.cashFlowAnalysis[
              selectPeriod
            ][0].find((month: any) => month?.numberIndex === selectMonth)
            if (monthFindAccountPayable) {
              const { percentageExpenseData, percentageIncomeData } =
                findBiggerPercetage(monthFindAccountPayable.categories)
              const percetageExpense = percentageExpenseData
              const percetageIncome = percentageIncomeData
              setDataUpdatePayableMixed(
                response.data.graphicData[0]
                  .filter((item: any) => item)
                  .map(
                    (month: any) =>
                      month && {
                        date: month.date,
                        goal: month.realized_value_expense,
                        goalAccomplished: month.realized_value_income,
                        discount: month.predicted_value_income,
                        lastYearTarget: month.predicted_value_expense
                      }
                  )
              )
              const categoriesExpense = createCategories(
                monthFindAccountPayable.categories,
                '#c42f2f',
                percetageExpense,
                'expense'
              )
              const categoriesIncome = createCategories(
                monthFindAccountPayable.categories,
                '#62b033',
                percetageIncome,
                'income'
              )
              setDataUpdatePayableExpensePizza(categoriesExpense)
              setDataUpdatePayableIncomePizza(categoriesIncome)
            }
          } else {
            setDatesRangePayable({
              minDate,
              maxDate
            })
            const { percentageExpenseData, percentageIncomeData } =
              findBiggerPercetage(
                response.data.cashFlowAnalysis[selectPeriod][0],
                true,
                true
              )
            const percetageExpense = percentageExpenseData
            const percetageIncome = percentageIncomeData
            const findGraphic = findGraphicCategoriesIndex(
              response.data.cashFlowAnalysis[selectPeriod][0]
            )

            const categoriesExpense = createCategories(
              response.data.cashFlowAnalysis[selectPeriod][0][findGraphic]
                .graphicData,
              '#c42f2f',
              percetageExpense,
              'expense'
            )
            const categoriesIncome = createCategories(
              response.data.cashFlowAnalysis[selectPeriod][0][findGraphic]
                .graphicData,
              '#62b033',
              percetageIncome,
              'income'
            )
            setDataUpdatePayableExpensePizza(categoriesExpense)
            setDataUpdatePayableIncomePizza(categoriesIncome)
            setDataUpdatePayableMixed(
              response.data.graphicData[0]
                .filter((item: any) => item)
                .map(
                  (month: any) =>
                    month && {
                      date: month.date,
                      goal: month.realized_value_expense,
                      goalAccomplished: month.realized_value_income,
                      discount: month.predicted_value_income,
                      lastYearTarget: month.predicted_value_expense
                    }
                )
            )
          }
        } else {
          let year
          setPaidBills(
            createTableData(
              response.data.cashFlowAnalysis[selectPeriod][1],
              'paid',
              1
            )
          )
          setAccountsPayable(
            createTableData(
              response.data.cashFlowAnalysis[selectPeriod][0],
              undefined,
              0
            )
          )

          if (item) {
            year = minDate.split('-')[0]
            setSelectedMonts({
              ...selectedMonts,
              [item.situation]: {
                ...selectedMonts[item.situation],
                isActive: false
              }
            })
            historyMonts = {
              ...historyMonts,
              [item.situation]: {
                ...historyMonts[item.situation],
                isActive: false
              }
            }
          } else {
            year = new Date().getFullYear()
            setSelectedMonts({
              ...selectedMonts,
              0: {
                ...selectedMonts[0],
                isActive: false
              },
              1: {
                ...selectedMonts[1],
                isActive: false
              }
            })
            historyMonts[1].isActive = false
            historyMonts[1].isActive = false
          }

          if (historyMonts[1].isActive) {
            const monthFind = response.data.cashFlowAnalysis[
              selectPeriod
            ][1].find((month: any) => month?.numberIndex === selectMonth)

            setDatesRange({
              minDate: `${year}-${historyMonts[1].month}`,
              maxDate: `${year}-${historyMonts[1].month}`
            })
            if (monthFind) {
              const { percentageExpenseData, percentageIncomeData } =
                findBiggerPercetage(monthFind.categories)
              const percetageExpense = percentageExpenseData
              const percetageIncome = percentageIncomeData

              setDataUpdateMixed(
                response.data.graphicData
                  .filter((item: any) => item)
                  .map(
                    (month: any) =>
                      month && {
                        date: month.date,
                        goal: month.realized_value_expense,
                        goalAccomplished: month.realized_value_income,
                        discount: month.predicted_value_income,
                        lastYearTarget: month.predicted_value_expense
                      }
                  )
              )
              const categoriesExpense = createCategories(
                monthFind.categories,
                '#c42f2f',
                percetageExpense,
                'expense'
              )
              const categoriesIncome = createCategories(
                monthFind.categories,
                '#62b033',
                percetageIncome,
                'income'
              )
              setDataUpdateExpensePizza(categoriesExpense)
              setDataUpdateIncomePizza(categoriesIncome)
            }
          } else {
            setDatesRange({
              minDate,
              maxDate
            })
            const findGraphic = findGraphicCategoriesIndex(
              response.data.cashFlowAnalysis[selectPeriod][1]
            )
            const { percentageExpenseData, percentageIncomeData } =
              findBiggerPercetage(
                response.data.cashFlowAnalysis[selectPeriod][1][findGraphic]
                  .graphicData,
                false,
                true
              )
            const percetageExpense = percentageExpenseData
            const percetageIncome = percentageIncomeData

            const categoriesExpense = createCategories(
              response.data.cashFlowAnalysis[selectPeriod][1][findGraphic]
                .graphicData,
              '#c42f2f',
              percetageExpense,
              'expense'
            )
            const categoriesIncome = createCategories(
              response.data.cashFlowAnalysis[selectPeriod][1][findGraphic]
                .graphicData,
              '#62b033',
              percetageIncome,
              'income'
            )
            setDataUpdateExpensePizza(categoriesExpense)
            setDataUpdateIncomePizza(categoriesIncome)
            setDataUpdateMixed(
              response.data.graphicData
                .filter((item: any) => item)
                .map(
                  (month: any) =>
                    month && {
                      date: month.date,
                      goal: month.realized_value_expense,
                      goalAccomplished: month.realized_value_income,
                      discount: month.predicted_value_income,
                      lastYearTarget: month.predicted_value_expense
                    }
                )
            )
          }
          if (historyMonts[0].isActive) {
            setDatesRangePayable({
              minDate: `${year}-${historyMonts[0].month}`,
              maxDate: `${year}-${historyMonts[0].month}`
            })
            const monthFindAccountPayable = response.data.cashFlowAnalysis[
              selectPeriod
            ][0].find((month: any) => month?.numberIndex === selectMonth)
            if (monthFindAccountPayable) {
              const { percentageExpenseData, percentageIncomeData } =
                findBiggerPercetage(monthFindAccountPayable.categories)
              const percetageExpense = percentageExpenseData
              const percetageIncome = percentageIncomeData
              setDataUpdatePayableMixed(
                response.data.graphicData[0]
                  .filter((item: any) => item)
                  .map(
                    (month: any) =>
                      month && {
                        date: month.date,
                        goal: month.realized_value_expense,
                        goalAccomplished: month.realized_value_income,
                        discount: month.predicted_value_income,
                        lastYearTarget: month.predicted_value_expense
                      }
                  )
              )
              const categoriesExpense = createCategories(
                monthFindAccountPayable.categories,
                '#c42f2f',
                percetageExpense,
                'expense'
              )
              const categoriesIncome = createCategories(
                monthFindAccountPayable.categories,
                '#62b033',
                percetageIncome,
                'income'
              )
              setDataUpdatePayableExpensePizza(categoriesExpense)
              setDataUpdatePayableIncomePizza(categoriesIncome)
            }
          } else {
            setDatesRangePayable({
              minDate,
              maxDate
            })
            const { percentageExpenseData, percentageIncomeData } =
              findBiggerPercetage(
                response.data.cashFlowAnalysis[selectPeriod][0],
                true,
                true
              )
            const percetageExpense = percentageExpenseData
            const percetageIncome = percentageIncomeData
            const findGraphic = findGraphicCategoriesIndex(
              response.data.cashFlowAnalysis[selectPeriod][0]
            )

            const categoriesExpense = createCategories(
              response.data.cashFlowAnalysis[selectPeriod][0][findGraphic]
                .graphicData,
              '#c42f2f',
              percetageExpense,
              'expense'
            )
            const categoriesIncome = createCategories(
              response.data.cashFlowAnalysis[selectPeriod][0][findGraphic]
                .graphicData,
              '#62b033',
              percetageIncome,
              'income'
            )
            setDataUpdatePayableExpensePizza(categoriesExpense)
            setDataUpdatePayableIncomePizza(categoriesIncome)
            setDataUpdatePayableMixed(
              response.data.graphicData[0]
                .filter((item: any) => item)
                .map(
                  (month: any) =>
                    month && {
                      date: month.date,
                      goal: month.realized_value_expense,
                      goalAccomplished: month.realized_value_income,
                      discount: month.predicted_value_income,
                      lastYearTarget: month.predicted_value_expense
                    }
                )
            )
          }
        }
        setTimeout(() => {
          disableLoading()
        }, 300)
        return
      }
      setDataUpdateMixed(
        cashFlowAnalysisData.graphicData
          .filter((item: any) => item)
          .map(
            (month: any) =>
              month && {
                date: month.date,
                goal: month.realized_value_expense,
                goalAccomplished: month.realized_value_income,
                discount: month.predicted_value_income,
                lastYearTarget: month.predicted_value_expense
              }
          )
      )
      setDataUpdatePayableMixed(
        cashFlowAnalysisData.graphicData[0]
          .filter((item: any) => item)
          .map(
            (month: any) =>
              month && {
                date: month.date,
                goal: month.realized_value_expense,
                goalAccomplished: month.realized_value_income,
                discount: month.predicted_value_income,
                lastYearTarget: month.predicted_value_expense
              }
          )
      )

      if (selectMonth) {
        setPaidBills(
          createTableData(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1],
            'paid',
            1
          )
        )
        setAccountsPayable(
          createTableData(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0],
            undefined,
            0
          )
        )
        const year = new Date().getFullYear()

        if (selectedMonts[1].isActive) {
          const monthFind = cashFlowAnalysisData.cashFlowAnalysis[
            selectPeriod
          ][1].find((month: any) => month?.numberIndex === selectMonth)
          if (monthFind) {
            const { percentageExpenseData, percentageIncomeData } =
              findBiggerPercetage(monthFind.categories)
            const percetageExpense = percentageExpenseData
            const percetageIncome = percentageIncomeData

            setDatesRange({
              minDate: `${year}-${historyMonts[1].month}`,
              maxDate: `${year}-${historyMonts[1].month}`
            })

            const categoriesExpense = createCategories(
              monthFind.categories,
              '#c42f2f',
              percetageExpense,
              'expense'
            )
            const categoriesIncome = createCategories(
              monthFind.categories,
              '#62b033',
              percetageIncome,
              'income'
            )
            setDataUpdateExpensePizza(categoriesExpense)
            setDataUpdateIncomePizza(categoriesIncome)
          }
        } else {
          setDatesRange({
            minDate: `${year}-01`,
            maxDate: `${year}-12`
          })
          const { percentageExpenseData, percentageIncomeData } =
            findBiggerPercetage(
              cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1],
              true,
              true
            )
          const percetageExpense = percentageExpenseData
          const percetageIncome = percentageIncomeData
          const findGraphic = findGraphicCategoriesIndex(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1]
          )

          const categoriesExpense = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1][findGraphic]
              .graphicData,
            '#c42f2f',
            percetageExpense,
            'expense'
          )
          const categoriesIncome = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1][findGraphic]
              .graphicData,
            '#62b033',
            percetageIncome,
            'income'
          )
          setDataUpdateExpensePizza(categoriesExpense)
          setDataUpdateIncomePizza(categoriesIncome)
          setDataUpdateMixed(
            cashFlowAnalysisData.graphicData
              .filter((item: any) => item)
              .map(
                (month: any) =>
                  month && {
                    date: month.date,
                    goal: month.realized_value_expense,
                    goalAccomplished: month.realized_value_income,
                    discount: month.predicted_value_income,
                    lastYearTarget: month.predicted_value_expense
                  }
              )
          )
        }
        if (selectedMonts[0].isActive) {
          const monthFindAccountPayable = cashFlowAnalysisData.cashFlowAnalysis[
            selectPeriod
          ][0].find((month: any) => month?.numberIndex === selectMonth)
          if (monthFindAccountPayable) {
            setDatesRangePayable({
              minDate: `${year}-${historyMonts[0].month}`,
              maxDate: `${year}-${historyMonts[0].month}`
            })
            const { percentageExpenseData, percentageIncomeData } =
              findBiggerPercetage(monthFindAccountPayable.categories)
            const percetageExpense = percentageExpenseData
            const percetageIncome = percentageIncomeData

            const categoriesExpense = createCategories(
              monthFindAccountPayable.categories,
              '#c42f2f',
              percetageExpense,
              'expense'
            )
            const categoriesIncome = createCategories(
              monthFindAccountPayable.categories,
              '#62b033',
              percetageIncome,
              'income'
            )
            setDataUpdatePayableExpensePizza(categoriesExpense)
            setDataUpdatePayableIncomePizza(categoriesIncome)
          }
        } else {
          setDatesRangePayable({
            minDate: `${year}-01`,
            maxDate: `${year}-12`
          })
          const { percentageExpenseData, percentageIncomeData } =
            findBiggerPercetage(
              cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0],
              true,
              true
            )
          const percetageExpense = percentageExpenseData
          const percetageIncome = percentageIncomeData
          const findGraphic = findGraphicCategoriesIndex(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0]
          )

          const categoriesExpense = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0][findGraphic]
              .graphicData,
            '#c42f2f',
            percetageExpense,
            'expense'
          )
          const categoriesIncome = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0][findGraphic]
              .graphicData,
            '#62b033',
            percetageIncome,
            'income'
          )
          setDataUpdatePayableExpensePizza(categoriesExpense)
          setDataUpdatePayableIncomePizza(categoriesIncome)
          setDataUpdatePayableMixed(
            cashFlowAnalysisData.graphicData[0]
              .filter((item: any) => item)
              .map(
                (month: any) =>
                  month && {
                    date: month.date,
                    goal: month.realized_value_expense,
                    goalAccomplished: month.realized_value_income,
                    discount: month.predicted_value_income,
                    lastYearTarget: month.predicted_value_expense
                  }
              )
          )
        }
      } else {
        const year = new Date().getFullYear()
        if (item) {
          setSelectedMonts({
            ...selectedMonts,
            [item.situation]: {
              ...selectedMonts[item.situation],
              isActive: false
            }
          })
          historyMonts = {
            ...historyMonts,
            [item.situation]: {
              ...historyMonts[item.situation],
              isActive: false
            }
          }
        } else {
          setSelectedMonts({
            ...selectedMonts,
            0: {
              ...selectedMonts[0],
              isActive: false
            },
            1: {
              ...selectedMonts[1],
              isActive: false
            }
          })
          historyMonts[1].isActive = false
          historyMonts[0].isActive = false
        }

        if (historyMonts[1].isActive) {
          const monthFind = cashFlowAnalysisData.cashFlowAnalysis[
            selectPeriod
          ][1].find((month: any) => month?.numberIndex === selectMonth)
          if (monthFind) {
            setDatesRange({
              minDate: `${year}-${historyMonts[1].month}`,
              maxDate: `${year}-${historyMonts[1].month}`
            })
            const { percentageExpenseData, percentageIncomeData } =
              findBiggerPercetage(monthFind.categories)
            const percetageExpense = percentageExpenseData
            const percetageIncome = percentageIncomeData

            setDataUpdateMixed(
              cashFlowAnalysisData.graphicData
                .filter((item: any) => item)
                .map(
                  (month: any) =>
                    month && {
                      date: month.date,
                      goal: month.realized_value_expense,
                      goalAccomplished: month.realized_value_income,
                      discount: month.predicted_value_income,
                      lastYearTarget: month.predicted_value_expense
                    }
                )
            )
            const categoriesExpense = createCategories(
              monthFind.categories,
              '#c42f2f',
              percetageExpense,
              'expense'
            )
            const categoriesIncome = createCategories(
              monthFind.categories,
              '#62b033',
              percetageIncome,
              'income'
            )
            setDataUpdateExpensePizza(categoriesExpense)
            setDataUpdateIncomePizza(categoriesIncome)
          }
        } else {
          setDatesRange({
            minDate: `${year}-01`,
            maxDate: `${year}-12`
          })
          const findGraphic = findGraphicCategoriesIndex(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1]
          )

          const { percentageExpenseData, percentageIncomeData } =
            findBiggerPercetage(
              cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1][
                findGraphic
              ].graphicData,
              false,
              true
            )

          const percetageExpense = percentageExpenseData
          const percetageIncome = percentageIncomeData

          const categoriesExpense = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1][findGraphic]
              .graphicData,
            '#c42f2f',
            percetageExpense,
            'expense'
          )
          const categoriesIncome = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1][findGraphic]
              .graphicData,
            '#62b033',
            percetageIncome,
            'income'
          )
          setDataUpdateExpensePizza(categoriesExpense)
          setDataUpdateIncomePizza(categoriesIncome)
          setDataUpdateMixed(
            cashFlowAnalysisData.graphicData
              .filter((item: any) => item)
              .map(
                (month: any) =>
                  month && {
                    date: month.date,
                    goal: month.realized_value_expense,
                    goalAccomplished: month.realized_value_income,
                    discount: month.predicted_value_income,
                    lastYearTarget: month.predicted_value_expense
                  }
              )
          )
        }
        if (historyMonts[0].isActive) {
          const monthFindAccountPayable = cashFlowAnalysisData.cashFlowAnalysis[
            selectPeriod
          ][0].find((month: any) => month?.numberIndex === selectMonth)
          if (monthFindAccountPayable) {
            setDatesRangePayable({
              minDate: `${year}-${historyMonts[0].month}`,
              maxDate: `${year}-${historyMonts[0].month}`
            })
            const { percentageExpenseData, percentageIncomeData } =
              findBiggerPercetage(monthFindAccountPayable.categories)
            const percetageExpense = percentageExpenseData
            const percetageIncome = percentageIncomeData
            setDataUpdatePayableMixed(
              cashFlowAnalysisData.graphicData[0]
                .filter((item: any) => item)
                .map(
                  (month: any) =>
                    month && {
                      date: month.date,
                      goal: month.realized_value_expense,
                      goalAccomplished: month.realized_value_income,
                      discount: month.predicted_value_income,
                      lastYearTarget: month.predicted_value_expense
                    }
                )
            )
            const categoriesExpense = createCategories(
              monthFindAccountPayable.categories,
              '#c42f2f',
              percetageExpense,
              'expense'
            )
            const categoriesIncome = createCategories(
              monthFindAccountPayable.categories,
              '#62b033',
              percetageIncome,
              'income'
            )
            setDataUpdatePayableExpensePizza(categoriesExpense)
            setDataUpdatePayableIncomePizza(categoriesIncome)
          }
        } else {
          setDatesRangePayable({
            minDate: `${year}-01`,
            maxDate: `${year}-12`
          })
          const findGraphic = findGraphicCategoriesIndex(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0]
          )

          const { percentageExpenseData, percentageIncomeData } =
            findBiggerPercetage(
              cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0][
                findGraphic
              ].graphicData,
              false,
              true
            )
          const percetageExpense = percentageExpenseData
          const percetageIncome = percentageIncomeData

          const categoriesExpense = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0][findGraphic]
              .graphicData,
            '#c42f2f',
            percetageExpense,
            'expense'
          )
          const categoriesIncome = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0][findGraphic]
              .graphicData,
            '#62b033',
            percetageIncome,
            'income'
          )
          setDataUpdatePayableExpensePizza(categoriesExpense)
          setDataUpdatePayableIncomePizza(categoriesIncome)
          setDataUpdatePayableMixed(
            cashFlowAnalysisData.graphicData[0]
              .filter((item: any) => item)
              .map(
                (month: any) =>
                  month && {
                    date: month.date,
                    goal: month.realized_value_expense,
                    goalAccomplished: month.realized_value_income,
                    discount: month.predicted_value_income,
                    lastYearTarget: month.predicted_value_expense
                  }
              )
          )
        }

        setPaidBills(
          createTableData(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1],
            'paid',
            1
          )
        )
        setAccountsPayable(
          createTableData(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0],
            undefined,
            0
          )
        )
      }
      setTimeout(() => {
        disableLoading()
      }, 300)
    },
    [
      activeLoading,
      cashFlowAnalysisData,
      createTableData,
      disableLoading,
      findBiggerPercetage,
      selectedDate,
      selectedMonthId,
      selectedMonts,
      selectedSituation
    ]
  )

  const handleSetDataPayable = useCallback(
    async (
      date: string,
      month_id?: number,
      item?: any,
      changePeriod?: boolean,
      historySelectedMonths?: any
    ) => {
      setSelectedSituation(0)
      const selectDate = date || selectedDate
      const selectMonth = month_id || selectedMonthId
      setSelectedDate(date || selectedDate)
      setSelectedMonthId(month_id || selectedMonthId)
      let historyMonts: any = { ...selectedMonts }
      activeLoading()
      let selectPeriod: any
      if (
        selectDate === 'Mes' ||
        selectDate === 'Mês' ||
        selectDate.includes('period')
      ) {
        selectPeriod = 'month'
      }
      if (selectDate === 'Ano') {
        selectPeriod = 'year'
      }
      if (selectDate === 'Hoje') {
        selectPeriod = 'day'
      }
      if (selectDate === 'Semana') {
        selectPeriod = 'week'
      }
      if (selectDate.includes('period')) {
        const { minDate, maxDate } = JSON.parse(date || selectDate)
        const response: any = { ...requestCashFlowAnalysisDataRef.current }
        const year = minDate.split('-')[0]
        const { percentageExpenseData, percentageIncomeData } =
          findBiggerPercetage(
            response.data.cashFlowAnalysis[selectPeriod][0],
            true,
            true
          )
        setAccountsPayable(
          createTableData(
            response.data.cashFlowAnalysis[selectPeriod][0],
            undefined,
            0
          )
        )
        if (selectMonth) {
          if (item) {
            setSelectedMonts({
              ...selectedMonts,
              [item.situation]: {
                ...selectedMonts[item.situation],
                isActive: true
              }
            })
            historyMonts = {
              ...historyMonts,
              [item.situation]: {
                ...historyMonts[item.situation],
                isActive: true
              }
            }
          } else {
            setSelectedMonts({
              ...selectedMonts,
              0: {
                ...selectedMonts[0],
                isActive: false
              },
              1: {
                ...selectedMonts[1],
                isActive: false
              }
            })
            historyMonts[1].isActive = false
            historyMonts[0].isActive = false
          }

          if (historyMonts[0].isActive) {
            const monthFindAccountPayable = response.data.cashFlowAnalysis[
              selectPeriod
            ][0].find((month: any) => month?.numberIndex === selectMonth)

            if (monthFindAccountPayable) {
              historyMonts = {
                ...historyMonts,
                0: {
                  month: monthFindAccountPayable.numberMonth,
                  monthIndex: selectMonth,
                  isActive: true
                }
              }
              setDatesRangePayable({
                minDate: `${year}-${historyMonts[0].month}`,
                maxDate: `${year}-${historyMonts[0].month}`
              })
              setSelectedMonts({
                ...selectedMonts,
                0: {
                  isActive: true,
                  month: monthFindAccountPayable.numberMonth,
                  monthIndex: selectMonth
                }
              })
              const { percentageExpenseData, percentageIncomeData } =
                findBiggerPercetage(monthFindAccountPayable.categories)
              const percetageExpense = percentageExpenseData
              const percetageIncome = percentageIncomeData
              setDataUpdatePayableMixed(
                response.data.graphicData[0]
                  .filter((item: any) => item)
                  .map(
                    (month: any) =>
                      month && {
                        date: month.date,
                        goal: month.realized_value_expense,
                        goalAccomplished: month.realized_value_income,
                        discount: month.predicted_value_income,
                        lastYearTarget: month.predicted_value_expense
                      }
                  )
              )
              const categoriesExpense = createCategories(
                monthFindAccountPayable.categories,
                '#c42f2f',
                percetageExpense,
                'expense'
              )
              const categoriesIncome = createCategories(
                monthFindAccountPayable.categories,
                '#62b033',
                percetageIncome,
                'income'
              )
              setDataUpdatePayableExpensePizza(categoriesExpense)
              setDataUpdatePayableIncomePizza(categoriesIncome)
            } else {
              setSelectedMonts({
                ...selectedMonts,
                0: {
                  ...selectedMonts[0],
                  isActive: false
                }
              })
              setDatesRangePayable({
                minDate,
                maxDate
              })
              const { percentageExpenseData, percentageIncomeData } =
                findBiggerPercetage(
                  response.data.cashFlowAnalysis[selectPeriod][0],
                  true,
                  true
                )
              const percetageExpense = percentageExpenseData
              const percetageIncome = percentageIncomeData
              const findGraphic = findGraphicCategoriesIndex(
                response.data.cashFlowAnalysis[selectPeriod][0]
              )

              const categoriesExpense = createCategories(
                response.data.cashFlowAnalysis[selectPeriod][0][findGraphic]
                  .graphicData,
                '#c42f2f',
                percetageExpense,
                'expense'
              )
              const categoriesIncome = createCategories(
                response.data.cashFlowAnalysis[selectPeriod][0][findGraphic]
                  .graphicData,
                '#62b033',
                percetageIncome,
                'income'
              )
              setDataUpdatePayableExpensePizza(categoriesExpense)
              setDataUpdatePayableIncomePizza(categoriesIncome)
              setDataUpdatePayableMixed(
                response.data.graphicData[0]
                  .filter((item: any) => item)
                  .map(
                    (month: any) =>
                      month && {
                        date: month.date,
                        goal: month.realized_value_expense,
                        goalAccomplished: month.realized_value_income,
                        discount: month.predicted_value_income,
                        lastYearTarget: month.predicted_value_expense
                      }
                  )
              )
            }
          } else {
            setDatesRangePayable({
              minDate,
              maxDate
            })
            const { percentageExpenseData, percentageIncomeData } =
              findBiggerPercetage(
                response.data.cashFlowAnalysis[selectPeriod][0],
                true,
                true
              )
            const selecteMonthString = selectMonth.toString()
            const selecteMonthNumber = Number(
              selecteMonthString.slice(2, selecteMonthString.length)
            )
            setSelectedMonts({
              ...selectedMonts,
              0: {
                isActive: true,
                month: selecteMonthNumber
              }
            })
            const percetageExpense = percentageExpenseData
            const percetageIncome = percentageIncomeData
            const findGraphic = findGraphicCategoriesIndex(
              response.data.cashFlowAnalysis[selectPeriod][0]
            )

            const categoriesExpense = createCategories(
              response.data.cashFlowAnalysis[selectPeriod][0][findGraphic]
                .graphicData,
              '#c42f2f',
              percetageExpense,
              'expense'
            )
            const categoriesIncome = createCategories(
              response.data.cashFlowAnalysis[selectPeriod][0][findGraphic]
                .graphicData,
              '#62b033',
              percetageIncome,
              'income'
            )
            setDataUpdatePayableExpensePizza(categoriesExpense)
            setDataUpdatePayableIncomePizza(categoriesIncome)
            setDataUpdatePayableMixed(
              response.data.graphicData[0]
                .filter((item: any) => item)
                .map(
                  (month: any) =>
                    month && {
                      date: month.date,
                      goal: month.realized_value_expense,
                      goalAccomplished: month.realized_value_income,
                      discount: month.predicted_value_income,
                      lastYearTarget: month.predicted_value_expense
                    }
                )
            )
          }
          disableLoading()
        } else {
          setDatesRangePayable({
            minDate,
            maxDate
          })

          setAccountsPayable(
            createTableData(
              response.data.cashFlowAnalysis[selectPeriod][0],
              undefined,
              0
            )
          )
          setDataUpdatePayableMixed(
            response.data.graphicData[0]
              .filter((item: any) => item)
              .map(
                (month: any) =>
                  month && {
                    date: month.date,
                    goal: month.realized_value_expense,
                    goalAccomplished: month.realized_value_income,
                    discount: month.predicted_value_income,
                    lastYearTarget: month.predicted_value_expense
                  }
              )
          )
          const findGraphic = findGraphicCategoriesIndex(
            response.data.cashFlowAnalysis[selectPeriod][0]
          )
          const categoriesExpense = createCategories(
            response.data.cashFlowAnalysis[selectPeriod][0][findGraphic]
              .graphicData,
            '#c42f2f',
            percentageExpenseData,
            'expense'
          )
          const categoriesIncome = createCategories(
            response.data.cashFlowAnalysis[selectPeriod][0][findGraphic]
              .graphicData,
            '#62b033',
            percentageIncomeData,
            'income'
          )
          setDataUpdatePayableExpensePizza(categoriesExpense)
          setDataUpdatePayableIncomePizza(categoriesIncome)
        }
        return
      }

      if (selectMonth) {
        let percentageExpense = 0
        let percentageIncome = 0
        const year = new Date().getFullYear()
        let monthFindAccountPayable
        if (changePeriod) {
          if (historySelectedMonths[0].isActive) {
            monthFindAccountPayable = cashFlowAnalysisData.cashFlowAnalysis[
              selectPeriod
            ][0].find(
              (month: any) =>
                month?.numberMonth === historySelectedMonths[0].month
            )
          }
        } else {
          monthFindAccountPayable = cashFlowAnalysisData.cashFlowAnalysis[
            selectPeriod
          ][0].find((month: any) => month?.numberIndex === selectMonth)
        }
        if (monthFindAccountPayable) {
          setSelectedMonts({
            ...selectedMonts,
            0: {
              isActive: true,
              monthIndex: selectMonth,
              month: monthFindAccountPayable.numberMonth
            }
          })
          setDatesRangePayable({
            minDate: `${year}-${monthFindAccountPayable.numberMonth}`,
            maxDate: `${year}-${monthFindAccountPayable.numberMonth}`
          })
          const { percentageExpenseData, percentageIncomeData } =
            findBiggerPercetage(monthFindAccountPayable.categories)
          percentageExpense = percentageExpenseData
          percentageIncome = percentageIncomeData
          const categoriesExpense = createCategories(
            monthFindAccountPayable.categories,
            '#c42f2f',
            percentageExpense,
            'expense'
          )
          const categoriesIncome = createCategories(
            monthFindAccountPayable.categories,
            '#62b033',
            percentageIncome,
            'income'
          )
          setDataUpdatePayableExpensePizza(categoriesExpense)
          setDataUpdatePayableIncomePizza(categoriesIncome)
        } else {
          setSelectedMonts({
            ...selectedMonts,
            0: {
              ...selectedMonts[0],
              isActive: false
            }
          })
          const {
            percentageExpenseData: percentagePayableExpense,
            percentageIncomeData: percentagePayableIncome
          } = findBiggerPercetage(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0],
            true,
            true
          )
          const findPayableGraphic = findGraphicCategoriesIndex(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0]
          )
          setDatesRangePayable({
            minDate: `${year}-01`,
            maxDate: `${year}-12`
          })
          const categoriesPayableExpense = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0][
              findPayableGraphic
            ].graphicData,
            '#c42f2f',
            percentagePayableExpense,
            'expense'
          )
          const categoriesPayableIncome = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0][
              findPayableGraphic
            ].graphicData,
            '#62b033',
            percentagePayableIncome,
            'income'
          )
          setDataUpdatePayableExpensePizza(categoriesPayableExpense)
          setDataUpdatePayableIncomePizza(categoriesPayableIncome)
        }
      } else {
        const year = new Date().getFullYear()
        setDatesRangePayable({
          minDate: `${year}-01`,
          maxDate: `${year}-12`
        })
        setDataUpdateMixed(
          cashFlowAnalysisData.graphicData[0]
            .filter((item: any) => item)
            .map(
              (month: any) =>
                month && {
                  date: month.date,
                  goal: month.realized_value_expense,
                  goalAccomplished: month.realized_value_income,
                  discount: month.predicted_value_income,
                  lastYearTarget: month.predicted_value_expense
                }
            )
        )
        const findGraphic = findGraphicCategoriesIndex(
          cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0]
        )
        const { percentageExpenseData, percentageIncomeData } =
          findBiggerPercetage(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0][findGraphic]
              .graphicData,
            false,
            true
          )
        const percetageExpense = percentageExpenseData
        const percetageIncome = percentageIncomeData
        const categoriesExpense = createCategories(
          cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0][findGraphic]
            .graphicData,
          '#c42f2f',
          percetageExpense,
          'expense'
        )

        const categoriesIncome = createCategories(
          cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0][findGraphic]
            .graphicData,
          '#62b033',
          percetageIncome,
          'income'
        )

        setDataUpdatePayableExpensePizza(categoriesExpense)
        setDataUpdatePayableIncomePizza(categoriesIncome)
      }
      disableLoading()
    },
    [
      activeLoading,
      cashFlowAnalysisData.cashFlowAnalysis,
      cashFlowAnalysisData.graphicData,
      createTableData,
      disableLoading,
      findBiggerPercetage,
      selectedDate,
      selectedMonthId,
      selectedMonts
    ]
  )

  const handleSetDate = useCallback(
    async (
      date: string,
      month_id?: number,
      item?: any,
      changePeriod?: boolean
    ) => {
      const selectDate = date || selectedDate
      const selectMonth = month_id || selectedMonthId
      let historyMonts: any = { ...selectedMonts }
      setSelectedDate(date || selectedDate)
      setSelectedMonthId(month_id || selectedMonthId)
      setSelectedSituation(1)
      activeLoading()
      let selectPeriod: any
      if (
        selectDate === 'Mes' ||
        selectDate === 'Mês' ||
        selectDate.includes('period')
      ) {
        selectPeriod = 'month'
      }
      if (selectDate === 'Ano') {
        selectPeriod = 'year'
      }
      if (selectDate === 'Hoje') {
        selectPeriod = 'day'
      }
      if (selectDate === 'Semana') {
        selectPeriod = 'week'
      }
      if (selectDate.includes('period')) {
        const { minDate, maxDate } = JSON.parse(date || selectDate)
        const year = minDate.split('-')[0]
        const response = await api.get(
          `/financial/cashflowanalysis/listPerPeriod/${date || selectDate}`
        )
        requestCashFlowAnalysisDataRef.current = response
        const { percentageExpenseData, percentageIncomeData } =
          findBiggerPercetage(
            response.data.cashFlowAnalysis[selectPeriod][1],
            true,
            true
          )
        const percetageExpense = percentageExpenseData
        const percetageIncome = percentageIncomeData

        setPaidBills(
          createTableData(
            response.data.cashFlowAnalysis[selectPeriod][1],
            'paid',
            1
          )
        )
        if (selectMonth) {
          if (item) {
            setSelectedMonts({
              ...selectedMonts,
              [item.situation]: {
                ...selectedMonts[item.situation],
                isActive: true
              }
            })
            historyMonts = {
              ...historyMonts,
              [item.situation]: {
                ...historyMonts[item.situation],
                isActive: true
              }
            }
          } else {
            setSelectedMonts({
              ...selectedMonts,
              0: {
                ...selectedMonts[0],
                isActive: false
              },
              1: {
                ...selectedMonts[1],
                isActive: false
              }
            })
            historyMonts[1].isActive = false
            historyMonts[0].isActive = false
          }

          if (historyMonts[1].isActive) {
            const monthFindAccount = response.data.cashFlowAnalysis[
              selectPeriod
            ][1].find((month: any) => {
              return month?.numberIndex === selectMonth
            })

            if (monthFindAccount) {
              historyMonts = {
                ...historyMonts,
                1: {
                  month: monthFindAccount.numberMonth,
                  monthIndex: selectMonth,
                  isActive: true
                }
              }
              setDatesRange({
                minDate: `${year}-${historyMonts[1].month}`,
                maxDate: `${year}-${historyMonts[1].month}`
              })
              setSelectedMonts({
                ...selectedMonts,
                1: {
                  isActive: true,
                  month: monthFindAccount.numberMonth,
                  monthIndex: selectMonth
                }
              })
              const { percentageExpenseData, percentageIncomeData } =
                findBiggerPercetage(monthFindAccount.categories)
              const percetageExpense = percentageExpenseData
              const percetageIncome = percentageIncomeData
              setDataUpdateMixed(
                response.data.graphicData
                  .filter((item: any) => item)
                  .map(
                    (month: any) =>
                      month && {
                        date: month.date,
                        goal: month.realized_value_expense,
                        goalAccomplished: month.realized_value_income,
                        discount: month.predicted_value_income,
                        lastYearTarget: month.predicted_value_expense
                      }
                  )
              )
              const categoriesExpense = createCategories(
                monthFindAccount.categories,
                '#c42f2f',
                percetageExpense,
                'expense'
              )
              const categoriesIncome = createCategories(
                monthFindAccount.categories,
                '#62b033',
                percetageIncome,
                'income'
              )
              setDataUpdateExpensePizza(categoriesExpense)
              setDataUpdateIncomePizza(categoriesIncome)
            } else {
              setSelectedMonts({
                ...selectedMonts,
                1: {
                  ...selectedMonts[1],
                  isActive: false
                }
              })
              setDatesRange({
                minDate,
                maxDate
              })
              const { percentageExpenseData, percentageIncomeData } =
                findBiggerPercetage(
                  response.data.cashFlowAnalysis[selectPeriod][1],
                  true,
                  true
                )
              const percetageExpense = percentageExpenseData
              const percetageIncome = percentageIncomeData
              const findGraphic = findGraphicCategoriesIndex(
                response.data.cashFlowAnalysis[selectPeriod][1]
              )

              const categoriesExpense = createCategories(
                response.data.cashFlowAnalysis[selectPeriod][1][findGraphic]
                  .graphicData,
                '#c42f2f',
                percetageExpense,
                'expense'
              )
              const categoriesIncome = createCategories(
                response.data.cashFlowAnalysis[selectPeriod][1][findGraphic]
                  .graphicData,
                '#62b033',
                percetageIncome,
                'income'
              )
              setDataUpdateExpensePizza(categoriesExpense)
              setDataUpdateIncomePizza(categoriesIncome)
              setDataUpdateMixed(
                response.data.graphicData
                  .filter((item: any) => item)
                  .map(
                    (month: any) =>
                      month && {
                        date: month.date,
                        goal: month.realized_value_expense,
                        goalAccomplished: month.realized_value_income,
                        discount: month.predicted_value_income,
                        lastYearTarget: month.predicted_value_expense
                      }
                  )
              )
            }
          } else {
            await handleSetDataPayable(date, month_id)
            setDatesRange({
              minDate,
              maxDate
            })
            const { percentageExpenseData, percentageIncomeData } =
              findBiggerPercetage(
                response.data.cashFlowAnalysis[selectPeriod][1],
                true,
                true
              )
            const selectMonthString = selectMonth.toString()
            const numberMonth = Number(
              selectMonthString.slice(2, selectMonthString.length)
            )
            setSelectedMonts({
              ...selectedMonts,
              1: {
                isActive: true,
                month: numberMonth,
                monthIndex: selectMonth
              }
            })
            const percetageExpense = percentageExpenseData
            const percetageIncome = percentageIncomeData
            const findGraphic = findGraphicCategoriesIndex(
              response.data.cashFlowAnalysis[selectPeriod][1]
            )

            const categoriesExpense = createCategories(
              response.data.cashFlowAnalysis[selectPeriod][1][findGraphic]
                .graphicData,
              '#c42f2f',
              percetageExpense,
              'expense'
            )
            const categoriesIncome = createCategories(
              response.data.cashFlowAnalysis[selectPeriod][1][findGraphic]
                .graphicData,
              '#62b033',
              percetageIncome,
              'income'
            )
            setDataUpdateExpensePizza(categoriesExpense)
            setDataUpdateIncomePizza(categoriesIncome)
            setDataUpdateMixed(
              response.data.graphicData
                .filter((item: any) => item)
                .map(
                  (month: any) =>
                    month && {
                      date: month.date,
                      goal: month.realized_value_expense,
                      goalAccomplished: month.realized_value_income,
                      discount: month.predicted_value_income,
                      lastYearTarget: month.predicted_value_expense
                    }
                )
            )
          }
        } else {
          await handleSetDataPayable(date, month_id)
          setDatesRange({
            minDate,
            maxDate
          })
          setPaidBills(
            createTableData(
              response.data.cashFlowAnalysis[selectPeriod][1],
              'paid',
              1
            )
          )

          setDataUpdateMixed(
            response.data.graphicData
              .filter((item: any) => item)
              .map(
                (month: any) =>
                  month && {
                    date: month.date,
                    goal: month.realized_value_expense,
                    goalAccomplished: month.realized_value_income,
                    discount: month.predicted_value_income,
                    lastYearTarget: month.predicted_value_expense
                  }
              )
          )
          const findGraphic = findGraphicCategoriesIndex(
            response.data.cashFlowAnalysis[selectPeriod][1]
          )
          const categoriesExpense = createCategories(
            response.data.cashFlowAnalysis[selectPeriod][1][findGraphic]
              .graphicData,
            '#c42f2f',
            percetageExpense,
            'expense'
          )
          const categoriesIncome = createCategories(
            response.data.cashFlowAnalysis[selectPeriod][1][findGraphic]
              .graphicData,
            '#62b033',
            percetageIncome,
            'income'
          )
          setDataUpdateExpensePizza(categoriesExpense)
          setDataUpdateIncomePizza(categoriesIncome)
        }

        setTimeout(() => {
          disableLoading()
        }, 300)
        return
      }
      setPaidBills(
        createTableData(
          cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1],
          'paid',
          1
        )
      )

      setAccountsPayable(
        createTableData(
          cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0],
          undefined,
          0
        )
      )

      if (selectMonth) {
        let percentageExpense = 0
        let percentageIncome = 0
        const year = new Date().getFullYear()
        let monthFind
        if (changePeriod) {
          historyMonts[0].isActive = true
          await handleSetDataPayable(
            date,
            month_id,
            undefined,
            true,
            historyMonts
          )
          if (historyMonts[1].isActive) {
            monthFind = cashFlowAnalysisData.cashFlowAnalysis[
              selectPeriod
            ][1].find((month: any) => {
              return month?.numberIndex === historyMonts[1].monthIndex
            })
          }
        } else {
          monthFind = cashFlowAnalysisData.cashFlowAnalysis[
            selectPeriod
          ][1].find((month: any) => {
            return month?.numberIndex === selectMonth
          })
        }
        if (monthFind) {
          setSelectedMonts({
            ...selectedMonts,
            1: {
              isActive: true,
              month: monthFind?.numberMonth,
              monthIndex: selectMonth
            }
          })
          setDatesRange({
            minDate: `${year}-${monthFind.numberMonth}`,
            maxDate: `${year}-${monthFind.numberMonth}`
          })
          const { percentageExpenseData, percentageIncomeData } =
            findBiggerPercetage(monthFind.categories)
          percentageExpense = percentageExpenseData
          percentageIncome = percentageIncomeData
          const categoriesExpense = createCategories(
            monthFind.categories,
            '#c42f2f',
            percentageExpense,
            'expense'
          )
          const categoriesIncome = createCategories(
            monthFind.categories,
            '#62b033',
            percentageIncome,
            'income'
          )

          setDataUpdateExpensePizza(categoriesExpense)
          setDataUpdateIncomePizza(categoriesIncome)
        } else {
          setSelectedMonts({
            ...selectedMonts,
            1: {
              ...selectedMonts[1],
              isActive: false
            }
          })
          const {
            percentageExpenseData: percentagePayableExpense,
            percentageIncomeData: percentagePayableIncome
          } = findBiggerPercetage(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1],
            true,
            true
          )
          const findGraphic = findGraphicCategoriesIndex(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1]
          )
          const categoriesPayableExpense = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1][findGraphic]
              .graphicData,
            '#c42f2f',
            percentagePayableExpense,
            'expense'
          )
          const categoriesPayableIncome = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1][findGraphic]
              .graphicData,
            '#62b033',
            percentagePayableIncome,
            'income'
          )
          setDataUpdateExpensePizza(categoriesPayableExpense)
          setDataUpdateIncomePizza(categoriesPayableIncome)
          setDatesRange({
            minDate: `${year}-01`,
            maxDate: `${year}-12`
          })
          if (changePeriod) {
            handleSetDataPayable(date, month_id)
          }
        }
      } else {
        const year = new Date().getFullYear()

        if (historyMonts[1].isActive) {
          const monthFind = cashFlowAnalysisData.cashFlowAnalysis[
            selectPeriod
          ][1].find(
            (month: any) => month?.numberIndex === historyMonts[1].month
          )

          setDatesRange({
            minDate: `${year}-${historyMonts[1].month}`,
            maxDate: `${year}-${historyMonts[1].month}`
          })
          if (monthFind) {
            const { percentageExpenseData, percentageIncomeData } =
              findBiggerPercetage(monthFind.categories)
            const percetageExpense = percentageExpenseData
            const percetageIncome = percentageIncomeData

            setDataUpdateMixed(
              cashFlowAnalysisData.graphicData
                .filter((item: any) => item)
                .map(
                  (month: any) =>
                    month && {
                      date: month.date,
                      goal: month.realized_value_expense,
                      goalAccomplished: month.realized_value_income,
                      discount: month.predicted_value_income,
                      lastYearTarget: month.predicted_value_expense
                    }
                )
            )
            const categoriesExpense = createCategories(
              monthFind.categories,
              '#c42f2f',
              percetageExpense,
              'expense'
            )
            const categoriesIncome = createCategories(
              monthFind.categories,
              '#62b033',
              percetageIncome,
              'income'
            )
            setDataUpdateExpensePizza(categoriesExpense)
            setDataUpdateIncomePizza(categoriesIncome)
          } else {
            setSelectedMonts({
              ...selectedMonts,
              1: {
                ...selectedMonts[1],
                isActive: false
              }
            })
            const {
              percentageExpenseData: percentagePayableExpense,
              percentageIncomeData: percentagePayableIncome
            } = findBiggerPercetage(
              cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1],
              true,
              true
            )
            const findGraphic = findGraphicCategoriesIndex(
              cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1]
            )
            const categoriesPayableExpense = createCategories(
              cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1][
                findGraphic
              ].graphicData,
              '#c42f2f',
              percentagePayableExpense,
              'expense'
            )
            const categoriesPayableIncome = createCategories(
              cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1][
                findGraphic
              ].graphicData,
              '#62b033',
              percentagePayableIncome,
              'income'
            )
            setDataUpdateExpensePizza(categoriesPayableExpense)
            setDataUpdateIncomePizza(categoriesPayableIncome)
            setDatesRange({
              minDate: `${year}-01`,
              maxDate: `${year}-12`
            })
          }
        } else {
          setDatesRange({
            minDate: `${year}-01`,
            maxDate: `${year}-12`
          })
          const findGraphic = findGraphicCategoriesIndex(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1]
          )
          const { percentageExpenseData, percentageIncomeData } =
            findBiggerPercetage(
              cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1][
                findGraphic
              ].graphicData,
              false,
              true
            )
          const percetageExpense = percentageExpenseData
          const percetageIncome = percentageIncomeData

          const categoriesExpense = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1][findGraphic]
              .graphicData,
            '#c42f2f',
            percetageExpense,
            'expense'
          )
          const categoriesIncome = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][1][findGraphic]
              .graphicData,
            '#62b033',
            percetageIncome,
            'income'
          )
          setDataUpdateExpensePizza(categoriesExpense)
          setDataUpdateIncomePizza(categoriesIncome)
          setDataUpdateMixed(
            cashFlowAnalysisData.graphicData
              .filter((item: any) => item)
              .map(
                (month: any) =>
                  month && {
                    date: month.date,
                    goal: month.realized_value_expense,
                    goalAccomplished: month.realized_value_income,
                    discount: month.predicted_value_income,
                    lastYearTarget: month.predicted_value_expense
                  }
              )
          )
        }
        if (historyMonts[0].isActive) {
          setDatesRangePayable({
            minDate: `${year}-${historyMonts[0].month}`,
            maxDate: `${year}-${historyMonts[0].month}`
          })
          const monthFindAccountPayable = cashFlowAnalysisData.cashFlowAnalysis[
            selectPeriod
          ][0].find(
            (month: any) => month?.numberMonth === historyMonts[0].month
          )
          if (monthFindAccountPayable) {
            const { percentageExpenseData, percentageIncomeData } =
              findBiggerPercetage(monthFindAccountPayable.categories)
            const percetageExpense = percentageExpenseData
            const percetageIncome = percentageIncomeData
            setDataUpdatePayableMixed(
              cashFlowAnalysisData.graphicData[0]
                .filter((item: any) => item)
                .map(
                  (month: any) =>
                    month && {
                      date: month.date,
                      goal: month.realized_value_expense,
                      goalAccomplished: month.realized_value_income,
                      discount: month.predicted_value_income,
                      lastYearTarget: month.predicted_value_expense
                    }
                )
            )
            const categoriesExpense = createCategories(
              monthFindAccountPayable.categories,
              '#c42f2f',
              percetageExpense,
              'expense'
            )
            const categoriesIncome = createCategories(
              monthFindAccountPayable.categories,
              '#62b033',
              percetageIncome,
              'income'
            )
            setDataUpdatePayableExpensePizza(categoriesExpense)
            setDataUpdatePayableIncomePizza(categoriesIncome)
          } else {
            setSelectedMonts({
              ...selectedMonts,
              0: {
                ...selectedMonts[0],
                isActive: false
              }
            })
            const {
              percentageExpenseData: percentagePayableExpense,
              percentageIncomeData: percentagePayableIncome
            } = findBiggerPercetage(
              cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0],
              true,
              true
            )
            const findGraphic = findGraphicCategoriesIndex(
              cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0]
            )
            const categoriesPayableExpense = createCategories(
              cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0][
                findGraphic
              ].graphicData,
              '#c42f2f',
              percentagePayableExpense,
              'expense'
            )
            const categoriesPayableIncome = createCategories(
              cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0][
                findGraphic
              ].graphicData,
              '#62b033',
              percentagePayableIncome,
              'income'
            )
            setDataUpdateExpensePizza(categoriesPayableExpense)
            setDataUpdateIncomePizza(categoriesPayableIncome)
            setDatesRange({
              minDate: `${year}-01`,
              maxDate: `${year}-12`
            })
          }
        } else {
          setDatesRangePayable({
            minDate: `${year}-01`,
            maxDate: `${year}-12`
          })
          const findGraphic = findGraphicCategoriesIndex(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0]
          )

          const { percentageExpenseData, percentageIncomeData } =
            findBiggerPercetage(
              cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0][
                findGraphic
              ].graphicData,
              false,
              true
            )
          const percetageExpense = percentageExpenseData
          const percetageIncome = percentageIncomeData

          const categoriesExpense = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0][findGraphic]
              .graphicData,
            '#c42f2f',
            percetageExpense,
            'expense'
          )
          const categoriesIncome = createCategories(
            cashFlowAnalysisData.cashFlowAnalysis[selectPeriod][0][findGraphic]
              .graphicData,
            '#62b033',
            percetageIncome,
            'income'
          )
          setDataUpdatePayableExpensePizza(categoriesExpense)
          setDataUpdatePayableIncomePizza(categoriesIncome)
          setDataUpdatePayableMixed(
            cashFlowAnalysisData.graphicData[0]
              .filter((item: any) => item)
              .map(
                (month: any) =>
                  month && {
                    date: month.date,
                    goal: month.realized_value_expense,
                    goalAccomplished: month.realized_value_income,
                    discount: month.predicted_value_income,
                    lastYearTarget: month.predicted_value_expense
                  }
              )
          )
        }
      }
      disableLoading()
    },
    [
      activeLoading,
      cashFlowAnalysisData,
      createTableData,
      disableLoading,
      findBiggerPercetage,
      handleSetDataPayable,
      selectedDate,
      selectedMonthId,
      selectedMonts
    ]
  )

  useEffect(() => {
    if (!isNaN(selectStartIndexPeriod[selectPeriod]?.index)) {
      setSelectedDate(selectStartIndexPeriod[selectPeriod]?.period)
    }
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container
      pageTitle={'Analise de Fluxo de Caixa'}
      portletTitle={''}
      breadcrumb={[
        {
          name: 'Início',
          to: '/'
        },
        {
          name: 'Analise de Fluxo de Caixa'
        }
      ]}
      tools={[]}
    >
      <ControlPanel>
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <HeaderPortlet
            handleSetDate={(date, month) =>
              handleSetDate(date, month, undefined, true)
            }
            getUserData={() => {}}
            activeMembersData={[]}
            setInitialData={setInitialData}
            actions={['Hoje', 'Semana', 'Mês', 'Ano', 'Período']}
            mainTitle=""
            startIndexAction={
              !isNaN(selectStartIndexPeriod[selectPeriod]?.index)
                ? selectStartIndexPeriod[selectPeriod]?.index
                : 3
            }
          />
        </div>
        <div className="row">
          <TablePortlet
            maintitle="Quadro de Receita e Despesa"
            className="col-md-6"
            handleSetDate={handleSetDate}
            setInitialData={setInitialData}
            realData={paidBills}
            isHighlight={true}
            isHighlightOver={true}
            indexOnlySettings={[paidBills.length - 1]}
            lastChildStyle="boldGrey"
            stylesChild={{
              th: {
                classname: 'hasBottomBorder'
              },
              column1: {
                isOnly: true,
                onlyClassname: 'boldGrey'
              },
              column2: {
                isOnly: true,
                onlyClassname: 'boldGrey'
              },
              column3: {
                isOnly: true,
                onlyClassname: 'boldGrey'
              },
              column4: {
                isOnly: true,
                onlyClassname: 'boldGrey'
              },
              column6: {
                isOnly: true,
                onlyClassname: 'boldGrey'
              },
              column5: {
                classname: 'boldGrey',
                isHighlight: true,
                overAndUnder: true,
                highlightOverNumber: 0
              },
              highlightColor: '#c42f2f',
              highlightOverColor: '#62b033',
              highlightUnderColor: '#c42f2f'
            }}
            hasDropdown={false}
            initialData={[]}
            actions={[]}
            tableHead={[
              'Meses',
              'Receita',
              'Despesa',
              'Balanço',
              'Percentual',
              'Saldo em contas'
            ]}
          />

          <div className="col-md-6">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <AmChartMixedColumn
                initialData={[]}
                settings={{
                  valueAxis1Title: 'Despesa',
                  valueAxis2Title: '',
                  series2Title: 'Despesa',
                  series2fillColor: '#c42f2f',
                  series1Title: 'Receita',
                  series1fillColor: '#62b033',
                  series3Title: 'Planejamento Receita',
                  series3fillColor: '#94d367',
                  series3StrokeColor: '#94d367',
                  series4Title: 'Planejamento Despesa',
                  series4fillColor: '#d87166',
                  series4StrokeColor: '#d87166'
                }}
                minDate={datesRange.minDate}
                maxDate={datesRange.maxDate}
                data={dataUpdateMixed}
              />
            </div>
          </div>
        </div>

        <div className="row" style={{ marginBottom: 100 }}>
          <div className="graphic-flex">
            <div className="col-md-6">
              <AmChartPizza
                data={dataUpdateIncomePizza}
                divId="amChartPizza"
                maintitle="Gráfico de Receita por Grupo e Produto"
                style={{ width: '100%', height: '400px' }}
                height="750"
                resizeChart={true}
                hasExport
                styleSettings={{
                  legend: {
                    maxWidth: 400
                  }
                }}
                ignoreFields={['SOMA TOTAL']}
                fields={[
                  {
                    type: 'sum',
                    name: 'SOMA TOTAL',
                    percent: 0,
                    id: Math.random()
                  }
                ]}
              />
            </div>
            <div className="col-md-6">
              <AmChartPizza
                data={dataUpdateExpensePizza}
                divId="amChartPizza1"
                maintitle="Gráfico de Despesa por Categoria e Subcategoria"
                style={{ width: '100%', height: '400px' }}
                height="750"
                hasExport
                resizeChart={true}
                styleSettings={{
                  legend: {
                    maxWidth: 322
                  }
                }}
                ignoreFields={['SOMA TOTAL']}
                fields={[
                  {
                    type: 'sum',
                    name: 'SOMA TOTAL',
                    percent: 0,
                    id: Math.random()
                  }
                ]}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <TablePortlet
            maintitle="Quadro de Despesa a Pagar e Receita a Receber"
            className="col-md-6"
            handleSetDate={handleSetDataPayable}
            setInitialData={setInitialData}
            realData={accountsPayable}
            lastChildStyle="boldGrey dropDown"
            hasDropdown={false}
            isHighlight={true}
            initialData={[]}
            indexOnlySettings={[accountsPayable.length - 1]}
            actions={[]}
            stylesChild={{
              column1: {
                isOnly: true,
                onlyClassname: 'boldGrey'
              },
              column2: {
                isOnly: true,
                onlyClassname: 'boldGrey'
              },
              column3: {
                isOnly: true,
                onlyClassname: 'boldGrey'
              },
              column4: {
                isOnly: true,
                onlyClassname: 'boldGrey'
              },
              column6: {
                isOnly: true,
                onlyClassname: 'boldGrey'
              },
              column5: {
                classname: 'boldGrey',
                isHighlight: true,
                overAndUnder: true,
                highlightOverNumber: 0
              },
              th: {
                classname: 'hasBottomBorder'
              },
              highlightColor: '#c42f2f',
              highlightOverColor: '#62b033',
              highlightUnderColor: '#c42f2f'
            }}
            tableHead={[
              'Meses',
              'Receita',
              'Despesa',
              'Balanço',
              'Lucratividade'
            ]}
          />
          <div className="col-md-6">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <AmChartMixedColumn
                divId="paybleChartMixed"
                initialData={[]}
                settings={{
                  valueAxis1Title: 'Despesa',
                  valueAxis2Title: '',
                  series2Title: 'Despesa a Pagar',
                  series2fillColor: '#c42f2f',
                  series1Title: 'Receita a Receber',
                  series1fillColor: '#62b033',
                  series3Title: 'Planejamento Receita',
                  series3fillColor: '#94d367',
                  series3StrokeColor: '#94d367',
                  series4Title: 'Planejamento Despesa',
                  series4fillColor: '#d87166',
                  series4StrokeColor: '#d87166'
                }}
                minDate={datesRangePayable.minDate}
                maxDate={datesRangePayable.maxDate}
                data={dataUpdatePayableMixed}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="graphic-flex">
            <div className="col-md-6">
              <AmChartPizza
                data={dataUpdatePayableIncomePizza}
                divId="amChartPizzaPayable"
                maintitle="Gráfico de Receita por Grupo e Produto"
                style={{ width: '100%', height: '400px' }}
                height="750"
                resizeChart={true}
                hasExport
                styleSettings={{
                  legend: {
                    maxWidth: 322
                  }
                }}
                ignoreFields={['SOMA TOTAL']}
                fields={[
                  {
                    type: 'sum',
                    name: 'SOMA TOTAL',
                    percent: 0,
                    id: Math.random()
                  }
                ]}
              />
            </div>
            <div className="col-md-6">
              <AmChartPizza
                data={dataUpdatePayableExpensePizza}
                divId="amChartPizza1Payable"
                maintitle="Gráfico de Despesa por Categoria e Subcategoria"
                style={{ width: '100%', height: '400px' }}
                height="750"
                hasExport
                resizeChart={true}
                styleSettings={{
                  legend: {
                    maxWidth: 322
                  }
                }}
                ignoreFields={['SOMA TOTAL']}
                fields={[
                  {
                    type: 'sum',
                    name: 'SOMA TOTAL',
                    percent: 0,
                    id: Math.random()
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </ControlPanel>
    </Container>
  )
}

export default CashFlowAnalysis
