import React, { useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { apiCreate, apiUpdate } from '../../domain/api'
import { useProduct } from '../../providers/product/ProductProvider'
import { HasCompositionTab } from '../HasComposition'
import { priorities, WarehouseRequest } from '../../domain/info'
import { useAuth } from '../../../../../hooks/auth'

interface TypesFormProps {
  warehouseRequest?: WarehouseRequest
  typeForm: 'create' | 'update'
}

export const FormWarehouseRequest = ({
  typeForm,
  warehouseRequest
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const {
    setWarehouseRequests,
    setWarehouseRequestsProducts,
    setOldWarehouseRequestsProducts
  } = useProduct()
  const { user } = useAuth()
  const [defaultValues, setDefaultValues] = useState<any>()
  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    if (warehouseRequest) {
      setWarehouseRequests(warehouseRequest)
      setWarehouseRequestsProducts(
        warehouseRequest.warehouseRequestsProducts || []
      )
      const copy = JSON.parse(
        JSON.stringify(warehouseRequest.warehouseRequestsProducts)
      )
      setOldWarehouseRequestsProducts(copy)
      setDefaultValues(warehouseRequest)
    }
  }, [
    setOldWarehouseRequestsProducts,
    setWarehouseRequests,
    setWarehouseRequestsProducts,
    warehouseRequest
  ])

  const onSubmitForm = async (data: any) => {
    activeLoading()
    const id = String(warehouseRequest?.id)

    if (typeForm === 'create') {
      try {
        await api.post(apiCreate(), data)
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
        history.push('/warehouse/warehouseRequests')
      } catch (error: any) {
        let description =
          'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        if (error?.response?.data?.message?.includes('Quantidade')) {
          description = error?.response?.data?.message
        }
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description
        })
      }
    } else {
      try {
        await api.put(apiUpdate(id), data)
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
        history.push('/warehouse/warehouseRequests')
      } catch (error: any) {
        let description =
          'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        if (error?.response?.data?.message?.includes('Quantidade')) {
          description = error?.response?.data?.message
        }
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description
        })
      }
    }
    disableLoading()
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <>
        <div className="form-body">
          <div className="row">
            <div className="col-md-4">
              <Input
                name="name"
                className="form-control"
                label="Solicitante"
                value={
                  typeForm === 'update'
                    ? warehouseRequest?.user?.name
                    : user.name
                }
                readOnly
                rules={{ required: true }}
                style={{
                  paddingRight: '5px',
                  paddingLeft: '5px'
                }}
              />
            </div>
            <div className="col-md-4">
              <Select
                name="priority"
                className="form-control"
                label="Prioridade"
                options={priorities}
                rules={{ required: true }}
                blank
                defaultValue={''}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-content col-md-12">
              <div className="form-group">
                <Textarea
                  label="Descrição"
                  className="form-control"
                  rules={{ required: true }}
                  name="description"
                  rows={6}
                  style={{
                    paddingRight: '5px',
                    paddingLeft: '5px'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
      {HasCompositionTab()}
    </Form>
  )
}
