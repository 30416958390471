import api from '../../../../../../services/api'

export type ProductType = {
  id: string
  name: string
  label: string
}

export type ProductCategory = {
  id: string
  name: string
  parent_id: string | null
}

export type FinancialCategory = {
  id: string
  name: string
  parent_id: string | null
}

export type Attributes = {
  id: string
  name: string
  parent_id: string | null
  childrenList: Attributes[]
  isChecked?: boolean
}

export type UnitMensured = {
  id: string
  name: string
}

export const loadProductTypes = (): ProductType[] => {
  return [
    { id: '5', name: 'locacao', label: 'LOCAÇÃO' },
    { id: '1', name: 'materia-prima', label: 'MATERIA PRIMA' },
    { id: '4', name: 'revenda', label: 'REVENDA' },
    { id: '2', name: 'semi-acabado', label: 'SEMI ACABADO' },
    { id: '6', name: 'consumo', label: 'USO E CONSUMO' },
    { id: '3', name: 'venda', label: 'VENDA' }
  ]
}

export const loadProductCategories = async (): Promise<ProductCategory[]> => {
  const { data, status } = await api.get('/warehouse/productCategories/list/')

  if (status === 200) {
    const resultData: ProductCategory[] = []
    data.forEach((result: any) =>
      resultData.push({
        id: result.id,
        name: result.name,
        parent_id: result.parent_id
      })
    )
    return resultData
  }
  return []
}

export const loadFinancialCategories = async (): Promise<
  FinancialCategory[]
> => {
  const { data, status } = await api.get('financial/categories')

  if (status === 200) {
    const resultData: FinancialCategory[] = []
    data.forEach((result: any) =>
      resultData.push({
        id: result.id,
        name: result.name,
        parent_id: result.parent_id
      })
    )
    return resultData
  }
  return []
}

export const loadAtributes = async (): Promise<Attributes[]> => {
  const { data, status } = await api.get<Attributes[]>(
    '/warehouse/productAttributes'
  )
  if (status === 200) {
    const resultData: Attributes[] = []
    data.forEach(result => {
      if (result.parent_id === null) {
        resultData.push({
          id: result.id,
          name: result.name,
          parent_id: result.parent_id,
          childrenList: data.filter((e: any) => e.parent_id === result.id)
        })
      }
    })
    return resultData
  }
  return []
}

export const loadUnitMensured = async (): Promise<UnitMensured[]> => {
  const { data, status } = await api.get<UnitMensured[]>(
    '/warehouse/productUnitMeasured'
  )
  if (status === 200) {
    const resultData: UnitMensured[] = []
    data.forEach((result: any) =>
      resultData.push({
        id: result.id,
        name: result.name
      })
    )
    return resultData
  }
  return []
}
