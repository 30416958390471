import React, { useCallback, useState } from 'react'
import { DatePicker } from '../DatePicker'
import { Container } from './style'
interface UsersProps {
  name: string
  avatar_url: string
  goalAccomplished: number
  cases: number
  closed: number
  rate: number
}
interface HeaderPortLetProps {
  mainTitle: string
  subTitle?: string
  actions: any[]
  activeMembersData: UsersProps[]
  startIndexAction?: number
  getUserData(): void
  setInitialData(date?: string): void
  handleSetDate(date: string, agent_id?: number): void
}

const HeaderPortlet: React.FC<HeaderPortLetProps> = ({
  subTitle,
  mainTitle,
  actions,
  getUserData,
  setInitialData,
  handleSetDate,
  startIndexAction
}) => {
  const [selectedItem, setSelectedItem] = useState(
    startIndexAction >= 0 ? startIndexAction : 2
  )
  const [isReset, setIsReset] = useState(false)
  const handleSwitchPeriod = useCallback(
    (index: number) => {
      if (actions[index] !== 'Período') {
        setIsReset(true)
        if (index === selectedItem) {
          setSelectedItem(startIndexAction >= 0 ? startIndexAction : 2)
          setInitialData(actions[startIndexAction >= 0 ? startIndexAction : 2])
          return
        }
      } else {
        setIsReset(false)
        setSelectedItem(index)

        return
      }
      handleSetDate(actions[index] === 'Mês' ? 'Mes' : actions[index])
      setSelectedItem(index)
      getUserData()
    },
    [
      actions,
      handleSetDate,
      getUserData,
      selectedItem,
      startIndexAction,
      setInitialData
    ]
  )

  return (
    <Container className="portlet-title">
      <div className="caption caption-md">
        <i className="icon-bar-chart font-dark hide"></i>
        <span className="caption-subject font-green-steel bold uppercase">
          {mainTitle}
        </span>
        <span className="caption-helper">{subTitle}</span>
      </div>
      <div className="actions">
        <div className="btn-group btn-group-devided" data-toggle="buttons">
          {actions.map((item, index) => {
            if (item !== 'Período') {
              return (
                <label
                  className={`btn btn-transparent blue-oleo btn-no-border btn-outline  btn-sm ${
                    index === selectedItem ? 'active' : ''
                  }`}
                  key={item}
                >
                  <input
                    type="radio"
                    name="options"
                    className="toggle"
                    id={`option${index + 1}`}
                    onClick={() => handleSwitchPeriod(index)}
                  />
                  {item}
                </label>
              )
            } else {
              return (
                <DatePicker
                  setInitialData={setInitialData}
                  initialAction={
                    actions[startIndexAction >= 0 ? startIndexAction : 2]
                  }
                  handleSwitchPeriod={() => handleSwitchPeriod(index)}
                  handleResetData={() =>
                    setSelectedItem(
                      startIndexAction >= 0 ? startIndexAction : 2
                    )
                  }
                  handleSetDate={handleSetDate}
                  isReset={isReset}
                  key={item}
                  getUserData={getUserData}
                  className={`btn btn-transparent blue-oleo btn-no-border btn-outline  btn-sm ${
                    index === selectedItem ? 'active' : ''
                  }`}
                />
              )
            }
          })}
        </div>
      </div>
    </Container>
  )
}

export { HeaderPortlet }
