import React, { useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { useLocation } from 'react-router'
import { FilterContaier, ListTitle } from './styles'
import {
  loadProductCategories,
  ProductCategory
} from '../../product/ProductRegister_temp/services/api'
import Modal from '../../../../components/Modal'
import { FormStockMoviments } from '../../StockMoviments/components/Form'
import { faCubes } from '@fortawesome/free-solid-svg-icons'

const StocksList = (): JSX.Element => {
  const locations = new URLSearchParams(useLocation().search)
  const [parameters, setParameters] = useState<any[]>()
  const [pageTitle, setPageTitle] = useState('Estoques')
  const [productCategory, setProductCategory] = useState('')
  const [productCategories, setProductCategories] =
    useState<ProductCategory[]>()

  const [modalStockMoviments, setModalStockMoviments] = useState(false)
  const [stock, setStock] = useState()
  useEffect(() => {
    if (locations.get('empty') === 'true') {
      setParameters([{ empty: 'true' }])
    } else if (locations.get('empty') === 'false') {
      setParameters([{ empty: 'false' }])
    } else if (locations.get('pending') === 'true') {
      setPageTitle('Estoques Pendentes')
      setParameters([{ pending: 'true' }])
    } else if (locations.get('pending') === 'false') {
      setPageTitle('Estoques Não Pendentes')
      setParameters([{ pending: 'false' }])
    } else {
      setParameters([{}])
    }
    ;(async () => {
      const categories = await loadProductCategories()
      setProductCategories(categories)
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container
      pageTitle={pageTitle}
      portletTitle={'Filtro'}
      breadcrumb={breadcrumbList}
    >
      <FilterContaier>
        <div>
          <div className="col-md-3">
            <label htmlFor="product-group">Grupo</label>
            <select
              className="form-control"
              id="product-group"
              value={productCategory}
              onChange={({ target }) => setProductCategory(target.value)}
            >
              <option value="" style={{ display: 'none' }}>
                Selecione
              </option>
              {productCategories?.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <footer>
          <button
            onClick={() => {
              setProductCategory('')
            }}
          >
            LIMPAR
          </button>
          <button
            onClick={() => {
              if (productCategory) {
                setParameters([
                  { ...parameters[0], product_category_id: productCategory }
                ])
              } else {
                setParameters([
                  { ...parameters[0], product_category_id: undefined }
                ])
              }
            }}
          >
            BUSCAR
          </button>
        </footer>
        <hr />
      </FilterContaier>
      <ListTitle>
        <p>Listagem</p>
        <hr style={{ paddingBottom: 10 }} />
      </ListTitle>
      {parameters && (
        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'fullname' }}
          searchParameters={parameters}
          notHasChildren
          headers={headers}
          onlyView
          actions={[
            {
              name: 'Visualizar',
              title: 'Visualizar',
              spanIcon: 'fa  fa-search',
              linkTo: stock => {
                return `/warehouse/products/view/${stock.product_id}?tab=${stock.id}`
              }
            },
            {
              name: 'Adicionar',
              title: 'Adicionar',
              icon: faCubes,
              onClick: stock => {
                setModalStockMoviments(true)
                setStock(stock)
              }
            }
          ]}
        />
      )}
      {modalStockMoviments && (
        <Modal
          onClickButtonCancel={() => setModalStockMoviments(false)}
          isOpenModal={modalStockMoviments}
          pageTitle={'Adicionar Movimentação'}
          styles={{
            padding: 0
          }}
          Children={
            <FormStockMoviments
              stock={stock}
              setModalStockMoviments={setModalStockMoviments}
            />
          }
        />
      )}
    </Container>
  )
}

export default StocksList
