/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { Container } from './styles'
import avatar1 from '../../assets/global/img/users/avatar1.jpg'
import avatar2 from '../../assets/global/img/users/avatar2.jpg'
import avatar3 from '../../assets/global/img/users/avatar3.jpg'
import avatar4 from '../../assets/global/img/users/avatar4.jpg'
import avatar5 from '../../assets/global/img/users/avatar5.jpg'
import avatar6 from '../../assets/global/img/users/avatar6.jpg'
import avatar7 from '../../assets/global/img/users/avatar7.jpg'
import avatar8 from '../../assets/global/img/users/avatar8.jpg'
import avatar9 from '../../assets/global/img/users/avatar9.jpg'
import avatar10 from '../../assets/global/img/users/avatar10.jpg'
import { DatePicker } from '../DatePicker'
import { genericMaskWithTwoZero } from '../../utlis/mask'
import api from '../../services/api'
import currency from 'currency.js'
import { IconBaseProps } from 'react-icons'

interface UsersProps {
  name: string
  agent_id: number
  avatar_url: string | React.ComponentType<IconBaseProps>
  goalAccomplished: number
  cases: number
  closed: number
  discount: number
  rate: number
}
interface GetUsersProps {
  username: string
  agent_id: number
}

interface BoardOfMembersProps {
  getUserData(): void
  setInitialData(date?: string, agent_id?: number): void
  handleSetDate(date: string, agent_id?: number): void
  activeMembersData: UsersProps[]
  tableHead?: any[]
}

const BoardOfMembers: React.FC<BoardOfMembersProps> = ({
  getUserData,
  setInitialData,
  activeMembersData,
  handleSetDate,
  tableHead
}) => {
  const [selectedItem, setSelectedItem] = useState(0)
  const [selectedUser, setSelectedUser] = useState('')
  const [isReset, setIsReset] = useState(false)
  const [actions] = useState(['Hoje', 'Semana', 'Mês', 'Ano', 'Período'])
  const handleGetUser = useCallback(
    async ({ username, agent_id }: GetUsersProps) => {
      if (username === selectedUser) {
        setSelectedUser('')
        setInitialData('', 0)
      } else {
        handleSetDate('', agent_id)
        setSelectedUser(username)
        getUserData()
      }
    },
    [getUserData, handleSetDate, selectedUser, setInitialData]
  )
  return (
    <Container className="col-md-6">
      <div className="portlet light ">
        <div className="portlet-title">
          <div className="caption caption-md">
            <i className="icon-bar-chart font-dark hide"></i>
            <span className="maintitle">Quadro de Meta</span>
          </div>
        </div>
        <div className="portlet-body">
          <div className="table-responsive" style={{ border: 0 }}>
            <table className="table table-light">
              <thead>
                <tr className="TableRowHead">
                  {tableHead?.length ? (
                    tableHead.map(tableHead => (
                      <th
                        colSpan={tableHead.colSpan || 0}
                        className={tableHead.classname || ''}
                        key={tableHead.name}
                      >
                        {tableHead.name}
                      </th>
                    ))
                  ) : (
                    <>
                      <th colSpan={2}> Membro </th>
                      <th> Meta </th>
                      <th> Meta Realizada </th>
                      <th className="ThBalance"> Balanço </th>
                      <th className="ThPercente"> Percentual </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {activeMembersData.length ? (
                  activeMembersData.map(user => (
                    <tr
                      key={user.name}
                      className={
                        user.name === selectedUser ? 'activeCustom' : ''
                      }
                      onClick={() =>
                        handleGetUser({
                          username: user.name,
                          agent_id: user.agent_id
                        })
                      }
                    >
                      <td className="fit">
                        {typeof user.avatar_url === 'string' ? (
                          <img
                            className="user-pic rounded"
                            src={user.avatar_url}
                          />
                        ) : (
                          <user.avatar_url size={30} color="#d4d4d4" />
                        )}
                      </td>
                      <td>
                        <a>{user.name}</a>
                      </td>
                      <td>{user.goalAccomplished}</td>
                      <td>{user.cases}</td>
                      <td>{user.closed}</td>
                      {user.discount && <td>{user.discount}</td>}
                      <td>
                        <span
                          className="bold"
                          style={{
                            color:
                              currency(user.rate).dollars() >= 100
                                ? 'rgb(224, 142, 32)'
                                : '#a0a0a0'
                          }}
                        >
                          {user.rate}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} style={{ textAlign: 'center' }}>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  )
}

export { BoardOfMembers }
