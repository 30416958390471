import styled from 'styled-components'

export const PageContainer = styled.div`
  padding: 20px 60px;
`

export const Attachments = styled.div`
  margin: 30px 0px;
  h4 {
    margin: 20px 0px;
  }
  a {
    margin-right: 10px;
    padding: 8px;
    border: solid 1px #ccc;
  }
`

export const Avatar = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50% !important;
  margin-right: 8px;
`
export const AvatarSmall = styled.img`
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 50% !important;
  margin-right: 8px;
`

export const TaskDate = styled.div`
  display: flex;
  margin: 10px 0px;
  color: #ccc;

  span {
    font-weight: normal;
    margin-right: 10px;
  }
  div:last-child span {
    color: green;
  }
`

export const Activities = styled.div`
  margin: 0px;
`

export const Divider = styled.hr`
  margin: 20px 0px;
`

export const Reply = styled.div`
  padding: 10px 5px;
  border: 1px solid #eee;
  margin: 4px 0px;
  p {
    margin: 8px 0px;
  }
  a {
    display: inline-block;
    margin-top: 10px !important;
    margin-right: 10px;
    padding: 4px;

    font-size: 12px;
    border: solid 1px #ccc;
  }
`

export const ReplyInput = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  label {
    margin-right: 3px;
  }
`
