/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useEffect, useRef } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
interface AmChartSortedProps {
  divId: string
  data?: any[]
  fillPrimaryColor: string
  fillSecondaryColor?: string
  labelHorizontalCenter?: string
  labelHorizontalOffSet?: number
  labelTextMaxWidth?: number
  labelLocationX?: number
  fillFontColor?: string
  fillPercentLighten?: number
  initialData: any[]
  revertInitial?: boolean
  height?: string
  maintitle?: string
  classname: string
}

const AmChartSortedBar: React.FC<AmChartSortedProps> = ({
  data,
  initialData,
  divId,
  fillPrimaryColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fillSecondaryColor,
  labelHorizontalCenter,
  labelHorizontalOffSet,
  labelLocationX,
  fillFontColor,
  fillPercentLighten,
  revertInitial,
  height,
  maintitle,
  classname,
  labelTextMaxWidth
}) => {
  const chartRef = useRef(null)
  const seriesRef = useRef(null)
  function checkEmptyData() {
    let indicator
    function showIndicator() {
      if (indicator) {
        indicator.show()
      } else {
        indicator = chartRef.current.tooltipContainer.createChild(
          am4core.Container
        )
        indicator.background.fill = am4core.color('#fff')
        indicator.background.fillOpacity = 0.8
        indicator.width = am4core.percent(100)
        indicator.height = am4core.percent(100)

        const indicatorLabel = indicator.createChild(am4core.Label)
        indicatorLabel.text = 'Sem dados para análise no momento'
        indicatorLabel.align = 'center'
        indicatorLabel.valign = 'middle'
        indicatorLabel.fontSize = 20
      }
    }

    function hideIndicator() {
      indicator.hide()
    }

    chartRef.current.events.on('datavalidated', function (ev) {
      if (ev.target.data.length === 0) {
        showIndicator()
      } else if (indicator) {
        hideIndicator()
      }
    })
  }
  function resizeCart() {
    const cellSize = 70
    chartRef.current.events.on('datavalidated', function (ev) {
      const chart = ev.target
      const categoryAxis = chart.yAxes.getIndex(0)

      const adjustHeight =
        chart.data.length * cellSize - categoryAxis.pixelHeight

      const targetHeight =
        chart.pixelHeight + adjustHeight > 187
          ? chart.pixelHeight + adjustHeight
          : 187

      chartRef.current.svgContainer.htmlElement.style.height =
        targetHeight + 'px'
    })
  }

  useEffect(() => {
    if (!chartRef.current) {
      am4core.useTheme(am4themes_animated)

      chartRef.current = am4core.create(divId, am4charts.XYChart)
      chartRef.current.language.locale._decimalSeparator = ','
      chartRef.current.language.locale._thousandSeparator = '.'

      chartRef.current.padding(40, 40, 40, 40)
      const categoryAxis = chartRef.current.yAxes.push(
        new am4charts.CategoryAxis()
      )
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.dataFields.category = 'name'
      categoryAxis.renderer.minGridDistance = 1
      categoryAxis.renderer.inversed = true
      categoryAxis.renderer.grid.template.disabled = true
      categoryAxis.renderer.labels.template.wrap = false
      categoryAxis.renderer.labels.template.maxWidth = labelTextMaxWidth || 700
      categoryAxis.renderer.labels.template.truncate = true
      categoryAxis.renderer.labels.template.fontSize = 12

      const valueAxis = chartRef.current.xAxes.push(new am4charts.ValueAxis())
      valueAxis.min = 0
      valueAxis.extraMax = 0.1
      const series = chartRef.current.series.push(new am4charts.ColumnSeries())
      seriesRef.current = series
      series.dataFields.categoryY = 'name'
      series.dataFields.valueX = 'valueMonetary'
      series.dataFields.value = 'valueUnit'

      series.columns.template.strokeOpacity = 0
      series.columns.template.column.cornerRadiusBottomRight = 5
      series.columns.template.column.cornerRadiusTopRight = 5
      series.columns.template.maxHeight = 40
      const labelBullet = series.bullets.push(new am4charts.LabelBullet())
      const labelBullet1 = series.bullets.push(new am4charts.LabelBullet())
      labelBullet.label.horizontalCenter = labelHorizontalCenter || 'left'
      labelBullet.label.dx = labelHorizontalOffSet || 10
      labelBullet.label.truncate = false
      labelBullet.label.fontSize = 12
      labelBullet.label.text =
        '{values.valueX.workingValue.formatNumber("#,###.00")}'
      labelBullet.locationX = labelLocationX || 0
      labelBullet1.label.horizontalCenter = labelHorizontalCenter || 'left'
      labelBullet1.label.paddingRight = labelHorizontalOffSet || 20
      labelBullet1.label.paddingLeft = labelHorizontalOffSet || 10
      labelBullet1.label.truncate = false
      labelBullet1.label.fontSize = 12
      labelBullet1.label.text = '{value}'
      if (divId === 'amChartSortedBarname') {
        labelBullet1.label.text = '{value} QTD'
      }
      if (divId === 'amChartSortedBarCountry') {
        labelBullet1.label.text = '{value} QTD'
      }
      labelBullet1.locationX = 1
      labelBullet1.label.fill = fillFontColor || '#000'
      series.columns.template.fill = fillPercentLighten
        ? am4core.color(fillPrimaryColor).lighten(fillPercentLighten)
        : am4core.color(fillPrimaryColor)

      labelBullet.label.adapter.add('dx', (dx, target) => {
        if ((target.dataItem.valueX * 100) / valueAxis.max <= 5) {
          return 55 - (target.dataItem.valueX * 100) / valueAxis.max
        }
        return 10
      })
      checkEmptyData()
      resizeCart()

      categoryAxis.sortBySeries = series
      chartRef.current.data = initialData.sort(
        (a, b) => b.valueMonetary - a.valueMonetary
      )
      chartRef.current.responsive.enable = true
    }

    return () => {
      chartRef.current && chartRef.current.dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = data
      chartRef.current.invalidateRawData()
      resizeCart()
      checkEmptyData()
      chartRef.current.responsive.enable = true
    }
  }, [data, initialData, revertInitial])
  return (
    <div
      className={classname || 'portlet light'}
      style={{ width: '100%', height: '100%' }}
    >
      {maintitle?.length > 0 && (
        <div className="portlet-title">
          <div className="caption caption-md">
            <i className="icon-bar-chart font-dark hide"></i>
            <span className="maintitle">{maintitle}</span>
          </div>
        </div>
      )}
      <div
        className="portlet-body"
        id={divId}
        style={{ width: '100%', minHeight: height }}
      ></div>
    </div>
  )
}

export { AmChartSortedBar }
