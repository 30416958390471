import React from 'react'

import { Input, Select } from '../../../../../../../../components/Form'
import { TooltipComponent } from '../../../../../../../../components/TooltipComponent'
import { useProduct } from '../../../providers/product/ProductProvider'

import { Container } from './styles'

export const OverviewTab = () => {
  const {
    typesProduct,
    categoriesCost,
    subCategoriesCost,
    loadSubCategoriesCost,
    groupsProduct,
    setProductType,
    setHasVariation
  } = useProduct()

  return (
    <>
      <Container className="row">
        <div className="form-content col-md-3">
          <TooltipComponent
            label="Tipo do produto"
            message="Selecione o tipo do produto"
          />
          <Select
            className="form-control"
            name="details_overview.type"
            options={typesProduct.map(({ name, label }) => ({
              value: name,
              name: label
            }))}
            onChange={({ target }) => setProductType(target.value)}
            blank
            controlled
            rules={{ required: true }}
          />
        </div>
        <div className="form-content col-md-3">
          <TooltipComponent
            label="Grupo do produto"
            message="selecione o grupo do produto"
          />
          <Select
            className="form-control"
            name="details_overview.product_category_id"
            options={groupsProduct.map(({ id, name }) => ({
              value: id,
              name
            }))}
            controlled
            blank
            rules={{ required: true }}
          />
        </div>
        <div className="form-content col-md-3">
          <TooltipComponent
            label="Nome do produto"
            message="Selecione o nome do produto"
          />
          <Input
            className="form-control"
            name="details_overview.name"
            rules={{ required: true }}
          />
        </div>
      </Container>
      <Container className="row">
        <div className="form-content col-md-3">
          <TooltipComponent
            label="Categoria de custo"
            message="Selecione a categoria de custo"
          />
          <Select
            className="form-control"
            name="details_overview.category_cost_id"
            options={categoriesCost
              .filter(fc => fc.parent_id === null)
              .map(({ id, name }) => ({
                value: id,
                name: name
              }))}
            onChange={({ target }) => loadSubCategoriesCost(target.value)}
            controlled
            blank
            rules={{ required: true }}
          />
        </div>
        <div className="form-content col-md-3">
          <TooltipComponent
            label="Subcategoria de custo"
            message="selecione o subcategoria de custo"
          />
          <Select
            name="details_overview.subcategory_cost_id"
            className="form-control"
            options={subCategoriesCost.map(({ id, name }) => ({
              value: id,
              name
            }))}
            controlled
            blank
            rules={{ required: true }}
          />
        </div>
        <div className="form-content col-md-3">
          <TooltipComponent
            label="Possui variação?"
            message="selecione se existe variação"
          />
          <Select
            name="details_overview.hasVariation"
            className="form-control"
            options={[
              { name: 'SIM', value: 'YES' },
              { name: 'NÃO', value: 'NO' }
            ]}
            controlled
            blank
            rules={{ required: true }}
            onChange={({ target }) => setHasVariation(target.value)}
          />
          <Input name="details_overview.details" type="hidden" value="{}" />
        </div>
      </Container>
    </>
  )
}
