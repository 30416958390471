import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions, namePageTitle } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import CustomDataTable from '../../../../components/CustomDataTable'
import { headersView } from '../domain/headers'
import moment from 'moment'

interface PaymentRemittanceFileData {
  id: number
  company_id: number
  bankAccount: {
    nome: string
    agencia?: string
    conta?: string
  }
  quantity_tranfers: number
  quantity_titles: number
  quantity_taxes: number
  value_transfers: string
  value_titles: string
  value_taxes: string
  total_value: string
  processed: boolean
  user_id: number
  user_name: string
  created_at: string
  updated_at: string
  transactions: any[]
}

const PaymentRemittanceFileView: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [paymentRemittanceFile, setPaymentRemittanceFile] =
    useState<PaymentRemittanceFileData | null>(null)
  const { addToast } = useToast()

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadCategory(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<PaymentRemittanceFileData>(apiList(id))
        const { data } = response
        setPaymentRemittanceFile(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar a categoria',
          description:
            'Houve um error ao carregar a categoria, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadCategory()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Atributo removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Atributo não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="id" className="control-label">
                  Cód.
                </label>
                <p>{paymentRemittanceFile?.id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Empresa
                </label>
                <p>
                  {paymentRemittanceFile?.company_id === 1
                    ? 'COMERCIO'
                    : 'INDUSTRIA'}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="type" className="control-label">
                  Conta
                </label>
                <p>{`${paymentRemittanceFile?.bankAccount?.nome} - AG${paymentRemittanceFile?.bankAccount?.agencia} - C${paymentRemittanceFile?.bankAccount?.conta}`}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="type" className="control-label">
                  Transferencias
                </label>
                <p>{`${paymentRemittanceFile?.value_transfers} (${
                  paymentRemittanceFile?.quantity_tranfers || '0'
                })`}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="type" className="control-label">
                  Títulos
                </label>
                <p>{`${paymentRemittanceFile?.value_titles} (${
                  paymentRemittanceFile?.quantity_titles || '0'
                })`}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="type" className="control-label">
                  Convênios/Tributos
                </label>
                <p>{`${paymentRemittanceFile?.value_taxes} (${
                  paymentRemittanceFile?.quantity_taxes || '0'
                })`}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="type" className="control-label">
                  Valor total
                </label>
                <p>R$ {paymentRemittanceFile?.total_value}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="created" className="control-label">
                  Período
                </label>
                <p>{paymentRemittanceFile?.created_at?.substring(0, 10)}</p>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
        </div>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">Pagamentos</div>
          </div>
          <div className="portlet-body form">
            <CustomDataTable
              customItems={paymentRemittanceFile?.transactions || []}
              headers={headersView}
              customHeaders={[
                {
                  name: 'Vencimento',
                  field: 'due_date',
                  sortable: true,
                  element: item => (
                    <span>
                      {moment(item.due_date, 'DD/MM/YYYY HH:mm:ss')
                        .add({ hours: 3 })
                        .format('DD/MM/YYYY')}
                    </span>
                  )
                }
              ]}
            />
          </div>
        </div>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">Sistema</div>
          </div>
          <div className="portlet-body form">
            <div className="row">
              <div className="col-md-2">Cadastrado por:</div>
              <div className="col-md-2">{paymentRemittanceFile?.user_name}</div>
            </div>
            <div className="row">
              <div className="col-md-2">Cadastrado em:</div>
              <div className="col-md-2">
                {paymentRemittanceFile?.created_at}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${paymentRemittanceFile?.id} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(
            Number(paymentRemittanceFile?.id)
          )
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default PaymentRemittanceFileView
