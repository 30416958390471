import React, { useState } from 'react'
import api from '../../../../../../services/api'
import Form, { Select, Textarea } from '../../../../../../components/Form'
import Button from '../../../../../../components/Button'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'

interface TypesFormProps {
  setModal: any
  loadOrderExpedition: any
  statusList: any[]
  currentStatus: number
  received: boolean
  id: number
}

export const FormSituation = ({
  setModal,
  loadOrderExpedition,
  statusList,
  currentStatus,
  id
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()
  const [newStatus, setNewStatus] = useState('')

  const onSubmitForm = async (data: any) => {
    activeLoading()
    const url = 'warehouse/expedition/orders/situation'
    try {
      await api.post(url, { order_id: id, ...data })
      addToast({
        type: 'success',
        title: 'Registro atualizado',
        description: 'Registro alterado com sucesso'
      })
      loadOrderExpedition()
      setModal(false)
    } catch (error: any) {
      const message = error?.response?.data?.message
      addToast({
        type: 'error',
        title: 'Erro ao atualizar o registro',
        description:
          message ||
          'Ocorreu um erro ao atualizar o cadastro, por favor, tente novamente.'
      })
    }
    disableLoading()
  }
  let statusFiltered = []
  if (currentStatus === 5) {
    statusFiltered = statusList.filter(f => Number(f.id) === 1)
  } else {
    statusFiltered = statusList.filter(
      f =>
        Number(f.id) - 1 === Number(currentStatus) ||
        Number(f.id) + 1 === Number(currentStatus) ||
        (Number(f.id) === 5 && Number(currentStatus) !== 5)
    )
  }
  return (
    <Form onSubmit={onSubmitForm}>
      <>
        <div style={{ width: '800px' }}>
          <div className="row" style={{ marginTop: '0px' }}>
            <div className="col-md-6">
              <Select
                name="status"
                className="form-control"
                label="Status"
                options={statusFiltered.map(s => ({
                  name: s.name,
                  value: s.id
                }))}
                rules={{ required: true }}
                onChange={e => setNewStatus(e.target.value)}
                blank
                defaultValue={''}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-content col-md-12">
              <div className="form-group">
                <Textarea
                  label="Observação"
                  className="form-control"
                  name="description"
                  rows={6}
                />
              </div>
            </div>
          </div>
        </div>
      </>
      <div className="form-actions right">
        <Button type="submit" className="btn dark btn-sm sbold uppercase">
          Salvar
        </Button>
      </div>
    </Form>
  )
}
