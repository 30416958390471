export const headers = [
  { name: 'Nº Requisição', field: 'id', sortable: true },
  { name: 'Prioridade', field: 'priority', sortable: true, custom: true },
  { name: 'Situação', field: 'situation', sortable: true, custom: true },
  {
    name: 'Entrega',
    field: 'delivery_date',
    custom: true,
    sortable: true
  },
  {
    name: 'Data da solicitação',
    field: 'created_at',
    sortable: true
  },
  {
    name: 'Solicitante',
    field: 'user_id',
    sortable: true,
    custom: true
  },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const logsHeaders = [
  {
    name: 'Situação anterior',
    field: 'previous_situation',
    sortable: true,
    custom: true
  },
  {
    name: 'Situação nova',
    field: 'new_situation',
    sortable: true,
    custom: true
  },
  { name: 'Usuário', field: 'user.name', sortable: true },
  {
    name: 'Data',
    field: 'date',
    sortable: true
  },
  {
    name: 'Observação',
    field: 'description',
    sortable: true
  }
]

export const productsHeaders = [
  { name: 'Produto', field: 'stock.product.name', sortable: true },
  { name: 'Variação', field: 'stock.name', sortable: true },
  { name: 'Quantidade', field: 'quantity', sortable: true }
]
