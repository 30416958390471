/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../../components/Container'
import Tabs from '../../../../../components/Tabs'
import Tab from '../../../../../components/Tabs/Tab'
import DataTable from '../../../../../components/DataTable'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { Alert } from '../../../../../components/Alert'
import { nameEntity, namePageTitle } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import { WrapperTablePortlet } from './styles'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import { useAuth } from '../../../../../hooks/auth'
import { TablePortlet } from '../../../../../components/TablePortlet'

interface RoleData {
  id: number
  parent_id: number | null
  name: string
  description: string
  created_at: string
  updated_at: string
}

const RolesView: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const { user } = useAuth()
  const [role, setRole] = useState<RoleData | null>(null)
  const [permissionsAggroupped, setPermissionsAggroupped] = useState([])
  const { addToast } = useToast()
  const searchParametersAuditLog = useRef([
    { entity: nameEntity, entity_id: id }
  ])
  const searchParametersUsers = useRef([{ role_id: id }])
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadRole(): Promise<void> {
      activeLoading()

      try {
        const response = await api.get<RoleData>(apiList(id))
        const permissions = await api.get(
          `/users/permissions/listAggrouped/${id}/-1`
        )
        setPermissionsAggroupped(permissions.data)
        const { data } = response
        setRole(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o Grupo de usuário',
          description:
            'Houve um error ao carregar o grupo, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadRole()
  }, [
    addToast,
    activeLoading,
    disableLoading,
    id,
    location.pathname,
    history,
    user.id
  ])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Atributo removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Atributo não removido, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Atributo removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Atributo não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  const handleSwitch = useCallback(
    async (item: any) => {
      activeLoading()
      if (!item.hasPermission) {
        await api.post('/users/permissionRole/create', {
          role_id: id,
          permission_id: item.id
        })
      } else {
        await api.delete(`/users/permissionRole/delete/${id}/${item.id}`)
      }
      const permissions = await api.get(
        `/users/permissions/listAggrouped/${id}/-1`
      )
      setPermissionsAggroupped(permissions.data)
      disableLoading()
    },
    [activeLoading, disableLoading, id]
  )

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={'Visualizar'}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="id" className="control-label">
                  Cód.
                </label>
                <p>{role?.id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Nome
                </label>
                <p>{role?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="created" className="control-label">
                  Cadastrado em
                </label>
                <p>{role?.created_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className="control-label">
                  Atualizado em
                </label>
                <p>{role?.updated_at}</p>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
          <div className="row">
            <div className="col-md-12">
              <Tabs>
                {[
                  <Tab key={0} title="Histórico">
                    <div className="portlet light">
                      <div className="portlet-title">
                        <div className="caption">Listagem</div>
                        <div className="tools"></div>
                      </div>
                      <div className="portlet-body form">
                        <DataTable
                          entityId={id}
                          format={{ orderBy: '' }}
                          source="auditLogs"
                          entity="AuditLog"
                          searchParameters={searchParametersAuditLog.current}
                        />
                      </div>
                    </div>
                  </Tab>,
                  <Tab key={1} title="Usuários">
                    <div className="portlet light">
                      <div className="portlet-title">
                        <div className="caption">Listagem</div>
                        <div className="tools"></div>
                      </div>
                      <div className="portlet-body form">
                        <DataTable
                          format={{ orderBy: '' }}
                          source="users"
                          entity="User"
                          entityId=""
                          searchParameters={searchParametersUsers.current}
                          notHasChildren
                          headers={[
                            {
                              name: 'Nome',
                              field: 'name',
                              sortable: true
                            },
                            { name: 'Ações', field: 'actions', sortable: false }
                          ]}
                        />
                      </div>
                    </div>
                  </Tab>,
                  <Tab key={2} title="Permissões">
                    <WrapperTablePortlet>
                      <TablePortlet
                        maintitle="Listagem"
                        handleSetDate={() => {}}
                        setInitialData={() => {}}
                        actions={[]}
                        tableHead={['Funções', 'Ação']}
                        hasDropdown={true}
                        hasToggle={true}
                        indexColumnToggle={[1]}
                        handleToggleSwitch={handleSwitch}
                        switchSettings={{
                          checkedIcon: false,
                          uncheckedIcon: false,
                          ischecked: 'hasPermission'
                        }}
                        realData={permissionsAggroupped}
                        stylesChild={{
                          th: {
                            classname: 'hasBottomBorder'
                          },
                          column4: {
                            classname: ''
                          },
                          column1: {
                            classname: 'firstChild'
                          },
                          child: {
                            column1: { classname: 'firstSubChild' }
                          },
                          highlightColor: '#c42f2f',
                          highlightOverColor: '#c42f2f'
                        }}
                      />
                    </WrapperTablePortlet>
                  </Tab>
                ]}
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${role?.name} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(role?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default RolesView
