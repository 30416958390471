import React from 'react'
import { Input } from '../../../../../../components/Form'
import { WarehouseRequest, WarehouseRequestProduct } from '../../../domain/info'
import { SearchContainer } from './styles'

type ProductListType = {
  id: number
  name: string
}
type SearchComponentProductParams = {
  index: number
  warehouseRequests: WarehouseRequest
  warehouseRequestsProducts: WarehouseRequestProduct[]
  setWarehouseRequestsProducts: React.Dispatch<
    React.SetStateAction<WarehouseRequestProduct[]>
  >
  productList: ProductListType[]
  selected: boolean
  setSelected: React.Dispatch<React.SetStateAction<boolean>>
}

export function SearchComponentProduct({
  index,
  warehouseRequests,
  warehouseRequestsProducts,
  setWarehouseRequestsProducts,
  productList,
  selected,
  setSelected
}: SearchComponentProductParams) {
  const handleBlur = () => {
    setTimeout(() => {
      !warehouseRequestsProducts[index].product_id &&
        setWarehouseRequestsProducts(prev => {
          const copy = [...prev]
          copy[index].product_name = ''
          return copy
        })
    }, 500)
  }

  return (
    <SearchContainer onBlur={handleBlur}>
      <div>
        <Input
          labelError="Produto"
          className="form-control"
          name={`warehouseRequestsProducts.${index}.product_name`}
          id="option"
          onChange={({ target }) => {
            setWarehouseRequestsProducts(prev => {
              const copy = [...prev]
              copy[index].product_name = target.value
              copy[index].product_id = 0
              copy[index].stock_id = ''
              return copy
            })
            setSelected(false)
          }}
          rules={{ required: true }}
          readOnly={
            !!warehouseRequests?.situation &&
            warehouseRequests?.situation !== 'new'
          }
          value={warehouseRequestsProducts[index].product_name}
          fullControlled
        />
        <Input
          name={`warehouseRequestsProducts.${index}.product_id`}
          value={warehouseRequestsProducts[index].product_id}
          type="hidden"
          controlled
        />
        <ul>
          {warehouseRequestsProducts[index].product_name !== '' &&
            !selected &&
            productList
              .filter(f =>
                f.name
                  ?.toLowerCase()
                  .includes(warehouseRequestsProducts[index].product_name)
              )
              .map(item => (
                <li
                  key={item.id}
                  onClick={() => {
                    setWarehouseRequestsProducts(prev => {
                      const copy = [...prev]
                      copy[index].product_name = item.name
                      copy[index].product_id = item.id
                      copy[index].stock_id = ''
                      copy[index].quantity = ''
                      return copy
                    })
                    setSelected(true)
                  }}
                >
                  {item.name}
                </li>
              ))}
        </ul>
      </div>
    </SearchContainer>
  )
}
