import React, { useEffect, useState } from 'react'
import Button from '../../../../../components/Button'
import Container from '../../../../../components/Container'
import Form, { Select } from '../../../../../components/Form'
import { Date as FiledDate } from '../../../../../components/Form/date'
import { useLoading } from '../../../../../hooks/loading'
import { useToast } from '../../../../../hooks/toast'
import api from '../../../../../services/api'
import { breadcrumbList } from '../domain/breadcrumb'
import { FilterContaier, ListTitle, Table } from './styles'
import TableHeader from '../components/Header'
import Search from '../components/Search'
import convertDateToISO from '../../../../../utlis/convertDateToISO'
import { format, addHours } from 'date-fns'

type FinancialTransaction = {
  id: number
  order_id: string
  order: {
    invoice_number_sequence: string
    delivery_date: string
    pick_up_date: string
  }
  client_name: string
  value: string
  due_date: string
  billing_date: string
  invoice_email: number
  invoice_email_date: string
  invoice_email_user: string
  isChecked: boolean
}
type User = {
  id: number
  old_user_id: number
  name: string
}
const ExpenseBudgetList = (): JSX.Element => {
  const [transactions, setTransactions] = useState<FinancialTransaction[]>([])
  const [searchTransactions, setSearchTransactions] = useState<
    FinancialTransaction[]
  >([])
  const [users, setUsers] = useState<User[]>([])
  const [startDate, setStartDate] = useState<Date>()
  const [finalDate, setFinalDate] = useState<Date>()
  const [situation, setSituation] = useState(0)
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()

  const headers = [
    {
      name: 'Nº Pedido',
      field: 'order_id',
      sortable: true
    },
    {
      name: 'Nº Fatura',
      field: 'order.invoice_number_sequence',
      sortable: true
    },
    {
      name: 'Cliente',
      field: 'client_name',
      sortable: true
    },
    {
      name: 'Faturamento',
      field: 'billing_date',
      sortable: true,
      type: 'date'
    },
    {
      name: 'Vigência',
      field: 'due_date',
      sortable: false
    },
    {
      name: 'Vencimento',
      field: 'due_date',
      sortable: true,
      type: 'date'
    },
    {
      name: 'Valor',
      field: 'value',
      sortable: true,
      type: 'monetary'
    },
    {
      name: 'Enviado',
      field: 'invoice_email',
      sortable: false
    },
    {
      name: 'Enviado em',
      field: 'invoice_email_data',
      sortable: true,
      type: 'date'
    },
    {
      name: 'Enviado por',
      field: 'invoice_email_user',
      sortable: true
    }
  ]
  const loadTransactions = async () => {
    activeLoading()
    const { data } = await api.get('financial/financialTransactions/notEmailed')
    setTransactions(data.financialTransactions)
    setSearchTransactions(data.financialTransactions)
    setUsers(data.users)
    disableLoading()
  }
  useEffect(() => {
    loadTransactions()
  }, [])

  const onSearch = async () => {
    let query = `?situation=${situation}`
    if (startDate && finalDate) {
      query += `&startDate=${startDate
        .toISOString()
        .substring(0, 10)}&finalDate=${finalDate
        .toISOString()
        .substring(0, 10)}`
    }
    const { data } = await api.get(
      `financial/financialTransactions/notEmailed${query}`
    )
    setTransactions(data.financialTransactions)
    setSearchTransactions(data.financialTransactions)
  }
  const sendMail = async () => {
    const ids = searchTransactions.filter(t => t.isChecked).map(t => t.id)
    activeLoading()
    try {
      await api.post('financial/financialTransactions/mail', {
        ids
      })
      addToast({
        type: 'success',
        title: 'Emails enviados!',
        description: 'Os emails foram enviados com sucessoo!'
      })
      onSearch()
      disableLoading()
    } catch (error: any) {
      const responseMessage = error?.response?.data?.message

      addToast({
        type: 'error',
        title: 'Error ao enviar os Emails.',
        description:
          responseMessage ||
          'Houve um error ao enviar os emails, tente novamente mais tarde.'
      })
      disableLoading()
    }
  }

  const onSearchItem = (value: string) => {
    activeLoading()
    const search = value.toLowerCase()
    if (!value) {
      setSearchTransactions(transactions)
    }
    setSearchTransactions(() => {
      const result = transactions.filter(f => {
        const client = f.client_name.toLowerCase()
        const order = String(f.order_id).toLowerCase()
        if (client.includes(search) || order.includes(search)) {
          return true
        }
        return false
      })
      return result
    })
    disableLoading()
  }
  return (
    <Container
      pageTitle={'Faturamento locação estendida'}
      portletTitle={'Filtro'}
      breadcrumb={breadcrumbList}
    >
      <FilterContaier>
        <Form onSubmit={onSearch}>
          <div className="col-md-2">
            <FiledDate
              label="Data inicial"
              className="form-control"
              name="start_date"
              onChange={date => setStartDate(date)}
              selected={startDate}
              controlled
            />
          </div>
          <div className="col-md-2">
            <FiledDate
              label="Data final"
              className="form-control"
              name="final_date"
              onChange={date => setFinalDate(date)}
              selected={finalDate}
              controlled
            />
          </div>
          <div className="col-md-2">
            <Select
              label="Situação"
              className="form-control"
              name="situation"
              options={[
                { name: 'Enviado', value: '1' },
                { name: 'Pendente', value: '0' }
              ]}
              onChange={e => setSituation(Number(e.target.value))}
              controlled
              blank
              rules={{ required: true }}
              defaultValue={''}
            />
          </div>
          <footer className="col-md-12">
            <button
              type="reset"
              onClick={() => {
                setStartDate(undefined)
                setFinalDate(undefined)
              }}
            >
              Limpar
            </button>
            <button type="submit">Buscar</button>
          </footer>
        </Form>
        <hr />
      </FilterContaier>
      <ListTitle>
        <p>Listagem</p>
        <div
          className="col-md-3"
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          <div className="">
            <div className="dataTables_filter">
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <p style={{ marginRight: '15px' }}>Pesquisar</p>
                <Search onSearch={onSearchItem} />
              </label>
            </div>
          </div>
        </div>
      </ListTitle>
      <hr style={{ marginBottom: '50px' }} />
      <Table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                name="all"
                id="all"
                onChange={({ target }) => {
                  setSearchTransactions(prev => {
                    const copy = JSON.parse(JSON.stringify(prev))
                    const checked = copy.map((c: FinancialTransaction) => ({
                      ...c,
                      isChecked: target.checked
                    }))
                    return checked
                  })
                }}
              />
            </th>
            <TableHeader
              headers={headers}
              onSorting={(field, order, type) => {
                const itemSorted = searchTransactions.sort((a: any, b: any) => {
                  const fields = field.split('.')
                  let currentFieldA
                  let currentFieldB
                  if (fields.length === 1) {
                    currentFieldA = a[fields[0]]
                    currentFieldB = b[fields[0]]
                  }
                  if (fields.length === 2) {
                    currentFieldA = a[fields[0]]?.[fields[1]]
                    currentFieldB = b[fields[0]]?.[fields[1]]
                  }
                  if (fields.length === 3) {
                    currentFieldA = a[fields[0]]?.[fields[1]]?.[fields[2]]
                    currentFieldB = b[fields[0]]?.[fields[1]]?.[fields[2]]
                  }
                  if (type === 'date') {
                    return new Date(
                      convertDateToISO(currentFieldA.substring(0, 10))
                    ) >
                      new Date(
                        convertDateToISO(currentFieldB.substring(0, 10))
                      ) && order === 'ASC'
                      ? 1
                      : -1
                  }
                  if (type === 'monetary') {
                    return Number(currentFieldA.replaceAll(/[',','.']/gi, '')) >
                      Number(currentFieldB.replaceAll(/[',','.']/gi, '')) &&
                      order === 'ASC'
                      ? 1
                      : -1
                  }
                  return currentFieldA > currentFieldB && order === 'ASC'
                    ? 1
                    : -1
                })
                setSearchTransactions([...itemSorted])
              }}
            />
          </tr>
        </thead>
        <tbody>
          {searchTransactions.map((t, index) => (
            <tr key={t.id}>
              <td>
                <input
                  type="checkbox"
                  name="all"
                  id="all"
                  checked={t.isChecked}
                  onChange={() => {
                    setSearchTransactions(prev => {
                      const copy = JSON.parse(JSON.stringify(prev))
                      copy[index].isChecked = !copy[index].isChecked
                      return copy
                    })
                  }}
                />
              </td>
              <td>{t.order_id}</td>
              <td>{t.order.invoice_number_sequence}</td>
              <td>{t.client_name}</td>
              <td>
                {t.billing_date.substring(0, 10).split('-').reverse().join('/')}
              </td>
              <td>
                {addHours(
                  new Date(
                    `${t.order.delivery_date
                      ?.substring(0, 10)
                      .split('/')
                      .reverse()
                      .join('-')} 21:00:00`
                  ),
                  3
                ).getDate()}
                /
                {addHours(
                  new Date(
                    `${t.order.delivery_date
                      ?.substring(0, 10)
                      .split('/')
                      .reverse()
                      .join('-')} 21:00:00`
                  ),
                  3
                ).getMonth() + 1}
                /
                {addHours(
                  new Date(
                    `${t.order.delivery_date
                      ?.substring(0, 10)
                      .split('/')
                      .reverse()
                      .join('-')} 21:00:00`
                  ),
                  3
                ).getFullYear()}
                {'  à '}
                {addHours(
                  new Date(
                    `${t.order.pick_up_date
                      ?.substring(0, 10)
                      .split('/')
                      .reverse()
                      .join('-')} 21:00:00`
                  ),
                  3
                ).getDate()}
                /
                {addHours(
                  new Date(
                    `${t.order.pick_up_date
                      ?.substring(0, 10)
                      .split('/')
                      .reverse()
                      .join('-')} 21:00:00`
                  ),
                  3
                ).getMonth() + 1}
                /
                {addHours(
                  new Date(
                    `${t.order.pick_up_date
                      ?.substring(0, 10)
                      .split('/')
                      .reverse()
                      .join('-')} 21:00:00`
                  ),
                  3
                ).getFullYear()}
              </td>
              <td>
                {t.due_date.substring(0, 10).split('-').reverse().join('/')}
              </td>
              <td>{`R$ ${t.value}`}</td>
              <td>{t.invoice_email ? 'Sim' : 'Não'}</td>
              <td>
                {t.invoice_email_date &&
                  format(new Date(t.invoice_email_date), 'dd/MM/yyyy HH:mm:ss')}
              </td>
              <td>
                {t.invoice_email_user &&
                  users.find(
                    u => Number(u.old_user_id) === Number(t.invoice_email_user)
                  )?.name}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="form-actions">
        <Button
          type="submit"
          className="btn dark btn-sm sbold uppercase"
          onClick={sendMail}
        >
          Enviar Email
        </Button>
      </div>
    </Container>
  )
}
export default ExpenseBudgetList
