export const headers = [
  { name: 'Cliente', field: 'client_name', sortable: true },
  { name: 'Tipo', field: 'type', sortable: true },
  { name: 'Pedido', field: 'order_id', sortable: true },
  { name: 'Vencimento', field: 'due_date', sortable: true, type: 'date' },
  { name: 'Pagamento', field: 'payment_date', sortable: true, type: 'date' },
  { name: 'Valor', field: 'value', sortable: true, type: 'monetary' },
  {
    name: 'Valor em aberto',
    field: 'open_value',
    sortable: true,
    type: 'monetary'
  },
  { name: 'Pago', field: 'paid', sortable: true },
  { name: 'Liberado', field: 'reconsidered', sortable: true },
  { name: 'Dias atrasado', field: 'days_delay', sortable: true },
  { name: 'Ações', field: 'actions', sortable: false }
]
