import React from 'react'
import { Container } from './style'

export type DetailsType = {
  name: string
  width: string
  height: string
  length: string
  weight: string
  thickness: string
  measure: string
  way_use: string
  old_product: string
  old_product_name: string
  measure_weight: string
  description_details: string
  technical_specification: string
}

type DetailsProps = {
  detail: DetailsType
}

export const Details = ({ detail }: DetailsProps): JSX.Element => {
  return (
    <Container>
      <div className="section">
        <div className="text-area row">
          <div className="form-content col-md-12 item">
            <label htmlFor="tipo do produto">Descrição e detalhes</label>
            <p>{detail.description_details}</p>
          </div>
        </div>
        <div className="text-area row">
          <div className="form-content col-md-12 item">
            <label htmlFor="tipo do produto">Especificação Técnica</label>
            <p>{detail.technical_specification}</p>
          </div>
        </div>
        <div className="text-area row">
          <div className="form-content col-md-12 item">
            <label htmlFor="tipo do produto">Forma de utilização</label>
            <p>{detail.way_use}</p>
          </div>
        </div>
      </div>
    </Container>
  )
}

export const labelDetails = 'Detalhe e medida'
export const nameDetails = '@@tabs-view-details'
