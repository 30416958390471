import React, { HTMLAttributes } from 'react'
import { Link } from 'react-router-dom'
import { useLoading } from '../../hooks/loading'
import { Loading } from '../Loading'
import { LinkContainer, ContainerWrapper } from './style'

interface Breadcrumb {
  name: string
  to?: string
}

type ParamsPush = {
  id: string
  value: string
}
export interface ToolsContainerProps {
  name: string
  to: string
  icon: string
  hasParams?: false | ParamsPush
  handleOnClick?: <T>(currentValue: T | any) => void
}

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  pageTitle: string
  portletTitle: string
  breadcrumb?: Breadcrumb[]
  tools?: ToolsContainerProps[]
  styleContent?: HTMLAttributes<HTMLDivElement>
  Content?: () => JSX.Element
}

const Container: React.FC<ContainerProps> = ({
  pageTitle,
  portletTitle,
  breadcrumb,
  tools,
  children,
  Content,
  ...props
}) => {
  const { loading } = useLoading()

  return (
    <ContainerWrapper {...props} className="page-content-wrapper">
      <Loading isActive={loading} />
      <div className="page-head">
        <div className="container-fluid">
          <div className="page-title">
            <h1>{pageTitle}</h1>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container-fluid">
          {breadcrumb && (
            <ul className="page-breadcrumb breadcrumb">
              {breadcrumb.map((bread, i) => (
                <li key={bread.name}>
                  {(bread.to && <Link to={bread.to}>{bread.name}</Link>) ||
                    bread.name}
                  {breadcrumb.length !== i + 1 && (
                    <i className="fa fa-circle" />
                  )}
                </li>
              ))}
            </ul>
          )}
          <div className="page-content-inner">
            <div className="mt-content-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="portlet light bordered">
                    {Content ? (
                      <Content />
                    ) : (
                      <>
                        <div className="portlet-title">
                          <div className="caption">{portletTitle}</div>
                          {tools && (
                            <LinkContainer className="tools">
                              {tools.map(tool => (
                                <Link
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                  key={Math.random()}
                                  to={tool.to}
                                  onClick={e => {
                                    if (tool.handleOnClick) {
                                      e.preventDefault()
                                      tool.handleOnClick(tool)
                                    }
                                  }}
                                >
                                  <i className={tool.icon} />
                                  <p>{tool.name}</p>
                                </Link>
                              ))}
                            </LinkContainer>
                          )}
                        </div>
                        <div className="portlet-body form">{children} </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerWrapper>
  )
}

export default Container
