import React, { useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Textarea } from '../../../../../components/Form'
import { Date } from '../../../../../components/Form/date'
import Button from '../../../../../components/Button'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { FormDataProtocol } from '../../domain/protocols'
import { apiCreate, apiUpdate } from '../../domain/api'
import { useProduct } from '../../providers/product/ProductProvider'
import { TypeComposition } from '../../domain/models/typeProduct'
import { HasCompositionTab } from '../HasComposition'
import { Campaign } from '../../Update'
import { genericMaskWithTwoZero, BRL } from '../../../../../utlis/mask'
import { SearchContainer } from './styles'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  campaign?: Campaign
  campaignItems?: TypeComposition[]
  isOpenInModal?: false | IsOpenInModalProps
  typeForm: 'create' | 'update'
  campaignId?: string
}

type Client = {
  id: number
  name: string
  isChecked: boolean
}
type Department = {
  id: number
  name: string
  isChecked: boolean
}

export const FormCategory = ({
  isOpenInModal,
  typeForm,
  campaignItems,
  campaign,
  campaignId
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { composition, setComposition } = useProduct()
  const [defaultValues, setDefaultValues] = useState<any>()
  const { activeLoading, disableLoading } = useLoading()
  const [departments, setDepartments] = useState<Department[]>([])
  const [departmentsSelected, setDepartmentsSelected] = useState<Department[]>(
    []
  )
  const [department, setDepartment] = useState('')
  const [departmentOpen, setDepartmentOpen] = useState(false)
  const [clients, setClients] = useState<Client[]>([])
  const [clientsSelected, setClientsSelected] = useState<Client[]>([])
  const [client, setClient] = useState('')
  const [clientIds, setClientIds] = useState([])
  const [clientOpen, setClientOpen] = useState(false)
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  useEffect(() => {
    ;(async () => {
      const departmentsFetch = await (
        await api.get('commercial/departments')
      ).data
      if (typeForm === 'update') {
        const departmentsIds: number[] = campaign?.department
          ? JSON.parse(campaign?.department)
          : []
        const clientsIds: number[] = campaign?.client
          ? JSON.parse(campaign?.client)
          : []
        setClientIds(clientsIds)
        const departments = departmentsFetch.map(
          (department: { id: any; name: any }) => ({
            id: department.id,
            name: department.name,
            isChecked: departmentsIds?.includes(department.id)
          })
        )
        setDepartments(departments)
      } else {
        const departments = departmentsFetch.map(
          (department: { id: any; name: any }) => ({
            id: department.id,
            name: department.name,
            isChecked: false
          })
        )
        setDepartments(departments)
      }
    })()
  }, [campaign, typeForm])

  useEffect(() => {
    if (!departments) return
    const departmentsSelected = departments.filter(d => d.isChecked)
    setDepartmentsSelected(departmentsSelected)
    const departmentsIds = departmentsSelected.map(a => a.id)
    if (!departmentsIds.length) {
      setClients([])
      return
    }
    ;(async () => {
      const clients = (
        await api.get(`commercial/clients?departments_ids="${departmentsIds}"`)
      ).data.map((client: { id: any; name: any }) => ({
        id: client.id,
        name: client.name,
        isChecked: clientIds.includes(client.id)
      }))
      setClients(clients)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments])

  useEffect(() => {
    if (!clients) return
    const clientsSelected = clients.filter(c => c.isChecked)
    setClientsSelected(clientsSelected)
  }, [clients])

  useEffect(() => {
    setClients(prev => {
      const copy = JSON.parse(JSON.stringify(prev))
      const newClients = copy.map((client: { id: any; name: any }) => ({
        id: client.id,
        name: client.name,
        isChecked: clientIds.includes(client.id)
      }))
      return newClients
    })
  }, [clientIds])

  useEffect(() => {
    if (campaignItems) {
      setComposition(campaignItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignItems])

  useEffect(() => {
    if (!campaign) return
    if (!campaignItems) return
    setDefaultValues({
      name: campaign.name,
      start_date: campaign.start_date,
      end_date: campaign.end_date,
      description: campaign.description
    })
  }, [campaign, campaignItems])

  const onSubmitForm = async (data: FormDataProtocol) => {
    activeLoading()
    const campaignItems = composition
      ?.map(item => ({
        stock_id: item.stock_id,
        min: item.min || undefined,
        max: item.max || undefined,
        promotion: BRL(genericMaskWithTwoZero(item.promotion)).value
      }))
      .filter(i => i)

    const departmentsIds = departments.filter(d => d.isChecked).map(a => a.id)
    const clientsIds = clients.filter(d => d.isChecked).map(a => a.id)
    const campaign = {
      name: data.name,
      department: JSON.stringify(departmentsIds),
      client: JSON.stringify(clientsIds),
      start_date: data.start_date,
      end_date: data.end_date,
      description: data.description,
      campaignItems
    }
    if (typeForm === 'create') {
      try {
        await api.post(apiCreate(), campaign)
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
        history.goBack()
      } catch (error: any) {
        let description =
          'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        if (
          error?.response?.data?.message ===
          'The campaign cannot be created with an end date less than the start date.'
        ) {
          description = 'A data final deve ser superior à data inicial.'
        }
        console.log(error?.response?.data?.message)
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description
        })
      }
    } else {
      try {
        await api.put(apiUpdate(campaignId), campaign)
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
        history.goBack()
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    }
    disableLoading()
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <>
        <div className="form-body">
          <div className="row">
            <div className="col-md-4">
              <Input
                name="name"
                className="form-control"
                label="Nome"
                rules={{ required: true }}
                style={{
                  paddingRight: '5px',
                  paddingLeft: '5px'
                }}
              />
            </div>
            <SearchContainer
              onBlur={e => {
                if (!e.currentTarget.contains(e.relatedTarget as Node)) {
                  setDepartmentOpen(false)
                }
              }}
              className="col-md-4"
            >
              <Input
                name="department"
                label="Segmentos"
                placeholder={
                  departmentsSelected.length
                    ? departmentsSelected.map(d => d.name).join(', ')
                    : 'Procurar segmentos'
                }
                className="form-control"
                onChange={({ target }) => {
                  setDepartment(target.value)
                  setDepartmentOpen(true)
                }}
                onClick={() => setDepartmentOpen(true)}
                controlled
              />
              <ul>
                {departmentOpen &&
                  departmentsSelected.length > 0 &&
                  departmentsSelected.map(item => (
                    <li key={item.id}>
                      <input
                        type="checkbox"
                        checked={item.isChecked}
                        onChange={() => {
                          setDepartments(prev => {
                            const copy = JSON.parse(JSON.stringify(prev))
                            const clicked = copy.find(
                              (a: { id: number }) => a.id === item.id
                            )
                            if (clicked) {
                              clicked.isChecked = !clicked.isChecked
                            }
                            return [...copy]
                          })
                        }}
                      />{' '}
                      {item.name}
                    </li>
                  ))}
                {departmentOpen &&
                  departments
                    .filter(f => f.name?.toLowerCase().includes(department))
                    .map(
                      item =>
                        !item.isChecked && (
                          <li key={item.id}>
                            <input
                              type="checkbox"
                              checked={item.isChecked}
                              onChange={() => {
                                setDepartments(prev => {
                                  const copy = JSON.parse(JSON.stringify(prev))
                                  const clicked = copy.find(
                                    (a: { id: number }) => a.id === item.id
                                  )
                                  if (clicked) {
                                    clicked.isChecked = !clicked.isChecked
                                  }
                                  return [...copy]
                                })
                              }}
                            />{' '}
                            {item.name}
                          </li>
                        )
                    )}
              </ul>
            </SearchContainer>
            <SearchContainer
              onBlur={e => {
                if (!e.currentTarget.contains(e.relatedTarget as Node)) {
                  setClientOpen(false)
                }
              }}
              className="col-md-4"
            >
              <Input
                name="client"
                label="Clientes"
                placeholder={
                  clientsSelected.length
                    ? clientsSelected.map(d => d.name).join(', ')
                    : 'Procurar clientes'
                }
                className="form-control"
                onChange={({ target }) => {
                  setClient(target.value)
                  setClientOpen(true)
                }}
                onClick={() => setClientOpen(true)}
                controlled
              />
              <ul>
                {clientOpen &&
                  clientsSelected.length > 0 &&
                  clientsSelected.map(item => (
                    <li key={item.id}>
                      <input
                        type="checkbox"
                        checked={item.isChecked}
                        onChange={() => {
                          if (!item.isChecked) {
                            setClientIds(prev => [...prev, item.id])
                          } else {
                            setClientIds(prev => {
                              const copy = [...prev]
                              const clientIdIndex = copy.indexOf(item.id)
                              if (clientIdIndex !== -1) {
                                copy.splice(clientIdIndex, 1)
                              }
                              return copy
                            })
                          }
                        }}
                      />{' '}
                      {item.name}
                    </li>
                  ))}
                {clientOpen &&
                  clients
                    .filter(f => f.name?.toLowerCase().includes(client))
                    .map(
                      item =>
                        !item.isChecked && (
                          <li key={item.id}>
                            <input
                              type="checkbox"
                              checked={item.isChecked}
                              onChange={() => {
                                if (!item.isChecked) {
                                  setClientIds(prev => [...prev, item.id])
                                } else {
                                  setClientIds(prev => {
                                    const copy = [...prev]
                                    const clientIdIndex = copy.indexOf(item.id)
                                    if (clientIdIndex !== -1) {
                                      copy.splice(clientIdIndex, 1)
                                    }
                                    return copy
                                  })
                                }
                              }}
                            />{' '}
                            {item.name}
                          </li>
                        )
                    )}
              </ul>
            </SearchContainer>
          </div>
          <div className="row"></div>
          <div className="row">
            <div className="col-md-2">
              <Date
                label="Data inicial"
                className="form-control"
                rules={{ required: true }}
                name="start_date"
                onChange={date => setStartDate(date)}
                selected={startDate}
                controlled
              />
            </div>

            <div className="col-md-2">
              <Date
                label="Data final"
                className="form-control"
                rules={{ required: true }}
                name="end_date"
                onChange={date => setEndDate(date)}
                selected={endDate}
                controlled
              />
            </div>
          </div>

          <div className="row">
            <div className="form-content col-md-12">
              <div className="form-group">
                <Textarea
                  label="Descrição"
                  className="form-control"
                  rules={{ required: true }}
                  name="description"
                  rows={6}
                  style={{
                    paddingRight: '5px',
                    paddingLeft: '5px'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {isOpenInModal && typeForm === 'create' ? (
          <hr className="divider" />
        ) : (
          <div style={{ margin: '10px 0' }} />
        )}
        <div className="form-actions right">
          {isOpenInModal && (
            <button
              onClick={isOpenInModal.handleOnClose}
              type="reset"
              className="btn btn-default btn-sm sbold uppercase"
            >
              Fechar
            </button>
          )}
          <Button type="submit" className="btn dark btn-sm sbold uppercase">
            Salvar
          </Button>
        </div>
      </>
      {HasCompositionTab()}
    </Form>
  )
}
