import React from 'react'
import { Container, FooterStyled, IconRemove } from './style'

import { Input, Select } from '../../../../../../../../../../components/Form'
import { SearchComponent } from '../SearchComponent'
import { TooltipComponent } from '../../../../../../../../../../components/TooltipComponent'
import currency from 'currency.js'
import { genericMaskWithTwoZero } from '../../../../../../../../../../utlis/mask'
import { TypeProductStock } from '../../../../../../domain/models/typeProduct'

type ProductListType = {
  id: number
  name: string
}
type StockListType = {
  id: number
  name: string
  product?: {
    id: number
    name: string
  }
}
const BRL = (value: any) =>
  currency(value, {
    symbol: '',
    decimal: ',',
    separator: '.'
  })

type Params = {
  stockIndex: number
  stock: TypeProductStock[]
  setStock: React.Dispatch<React.SetStateAction<TypeProductStock[]>>
  productList: ProductListType[]
  stocksList: StockListType[]
  setProductList: React.Dispatch<React.SetStateAction<ProductListType[]>>
  selected: boolean
  setSelected: React.Dispatch<React.SetStateAction<boolean>>
  usedProducts: number[]
  setUsedProducts: React.Dispatch<React.SetStateAction<number[]>>
  productType: string
}

export const CompositionTable = ({
  stockIndex,
  stock,
  setStock,
  productList,
  stocksList,
  setProductList,
  selected,
  setSelected,
  usedProducts,
  productType
}: Params): JSX.Element => {
  return (
    <td colSpan={100}>
      <h4 style={{ textAlign: 'left' }}>Composição</h4>
      <Container className="table-responsive">
        <div className="table-content-relative">
          <table className="table table-bordered margin-bottom-0">
            <tbody>
              <tr>
                <th style={{ width: '30%' }}>Produto</th>
                <th style={{ width: '30%' }}>Variação</th>
                <th>
                  {' '}
                  <TooltipComponent
                    label={
                      productType === 'semi-acabado' ? 'Fração' : 'Quantidade'
                    }
                    message="Quantidade necessária do produto para essa composição. O valor poderá ser fracionado."
                    bold
                  />
                </th>
                <th>Custo</th>
                <th>Subtotal</th>
                <th>Ações</th>
              </tr>
              {stock[stockIndex].composition &&
                stock[stockIndex].composition?.map(
                  (comp, index) =>
                    comp && (
                      <tr
                        key={index}
                        style={{
                          height: '10px'
                        }}
                      >
                        <td>
                          {SearchComponent({
                            index,
                            stockIndex,
                            stock,
                            setStock,
                            productList,
                            selected,
                            setSelected,
                            usedProducts
                          })}
                        </td>
                        <td>
                          <Select
                            name={`stock.${stockIndex}.composition.${index}.stock_id`}
                            className="form-control"
                            options={stocksList
                              .filter(
                                a =>
                                  a.product?.id ===
                                  stock[stockIndex].composition[index]
                                    .product_id
                              )
                              .map(a => ({
                                name: a.name,
                                value: String(a.id)
                              }))}
                            onChange={({ target }) => {
                              setStock(prev => {
                                const copy = [...prev]
                                copy[stockIndex].composition[index].name =
                                  stocksList.find(
                                    s => s.id === Number(target.value)
                                  )?.name
                                return copy
                              })
                            }}
                            blank
                            defaultValue={''}
                            controlled
                          />
                          <Input
                            name={`stock.${stockIndex}.composition.${index}.name`}
                            value={stock[stockIndex].composition[index].name}
                            type="hidden"
                            controlled
                          />
                        </td>
                        <td>
                          <Input
                            name={`stock.${stockIndex}.composition.${index}.amount`}
                            placeholder="0,00"
                            className="form-control"
                            type="text"
                            onChange={({ target }) => {
                              setStock(prev => {
                                const copy = [...prev]
                                copy[stockIndex].composition[index].amount =
                                  genericMaskWithTwoZero(target.value)
                                return copy
                              })
                            }}
                            value={stock[stockIndex].composition[index].amount}
                            controlled
                          />
                        </td>
                        <td>
                          <Input
                            name={`stock.${stockIndex}.composition.${index}.cost`}
                            placeholder="0,00"
                            className="form-control"
                            type="text"
                            onChange={({ target }) =>
                              setStock(prev => {
                                const copy = [...prev]
                                copy[stockIndex].composition[index].cost =
                                  genericMaskWithTwoZero(target.value)
                                return copy
                              })
                            }
                            // value={composition[index].cost}
                            value="0,00"
                            readOnly={true}
                            controlled
                          />
                        </td>
                        <td>
                          <Input
                            name={`stock.${stockIndex}.composition.${index}.subtotal`}
                            placeholder="0.00"
                            className="form-control"
                            type="text"
                            value={BRL(comp.amount)
                              .multiply(BRL(comp.cost))
                              .format()}
                            controlled
                            disabled
                          />
                        </td>
                        <td className="actions">
                          <IconRemove
                            onClick={() =>
                              setStock(prev => {
                                const copy = [...prev]
                                copy[stockIndex].composition.splice(index, 1)
                                return copy
                              })
                            }
                          />
                        </td>
                      </tr>
                    )
                )}
            </tbody>
          </table>
        </div>
        <FooterStyled>
          <button
            type="button"
            onClick={() =>
              setStock(prev => {
                const copy = [...prev]
                copy[stockIndex].composition = [
                  ...copy[stockIndex].composition,
                  {
                    amount: 0,
                    cost: 0,
                    name: '',
                    product_name: '',
                    subtotal: 0,
                    stock_id: '',
                    product_id: 0
                  }
                ]
                return copy
              })
            }
            className="btn dark btn-sm sbold uppercase"
          >
            <span
              className="fa fa-plus"
              aria-hidden="true"
              style={{ marginRight: '5px' }}
            />
            Produto de Composição
          </button>
          <div>
            <h4>Total</h4>
            {/*             <h6>{total}</h6>
             */}
          </div>
        </FooterStyled>
      </Container>
    </td>
  )
}
