import React, { useEffect, useState } from 'react'
import Container from '../../../../../components/Container'
import { namePageTitle, OrdersExpeditionLogs } from '../domain/info'
import { apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import { toolsViewList } from '../domain/tools'
import { useHistory, useParams } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import api from '../../../../../services/api'
import { FormSituation } from '../components/FormSituation'
import Modal from '../../../../../components/Modal'
import Tabs from '../../../../../components/Tabs'
import Tab from '../../../../../components/Tabs/Tab'
import CustomDataTable from '../../../../../components/CustomDataTable'
import { logsHeaders, productsHeaders } from '../domain/headers'
import moment from 'moment'

const View: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [logs, setLogs] = useState<OrdersExpeditionLogs[]>([])
  const [statusList, setStatusList] = useState<any[]>([])
  const [order, setOrder] = useState<any>()
  const [products, setProducts] = useState<any[]>([])
  const [modal, setModal] = useState(false)
  const { addToast } = useToast()

  const { disableLoading, activeLoading } = useLoading()

  const loadOrderExpedition = async () => {
    activeLoading()
    try {
      const response = await api.get(apiList(id))
      const { data } = response
      setLogs(data.logs)
      setStatusList(data.statusList)
      setOrder(data.order)
      setProducts(data.products)
      disableLoading()
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Requisição não encontrada.'
      })
      disableLoading()
      history.goBack()
    }
  }

  useEffect(() => {
    loadOrderExpedition()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={'Visualizar'}
        breadcrumb={breadcrumbView}
        tools={[toolsViewList()]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Nº Pedido
                </label>
                <p>{id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Data do pedido
                </label>
                <p>
                  {order?.date
                    ? moment(order.date, 'DD/MM/YYYY HH:mm:ss')
                        .add({ hour: 3 })
                        .format('DD/MM/YYYY')
                    : ''}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Status do pedido
                </label>
                <p>{order?.situation}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Cliente
                </label>
                <p>{order?.client?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Data entrega
                </label>
                <p>
                  {order?.delivery_date
                    ? moment(order.delivery_date, 'DD/MM/YYYY HH:mm:ss')
                        .add({ hour: 3 })
                        .format('DD/MM/YYYY')
                    : ''}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Transportadora
                </label>
                <p>{order?.carrier?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Representante
                </label>
                <p>{order?.agent?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Status expedição
                </label>
                <p>{order?.expedition?.name}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="portlet light" style={{ paddingTop: '0px' }}>
            <div className="portlet-title">
              <div className="caption">Produtos</div>
            </div>
            <CustomDataTable customItems={products} headers={productsHeaders} />
          </div>
        </div>
        <Tabs>
          {[
            <Tab key={0} title="Histórico">
              <div className="portlet light">
                <div className="portlet-title">
                  <div className="caption">Listagem</div>
                  <div className="tools">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        color: '#333'
                      }}
                    >
                      <span
                        onClick={() => {
                          setModal(true)
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <span
                          className="fa fa-edit"
                          style={{ marginRight: '3px' }}
                        ></span>
                        Alterar Status
                      </span>
                    </div>
                  </div>
                </div>
                <div className="portlet-body form">
                  <CustomDataTable
                    customItems={logs.sort((a, b) => b.id - a.id)}
                    headers={logsHeaders}
                    customHeaders={[
                      {
                        field: 'expiration',
                        name: 'Status expiração',
                        sortable: false,
                        element: item => (
                          <span>{item.expiration?.substring(0, 10)}</span>
                        )
                      }
                    ]}
                    search
                    pagination
                  />
                </div>
              </div>
            </Tab>
          ]}
        </Tabs>
      </Container>
      {modal && (
        <Modal
          onClickButtonCancel={() => setModal(false)}
          isOpenModal={modal}
          pageTitle={'Alterar status'}
          styles={{
            padding: 0,
            marginTop: 0
          }}
          sectionStyle={{
            minWidth: 'auto'
          }}
          Children={
            <FormSituation
              received={false}
              setModal={setModal}
              loadOrderExpedition={loadOrderExpedition}
              statusList={statusList}
              currentStatus={order?.expedicao_status_id}
              id={Number(id)}
            />
          }
        />
      )}
    </>
  )
}
export default View
