import React, { useCallback, useEffect, useState } from 'react'
import { ControlPanel } from './style'
import { BoardOfMembers } from './components/BoardOfMembers'
import { HeaderPortlet } from './components/HeaderPortlet'
import Container from '../../../components/Container'
import api from '../../../services/api'
import { useLoading } from '../../../hooks/loading'
import currency from 'currency.js'
import { FaUserCircle } from 'react-icons/fa'
import { useAuth } from '../../../hooks/auth'
import { genericMaskWithTwoZeroWithPoint } from '../../../utlis/mask'
import { useToast } from '../../../hooks/toast'
import { Alert } from '../../../components/Alert'

const CommissionRelease: React.FC = () => {
  const { user } = useAuth()
  const [activeMembers, setActiveMembers] = useState([])
  const [yearMonth, setYearMonth] = useState('')
  const [selectedDate, setSelectedDate] = useState('Mes')
  const [financialModalIsOpen, setFinancialModalIsOpen] = useState(false)

  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()
  const BRL = (value: any) =>
    currency(value, {
      symbol: '',
      decimal: ',',
      separator: '.'
    })

  const saveData = useCallback(
    async (activeMembers?: any[]) => {
      const goalsEmployees = activeMembers.map(goals => ({
        id: goals.id,
        name: goals.fullName,
        employee_id: goals.employee_id,
        goal_id: goals.goal_id,
        month: goals.month,
        goal_achieved: BRL(goals?.goalAchieved).value,
        commission_percentage: BRL(goals?.commissionPercentage).value,
        generated_commission: BRL(goals.commission).value,
        adicional_award: BRL(goals.adicionalAward).value,
        total_commission: BRL(goals.total).value,
        financial: goals.financial,
        orders: goals.orders.split(',').filter((o: string) => o),
        due_date: activeMembers[0].due_date,
        order_type: goals.order_type
      }))
      try {
        await api.put('commercial/goalsEmployees/updateCommission', {
          goalsEmployees
        })
        addToast({
          type: 'success',
          title: 'Comissões atualizadas',
          description: 'Comissões alteradas com sucesso'
        })
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar as comissões',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    },
    [addToast]
  )

  const loadData = useCallback(
    async (date?: string) => {
      activeLoading()
      const selectDate = date || 'Mes'
      let selectPeriod: any
      if (selectDate === 'Mes') {
        selectPeriod = 'month'
      }
      const order = await api.get(
        `/commercial/goalsEmployees/Mes/${
          user.role_id === 1 ? undefined : user.employee.agent_id
        }`
      )
      const data = order.data.agentGraphicData
        ?.flatMap((item: any) =>
          item.agent_id > 0 ? [item, item.team].flat() : item.team
        )
        .filter((item: any) => item)
      const dia1 = new Date(`${order.data.year}-${order.data.month}-1`).getDay()
      const monthIndex = new Date().getMonth() + 1
      const year = new Date().getFullYear()
      const month = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`
      let dueDate = `${year}-${month}-05`
      if (dia1 === 7) {
        dueDate = `${year}-${month}-06`
      } else {
        dueDate = `${year}-${month}-07`
      }
      setYearMonth(dueDate)

      const members = data.map((item: any) => {
        const splitedName = item.employee_name.split(' ')
        const name = `${splitedName[0]} ${splitedName[splitedName.length - 1]}`
        const exist = order.data.goalsEmployees.find(
          (goal: { employee_id: any; month: any; goal_id: any }) =>
            goal.employee_id === item.employee_id &&
            goal.month === order.data.month &&
            goal.goal_id === order.data.goal_id
        )
        const currentMonth = Number(order.data.month)
        const lastMonth =
          currentMonth === 1 ? '12' : String(currentMonth - 1).padStart(2, '0')
        const currentYear = Number(order.data.year)
        const lastYear =
          currentMonth === 1 ? String(currentYear - 1) : String(currentYear)
        const existLastMonth = order.data.goalsEmployees.find(
          (goal: { employee_id: any; month: any; goal_id: any; goal: any }) =>
            goal.employee_id === item.employee_id &&
            goal.month === lastMonth &&
            goal.goal_id === order.data.goal_id &&
            String(goal.goal.year) === lastYear
        )
        if (item.total_team) {
          return {
            ...item,
            id: exist?.id,
            name,
            fullName: item.employee_name,
            avatar_url: FaUserCircle,
            goal_id: order.data.goal_id,
            month: order.data.month,
            goal: BRL(item.total_team[selectPeriod]?.goal || 0).format(),
            goalAchieved: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.goal_achieved)
              : BRL(item.total_team[selectPeriod].total_profit || 0).format(),
            commissionPercentage:
              genericMaskWithTwoZeroWithPoint(exist?.commission_percentage) ||
              genericMaskWithTwoZeroWithPoint(
                existLastMonth?.commission_percentage
              ) ||
              '0,00',
            commission: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.generated_commission)
              : BRL(item.total_team[selectPeriod].total_profit || 0)
                  .multiply(exist?.commission_percentage || 0)
                  .divide(100)
                  .format(),
            adicionalAward:
              genericMaskWithTwoZeroWithPoint(exist?.adicional_award) ||
              genericMaskWithTwoZeroWithPoint(
                existLastMonth?.adicional_award
              ) ||
              '0,00',
            total: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.total_commission)
              : BRL(item.total_team[selectPeriod].total_profit || 0)
                  .multiply(exist?.commission_percentage || 0)
                  .divide(100)
                  .add(
                    genericMaskWithTwoZeroWithPoint(exist?.adicional_award) ||
                      genericMaskWithTwoZeroWithPoint(
                        existLastMonth?.adicional_award
                      ) ||
                      '0,00'
                  )
                  .format(),
            financial: false,
            financial_moviment_id: exist?.financial_moviment_id,
            orders: item.total_team[selectPeriod]?.orders,
            due_date: dueDate,
            order_type: item.order_type
          }
        } else {
          return {
            ...item,
            id: exist?.id,
            name,
            fullName: item.employee_name,
            avatar_url: FaUserCircle,
            goal_id: order.data.goal_id,
            month: order.data.month,
            goal: BRL(item[selectPeriod]?.goal || 0).format(),
            goalAchieved: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.goal_achieved)
              : BRL(item[selectPeriod]?.total_profit || 0).format(),
            commissionPercentage:
              genericMaskWithTwoZeroWithPoint(exist?.commission_percentage) ||
              genericMaskWithTwoZeroWithPoint(
                existLastMonth?.commission_percentage
              ) ||
              '0,00',
            commission: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.generated_commission)
              : BRL(item[selectPeriod]?.total_profit || 0)
                  .multiply(exist?.commission_percentage || 0)
                  .divide(100)
                  .format(),
            adicionalAward:
              genericMaskWithTwoZeroWithPoint(exist?.adicional_award) ||
              genericMaskWithTwoZeroWithPoint(
                existLastMonth?.adicional_award
              ) ||
              '0,00',
            total: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.total_commission)
              : BRL(item[selectPeriod]?.total_profit || 0)
                  .multiply(exist?.commission_percentage || 0)
                  .divide(100)
                  .add(
                    genericMaskWithTwoZeroWithPoint(exist?.adicional_award) ||
                      genericMaskWithTwoZeroWithPoint(
                        existLastMonth?.adicional_award
                      ) ||
                      '0,00'
                  )
                  .format(),
            financial: false,
            financial_moviment_id: exist?.financial_moviment_id,
            orders: item[selectPeriod]?.orders,
            due_date: dueDate,
            order_type: item.order_type
          }
        }
      })
      setActiveMembers(members)
      await saveData(members)
      disableLoading()
    },
    [activeLoading, disableLoading, saveData, user]
  )

  const handleSetDate = useCallback(
    async (date?: string, update = true) => {
      setSelectedDate(date)
      const selectDate = date
      let selectPeriod: any
      if (
        selectDate === 'Mes' ||
        selectDate === 'Mês' ||
        selectDate.includes('period')
      ) {
        selectPeriod = 'month'
      }
      activeLoading()
      const order = await api.get(
        `/commercial/goalsEmployees/${date}/${
          user.role_id === 1 ? undefined : user.employee.agent_id
        }`
      )
      const data = order.data.agentGraphicData
        ?.flatMap((item: any) =>
          item.agent_id > 0 ? [item, item.team].flat() : item.team
        )
        .filter((item: any) => item)
      const dia1 = new Date(`${order.data.year}-${order.data.month}-1`).getDay()
      const monthIndex = new Date().getMonth() + 1
      const year = new Date().getFullYear()
      const month = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`
      let dueDate = `${year}-${month}-05`
      if (dia1 === 7) {
        dueDate = `${year}-${month}-06`
      } else {
        dueDate = `${year}-${month}-07`
      }
      setYearMonth(dueDate)
      const members = data.map((item: any) => {
        const splitedName = item.employee_name.split(' ')
        const name = `${splitedName[0]} ${splitedName[splitedName.length - 1]}`
        const exist = order.data.goalsEmployees.find(
          (goal: { employee_id: any; month: any; goal_id: any }) =>
            goal.employee_id === item.employee_id &&
            goal.month === order.data.month &&
            goal.goal_id === order.data.goal_id
        )
        if (item.total_team) {
          return {
            ...item,
            id: exist?.id,
            name,
            fullName: item.employee_name,
            avatar_url: FaUserCircle,
            goal_id: order.data.goal_id,
            month: order.data.month,
            goal: BRL(item.total_team[selectPeriod]?.goal || 0).format(),
            goalAchieved: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.goal_achieved)
              : BRL(item.total_team[selectPeriod].total_profit || 0).format(),
            commissionPercentage:
              genericMaskWithTwoZeroWithPoint(exist?.commission_percentage) ||
              '0,00',
            commission: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.generated_commission)
              : BRL(item.total_team[selectPeriod].total_profit || 0)
                  .multiply(exist?.commission_percentage || 0)
                  .divide(100)
                  .format(),
            adicionalAward:
              genericMaskWithTwoZeroWithPoint(exist?.adicional_award) || '0,00',
            total: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.total_commission)
              : BRL(item.total_team[selectPeriod].total_profit || 0)
                  .multiply(exist?.commission_percentage || 0)
                  .divide(100)
                  .add(
                    genericMaskWithTwoZeroWithPoint(exist?.adicional_award) ||
                      '0,00'
                  )
                  .format(),
            financial: false,
            financial_moviment_id: exist?.financial_moviment_id,
            orders: item.total_team[selectPeriod]?.orders,
            due_date: dueDate,
            order_type: item.order_type
          }
        } else {
          return {
            ...item,
            id: exist?.id,
            name,
            fullName: item.employee_name,
            avatar_url: FaUserCircle,
            goal_id: order.data.goal_id,
            month: order.data.month,
            goal: BRL(item[selectPeriod]?.goal || 0).format(),
            goalAchieved: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.goal_achieved)
              : BRL(item[selectPeriod]?.total_profit || 0).format(),
            commissionPercentage:
              genericMaskWithTwoZeroWithPoint(exist?.commission_percentage) ||
              '0,00',
            commission: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.generated_commission)
              : BRL(item[selectPeriod]?.total_profit || 0)
                  .multiply(exist?.commission_percentage || 0)
                  .divide(100)
                  .format(),
            adicionalAward:
              genericMaskWithTwoZeroWithPoint(exist?.adicional_award) || '0,00',
            total: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.total_commission)
              : BRL(item[selectPeriod]?.total_profit || 0)
                  .multiply(exist?.commission_percentage || 0)
                  .divide(100)
                  .add(
                    genericMaskWithTwoZeroWithPoint(exist?.adicional_award) ||
                      '0,00'
                  )
                  .format(),
            financial: false,
            financial_moviment_id: exist?.financial_moviment_id,
            orders: item[selectPeriod]?.orders,
            due_date: dueDate,
            order_type: item.order_type
          }
        }
      })
      setActiveMembers(members)
      if (update) {
        await saveData(members)
      }

      disableLoading()
    },
    [activeLoading, disableLoading, user, saveData]
  )

  useEffect(() => {
    activeLoading()
    loadData()
  }, [activeLoading, loadData])

  async function handleFinancialConfirmButton() {
    try {
      await saveData(activeMembers)
      handleSetDate(selectedDate, false)
      setFinancialModalIsOpen(false)
    } catch (error) {
      setFinancialModalIsOpen(false)
    }
  }

  async function handleSaveButton() {
    let hasFinancial = false
    activeMembers.forEach(goals => {
      if (goals.financial) {
        hasFinancial = true
      }
    })
    if (hasFinancial) {
      setFinancialModalIsOpen(true)
      return
    }
    await saveData(activeMembers)
    handleSetDate(selectedDate, false)
  }

  return (
    <>
      <Container
        pageTitle={'Lançamento de comissão'}
        portletTitle={''}
        breadcrumb={[
          {
            name: 'Início',
            to: '/'
          },
          {
            name: 'comercial'
          },
          {
            name: 'Lançamento de comissão'
          }
        ]}
        tools={[]}
      >
        <ControlPanel>
          <div
            className="row"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <HeaderPortlet
              activeMembersData={activeMembers}
              handleSetDate={handleSetDate}
              actions={['Mês Atual', 'Selecione o Mês']}
              mainTitle=""
            />
          </div>
          <BoardOfMembers
            activeMembersData={activeMembers}
            setActiveMembersData={setActiveMembers}
            handleSetDate={handleSetDate}
            selectedDate={selectedDate}
            tableHead={[
              {
                colSpan: 2,
                name: 'Membro'
              },
              {
                name: 'Meta'
              },
              {
                name: 'Meta Realizada'
              },
              {
                name: 'Percentual de Comissão'
              },
              {
                name: 'Comissão'
              },
              {
                name: 'Premiação Adicional'
              },
              {
                name: 'Total'
              },
              {
                name: 'Financeiro'
              }
            ]}
          />
          {activeMembers.length > 0 && (
            <div
              className="col-md-12"
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <button
                className="btn dark btn-sm bold uppercase"
                onClick={handleSaveButton}
              >
                Salvar
              </button>
            </div>
          )}
        </ControlPanel>
      </Container>
      <Alert
        RenderComponent={() => (
          <>
            <h4>Deseja realmente lançar esses valores no financeiro? </h4>
            <br />
            {activeMembers?.map(
              ({ name, financial, total }) =>
                financial && (
                  <div key={name}>
                    <p key={name}>
                      {name} - R$ {total}
                    </p>
                  </div>
                )
            )}
            <div
              style={{
                display: 'flex',
                marginBottom: '1em',
                alignItems: 'baseline',
                marginTop: '1em'
              }}
            >
              <label htmlFor="date">Data de pagamento</label>
              <input
                style={{ marginLeft: '8px', width: '200px' }}
                className="form-control"
                type="date"
                name="date"
                id="date"
                defaultValue={yearMonth}
                onChange={e => {
                  const members = [...activeMembers]
                  members[0].due_date = e.target.value
                }}
              />
            </div>
          </>
        )}
        onClickCancellButton={() => setFinancialModalIsOpen(false)}
        onClickConfirmButton={handleFinancialConfirmButton}
        isActive={financialModalIsOpen}
      />
    </>
  )
}

export default CommissionRelease
