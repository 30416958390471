import {
  faTruck,
  faBoxes,
  faClipboardCheck,
  faWarehouse
} from '@fortawesome/free-solid-svg-icons'
import { faClipboard, faClock } from '@fortawesome/free-regular-svg-icons'
import { useEffect, useState } from 'react'
import api from '../../services/api'

type DashboardValues = {
  stock: number
  pending_stock: number
}

export default function DashboardData() {
  const [data, setData] = useState<DashboardValues>()

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get<DashboardValues>('/dashboard')
      setData(data)
    })()
  }, [])

  const controlsData = [
    {
      name: 'ALMOXARIFADO',
      items: [
        {
          number: 0,
          text: 'SEPARAR REQUISIÇÃO',
          textFooter: 'pendente',
          valueSubtitleFooter: 0,
          link: 'warehouse/stocks',
          icon: faClipboard
        },
        {
          number: data ? Number(data.stock) : 0,
          text: 'ESTOQUE',
          textFooter: 'pendente',
          valueSubtitleFooter: data ? Number(data.pending_stock) : 0,
          link: 'warehouse/stocks?empty=true',
          footerLink: 'warehouse/stocks?pending=true',
          icon: faWarehouse
        }
      ]
    },
    {
      name: 'EXPEDIÇÃO',
      items: [
        {
          number: 0,
          text: 'SEPARAR PEDIDO',
          textFooter: 'pendente',
          valueSubtitleFooter: 0,
          link: 'warehouse/pickOrder',
          icon: faClipboard
        },
        {
          number: 0,
          text: 'CONFERÊNCIA',
          textFooter: 'pendente',
          valueSubtitleFooter: 0,
          link: '#',
          icon: faClipboardCheck
        },
        {
          number: 0,
          text: 'SOLICITAR COLETA',
          textFooter: 'pendente',
          valueSubtitleFooter: 0,
          link: '#',
          icon: faBoxes
        },
        {
          number: 0,
          text: 'PEDIDO COLETADO',
          textFooter: 'pendente',
          valueSubtitleFooter: 0,
          link: '#',
          icon: faTruck
        },
        {
          number: 0,
          text: 'AGUARDANDO COMPRA',
          textFooter: 'pendente',
          valueSubtitleFooter: 0,
          link: '#',
          icon: faClock
        }
      ]
    }
    /*     {
      name: 'AGUARDANDO COMPRA',
      items: [
        {
          number: 0,
          text: 'AGUARDANDO COMPRA',
          textFooter: 'pendente',
          valueSubtitleFooter: 0,
          link: '#',
          icon: faEdit
        },
        {
          number: 0,
          text: 'AGUARDANDO FRETE',
          textFooter: 'pendente',
          valueSubtitleFooter: 0,
          link: '#',
          icon: faEdit
        },
        {
          number: 0,
          text: 'AGUARDANDO SERVIÇO',
          textFooter: 'pendente',
          valueSubtitleFooter: 0,
          link: '#',
          icon: faEdit
        }
      ]
    }
 */
  ]
  return [controlsData]
}
