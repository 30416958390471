import styled, { css } from 'styled-components'
import { MdClose } from 'react-icons/md'

export const Container = styled.div<{ isActive: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  display: flex;
  flex-direction: column;
  ${({ isActive }) =>
    !isActive &&
    css`
      display: none;
    `}
  > main {
    @media (min-width: 768px) {
      width: 600px;
    }
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
    h4 {
      margin: 0px;
      padding: 15px;
      opacity: 0.6;
    }
    p {
      padding: 15px;
    }
    > hr {
      margin: 0;
    }

    > footer {
      display: flex;
      justify-content: flex-end;
      padding: 15px;

      > button {
        width: 80px !important;
        margin-left: 10px;
      }
    }
  }
  main {
    position: relative;
  }
`

export const InconClose = styled(MdClose).attrs(() => ({
  color: '#333333'
}))`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
    transition: all 0.2s;
  }
  z-index: 999;
`
