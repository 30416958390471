import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from '../../../../components/Button'
import Container from '../../../../components/Container'
import Form, { Select } from '../../../../components/Form'
import { Date } from '../../../../components/Form/date'
import { useLoading } from '../../../../hooks/loading'
import { useToast } from '../../../../hooks/toast'
import api from '../../../../services/api'
import TableHeader from '../components/Header'
import { breadcrumbList } from '../domain/breadcrumb'
import { headersCreate } from '../domain/headers'
import { toolsCreate } from '../domain/tools'
import { FilterContaier, ListTitle, Table } from './styles'

type FinancialTransaction = {
  id: number
  order_id: string
  name: string
  payment_form: string
  notes: string
  category_name: string
  supplier_name: string
  value: string
  due_date: string
  isChecked: boolean
  disabled: boolean
}
type Company = {
  id: number
  name: string
}

type Bank = {
  id: number
  name: string
}

const PaymentRemittanceFile = (): JSX.Element => {
  const [transactions, setTransactions] = useState<FinancialTransaction[]>([])
  const [companies, setCompanies] = useState<Company[]>([])
  const [banks, setBanks] = useState<Bank[]>([])
  const [startDate, setStartDate] = useState<Date>()
  const [finalDate, setFinalDate] = useState<Date>()

  const history = useHistory()
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()

  const loadTransactions = async () => {
    const { data } = await api.get('financial/financialTransactions/notEmailed')
    setTransactions(data)
  }
  const loadCompanies = async () => {
    const { data } = await api.get('financial/configurations')
    setCompanies(data)
  }
  const loadBanks = async () => {
    const { data } = await api.get('financial/financialTransactions/notEmailed')
    setBanks(data)
  }
  useEffect(() => {
    // loadTransactions()
    loadCompanies()
  }, [])

  const onSearch = async (d: any) => {
    let query = ''
    if (startDate && finalDate) {
      query = `?startDate=${startDate
        .toISOString()
        .substring(0, 10)}&finalDate=${finalDate
        .toISOString()
        .substring(0, 10)}`
    }
    query += `&bankAccountId=${d.bankAccountId}&companyId=${d.companyId}`
    const { data } = await api.get(
      `financial/financialTransactions/search${query}`
    )
    setTransactions(data)
  }

  const generateFile = async () => {
    const ids = transactions.filter(t => t.isChecked).map(t => t.id)
    activeLoading()
    try {
      await api
        .post('financial/paymentRemittanceFiles/generate', {
          ids
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'text/plain'
          })
          console.log(response.headers)

          const name = response.headers['content-disposition']
            .split('filename=')[1]
            .split('.')[0]
          const extension = response.headers['content-disposition']
            .split('.')[1]
            .split(';')[0]

          const filename = `${name.substring(
            1,
            name.length
          )}.${extension.substring(0, extension.length - 1)}`
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = filename
          a.click()
        })
      disableLoading()

      addToast({
        type: 'success',
        title: 'Arquivo gerado!',
        description: 'O arquivo de remessa foi gerado com sucessoo!'
      })
      disableLoading()
      history.push('/financial/paymentRemittanceFiles')
    } catch (error: any) {
      const responseMessage: string = error?.response?.data?.message
      const userMessage = responseMessage?.includes('USER MESSAGE: ')
        ? responseMessage.replace('USER MESSAGE: ', '')
        : ''

      addToast({
        type: 'error',
        title: 'Error ao gerar arquivo.',
        description:
          userMessage ||
          'Houve um error ao enviar os emails, tente novamente mais tarde.'
      })
      disableLoading()
    }
  }

  return (
    <Container
      pageTitle={'Arquivos de remessa de pagamentos'}
      portletTitle={'Filtro'}
      breadcrumb={breadcrumbList}
      tools={[toolsCreate]}
    >
      <FilterContaier>
        <Form onSubmit={onSearch}>
          <div className="col-md-2">
            <Select
              label="Empresa"
              className="form-control"
              name="companyId"
              options={companies.map(c => ({
                name: c.name,
                value: c.id
              }))}
              blank
              defaultValue={''}
              controlled
              rules={{ required: true }}
            />
          </div>
          <div className="col-md-2">
            <Select
              label="Conta Bancária"
              className="form-control"
              name="bankAccountId"
              options={[{ name: 'Inter', value: 13 }]}
              blank
              defaultValue={''}
              controlled
              rules={{ required: true }}
            />
          </div>
          <div className="col-md-2">
            <Date
              label="Vencimento inicial"
              className="form-control"
              name="start_date"
              onChange={date => setStartDate(date)}
              selected={startDate}
              controlled
            />
          </div>
          <div className="col-md-2">
            <Date
              label="Vencimento final"
              className="form-control"
              name="final_date"
              onChange={date => setFinalDate(date)}
              selected={finalDate}
              controlled
            />
          </div>
          <footer className="col-md-12">
            <button
              type="reset"
              onClick={() => {
                setStartDate(undefined)
                setFinalDate(undefined)
              }}
            >
              Limpar
            </button>
            <button type="submit">Buscar</button>
          </footer>
        </Form>
        <hr />
      </FilterContaier>
      <ListTitle>
        <p>Movimentações financeiras</p>
        <hr style={{ paddingBottom: 10 }} />
      </ListTitle>
      <Table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                name="all"
                id="all"
                onChange={({ target }) => {
                  setTransactions(prev => {
                    const copy = JSON.parse(JSON.stringify(prev))
                    const checked = copy.map((c: FinancialTransaction) => ({
                      ...c,
                      isChecked: target.checked
                    }))
                    return checked
                  })
                }}
              />
            </th>
            <TableHeader
              headers={headersCreate}
              onSorting={(field, order, type) => {
                const itemSorted = transactions.sort((a: any, b: any) => {
                  const fields = field.split('.')
                  let currentFieldA
                  let currentFieldB
                  if (fields.length === 1) {
                    currentFieldA = a[fields[0]]
                    currentFieldB = b[fields[0]]
                  }
                  if (fields.length === 2) {
                    currentFieldA = a[fields[0]]?.[fields[1]]
                    currentFieldB = b[fields[0]]?.[fields[1]]
                  }
                  if (fields.length === 3) {
                    currentFieldA = a[fields[0]]?.[fields[1]]?.[fields[2]]
                    currentFieldB = b[fields[0]]?.[fields[1]]?.[fields[2]]
                  }
                  if (type === 'monetary') {
                    return Number(currentFieldA.replaceAll(/[',','.']/gi, '')) >
                      Number(currentFieldB.replaceAll(/[',','.']/gi, '')) &&
                      order === 'ASC'
                      ? 1
                      : -1
                  }
                  return currentFieldA > currentFieldB && order === 'ASC'
                    ? 1
                    : -1
                })
                setTransactions([...itemSorted])
              }}
            />
          </tr>
        </thead>
        <tbody>
          {transactions?.map((t, index) => (
            <tr key={t.id}>
              <td>
                <input
                  type="checkbox"
                  name="all"
                  id="all"
                  checked={t.isChecked}
                  disabled={t.disabled}
                  onChange={() => {
                    setTransactions(prev => {
                      const copy = JSON.parse(JSON.stringify(prev))
                      copy[index].isChecked = !copy[index].isChecked
                      return copy
                    })
                  }}
                />
              </td>
              <td>{t.name}</td>
              <td>{t.category_name}</td>
              <td>{t.supplier_name}</td>
              <td>{`R$ ${t.value}`}</td>
              <td>
                {t.due_date.substring(0, 10).split('-').reverse().join('/')}
              </td>
              <td>{t.payment_form}</td>
              <td>{t.notes}</td>
              <td>
                <Link to={''}>
                  <span className="fa fa-search" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="form-actions right">
        <Button
          type="submit"
          className="btn dark btn-sm sbold uppercase"
          onClick={generateFile}
        >
          Gerar Arquivo
        </Button>
      </div>
    </Container>
  )
}

export default PaymentRemittanceFile
